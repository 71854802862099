import { DialogTitle } from '@material-ui/core';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { bind } from 'decko';
import * as firebase from 'firebase/app';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Validate, { FirebaseError, FirebaseErrorHandler, logError, Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import Legal from '../../helpers/Legal';
import LinkTo from '../../helpers/LinkTo';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton, SecondaryButton } from '../button';
import Icon from '../common/Icon';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Checkbox from '../forms/Checkbox';
import Input, { InputValue } from '../forms/Input';
import { ErrorText, ImprintText, Link, RegularText, TealLink } from '../text';

const SpacedCheckbox = styled(Checkbox)`
    margin: ${GRID_SIZE * 1.5}px 0;
`;

interface Params {
    isRegistrationMode: boolean;
}

interface State extends ModalState<Params> {
    emailValid?: boolean;
    passwordValid?: boolean;
    showPassword?: boolean;
    suggestModeChange?: boolean;
    gdprAccepted?: boolean;
    userIsVisible?: boolean;
    showError?: boolean;
}

@inject('api', 'auth')
export default class Registration extends Modal<Params, State> {
    public readonly state: State = {
        params: {}
    };

    private emailInputRef = React.createRef<Input>();
    private passwordInputRef = React.createRef<Input>();
    private presetEmail: string;

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedAuthProps;
    }

    constructor(props: ModalProps) {
        super(props);

        // Pre-fill email address after password resetting
        this.presetEmail = this.injected.auth.user?.email || '';
    }

    public render() {
        let showPasswordIcon = VisibilityOffOutlined;
        let showPasswordIconColor = colors.grey_02;

        if (this.state.showPassword) {
            showPasswordIcon = VisibilityOutlined;
            showPasswordIconColor = colors.matte_black;
        }

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderCta}>
                <ScreenHeader
                    title={Env.i18n.t(this.state.params.isRegistrationMode ? 'CreateAccountTitle' : 'LoginTitle')}
                    onBack={this.back}
                />
                <Input
                    defaultValue={this.presetEmail}
                    ref={this.emailInputRef}
                    placeholder={Env.i18n.t('YourEmailAddress')}
                    validate={this.validateEmail}
                    nextInput={this.passwordInputRef}
                    type="email"
                />
                {/* TODO: Extract component for password input */}
                <Input
                    ref={this.passwordInputRef}
                    placeholder={Env.i18n.t('YourPassword')}
                    validate={this.validatePassword}
                    sanitizeValue={false}
                    onSubmitEditing={this.createAccount}
                    type={this.state.showPassword ? 'text' : 'password'}
                >
                    <Icon src={showPasswordIcon} color={showPasswordIconColor} onClick={this.togglePassword}/>
                </Input>
                {this.state.params.isRegistrationMode ? (
                    <>
                        <SpacedCheckbox onToggle={this.confirmVisibility} checked={this.state.userIsVisible}>
                            <RegularText>
                                {Env.i18n.t('VisibilityHint')}
                                <Link onClick={this.explainVisibility}>
                                    {Env.i18n.t('VisibilityLink')}
                                </Link>
                            </RegularText>
                        </SpacedCheckbox>
                        <SpacedCheckbox onToggle={this.confirmGdpr}>
                            <RegularText>
                                {Env.i18n.t('GDPRLabelBeforeLink')}
                                <Link onClick={this.showGdpr}>
                                    {Env.i18n.t('GDPRLink')}
                                </Link>
                                {Env.i18n.t('GDPRLabelAfterLink')}
                            </RegularText>
                            {this.state.showError && (
                                <ErrorText>
                                    {Env.i18n.t('ErrorGdpr')}
                                </ErrorText>
                            )}
                        </SpacedCheckbox>
                    </>
                ) : (
                    <div onClick={this.handleForgottenPassword} style={{ cursor: 'pointer', flex: 0 }}>
                        <ImprintText>
                            {Env.i18n.t('ForgotPassword')}
                        </ImprintText>
                    </div>
                )}
                {this.state.suggestModeChange && (
                    <TealLink onClick={this.changeMode} style={{ marginTop: GRID_SIZE * 4 }}>
                        {Env.i18n.t(this.state.params.isRegistrationMode ? 'InsteadLogin' : 'InsteadRegistration')}
                    </TealLink>
                )}
            </Screen>
        );
    }

    protected async hydrateParams(params: string[]) {
        return {
            isRegistrationMode: params.includes('new')
        };
    }

    protected validateParams() {
        return computed(() => this.injected.auth.user?.isAnonymous !== false).get();
    }

    @bind
    private renderCta() {
        const SubmitButton = this.state.emailValid && this.state.passwordValid ? PrimaryButton : SecondaryButton;

        return (
            <DialogTitle>
                <SubmitButton onClick={this.createAccount}>
                    {Env.i18n.t(this.state.params.isRegistrationMode ? 'Registration' : 'Login')}
                </SubmitButton>
            </DialogTitle>
        );
    }

    @bind
    private async createAccount() {
        const emailInput = this.emailInputRef.current!;
        const passwordInput = this.passwordInputRef.current!;
        const email = emailInput.validateValue();
        const password = passwordInput.validateValue();

        if (this.state.params.isRegistrationMode && !this.state.gdprAccepted) {
            this.setState({ showError: true });
            return;
        }

        if (email !== undefined && password !== undefined) {
            const signInPromise = this.state.params.isRegistrationMode
                ? this.injected.auth.register(email.toString(), password.toString(), !!this.state.userIsVisible)
                : this.injected.auth.login(email.toString(), password.toString()) as Promise<any>;

            // check for valid credentials (not in `validate*` since it would be done unncessarily often)
            this.injected.api.waitFor(
                signInPromise
                    .then(() => this.props.navigation.back(2)) // assuming, it has been opened via RegistrationOptions
                    .catch(error => {
                        const emailInputError = FirebaseErrorHandler.forEmail(error);
                        let passwordInputError = FirebaseErrorHandler.forPassword(error);

                        if (emailInputError.valid && passwordInputError.valid) {
                            logError('Registration.createAccount', error);
                            passwordInputError = Validation.error(Env.i18n.t('ErrorUnknown'));
                        }

                        emailInput.setError(emailInputError);
                        passwordInput.setError(passwordInputError);
                        this.setState({ suggestModeChange: FirebaseErrorHandler.forModeChange(error) });
                    })
            );
        }
    }

    @bind
    private validateEmail(email: InputValue) {
        const validation = Validate.email(email.toString());

        this.setState({ emailValid: validation.valid, suggestModeChange: false });

        return validation;
    }

    @bind
    private validatePassword(password: InputValue) {
        const validation = Validate.password(password.toString(), this.state.params.isRegistrationMode);

        this.setState({ passwordValid: validation.valid });

        return validation;
    }

    @bind
    private togglePassword() {
        this.setState(state => ({ showPassword: !state.showPassword }));
        this.passwordInputRef.current?.focus();
    }

    @bind
    private changeMode() {
        this.emailInputRef.current?.setError();
        this.passwordInputRef.current?.setError();
        this.setState(state => ({
            params: { isRegistrationMode: !state.params.isRegistrationMode },
            suggestModeChange: false
        }));
    }

    @bind
    private explainVisibility(event: React.MouseEvent) {
        event.stopPropagation();
        Legal.confirmVisibility().then(this.confirmVisibility);
    }

    @bind
    private showGdpr(event: React.MouseEvent) {
        event.stopPropagation();
        LinkTo.gdpr();
    }

    @bind
    private handleForgottenPassword() {
        const email = this.emailInputRef.current?.validateValue();

        if (email !== undefined) {
            firebase
                .auth()
                .sendPasswordResetEmail(email.toString())
                .then(() => Env.snackbar.success(Env.i18n.t('ForgotPasswordSuccess')))
                .catch((error: any) => {
                    logError('RegistrationScreen.handleForgottenPassword', error);
                    Env.snackbar.error(Env.i18n.t(error.code === FirebaseError.USER_NOT_FOUND ? 'ForgotPasswordFail' : 'Fail'));
                });
        }
    }

    @bind
    private confirmGdpr(gdprAccepted: boolean) {
        this.setState({ gdprAccepted, showError: false });
    }

    @bind
    private confirmVisibility(userIsVisible: boolean) {
        this.setState({ userIsVisible });
    }
}
