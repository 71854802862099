import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Group from '../../../../lib/src/types/models/Group';
import { EmptyGroupsList } from '../social/EmptyList';
import AbstractContactsTab from './AbstractContactsTab';
import EditGroupModal from './EditGroupModal';

export type GroupsTabType = GroupsTab;

@inject('api', 'invitationDraft', 'chats')
@observer
export default class GroupsTab extends AbstractContactsTab<Group> {
    private editGroupNameModalRef = React.createRef<EditGroupModal>();

    @bind
    public addNew() {
        this.editGroupNameModalRef.current?.open();
    }

    @bind
    public renderModals() {
        return (
            <EditGroupModal ref={this.editGroupNameModalRef} />
        );
    }

    public render() {
        return this.renderList(false, [
            {
                label: Env.i18n.t('ContactGroupEditMembers'),
                action: this.editGroup
            },
            {
                label: Env.i18n.t('ContactGroupRemove'),
                action: this.removeContactGroup,
                destructive: true
            }
        ]);
    }

    @bind
    protected renderEmptyList() {
        return (
            <EmptyGroupsList onPress={this.addNew} />
        );
    }

    @bind
    private editGroup() {
        const { selected } = this.state;

        if (selected) {
            this.editGroupNameModalRef.current?.open(selected);
        }
    }

    @bind
    private async removeContactGroup() {
        this.handlePromise(
            group => group.leave(),
            Env.i18n.t('SuccessGroupRemoved'),
            Env.i18n.t('ErrorGroupNotRemoved')
        );
    }
}
