import { Coords } from 'google-map-react';
import React from 'react';

type MarkerType = 'user' | 'place' | 'premium';

interface Props extends Coords {
    type?: MarkerType;
    secondary?: boolean;
    onClick?: () => void;
}

export default class Marker extends React.PureComponent<Props> {
    private static images: { [k in MarkerType]: string } = {
        user: require('../../assets/png/teal_marker.png'),
        place: require('../../assets/png/red_marker.png'),
        premium: require('../../assets/png/premium_marker.png')
    };

    public render() {
        const { type = 'place', secondary, onClick } = this.props;
        const opacity = secondary ? 0.7 : 1;
        const cursor = onClick ? 'pointer' : undefined;
        let height = 12;
        let width = 12;

        if (type === 'user') {
            height = 16;
        }

        if (secondary) {
            height = 10;
            width = 10;
        }

        return (
            <div onClick={onClick} style={{ cursor, opacity }}>
                <img style={{ height, width }} src={Marker.images[type]} />
            </div>
        );
    }
}
