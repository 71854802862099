import { Button, ButtonGroup } from '@material-ui/core';
import GpsFixed from '@material-ui/icons/GpsFixed';
import { bind } from 'decko';
import GoogleMapReact, { ChangeEventValue, Coords } from 'google-map-react';
import { autorun, computed, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import GoogleMapsApi from '../../../../lib/src/helpers/GoogleMapsApi';
import Locations from '../../../../lib/src/helpers/Locations';
import CurrentLocation from '../../../../lib/src/store/CurrentLocation';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import UserLocation from '../../helpers/UserLocation';
import Marker from '../map/Marker';

const ButtonGroupContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 12px;
`;

const MyLocationContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: 113px;
    width: 39px;
    height: 39px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    position: absolute;
    cursor: pointer;
    right: 11px;

    & svg {
        opacity: 0.6;
    }

    &:hover svg {
        opacity: 0.9;
    }
`;

const INITIAL_ZOOM = 16;

interface Props {
    onLoaded?: () => void;
    onItemSelect: (item: RestaurantEntry) => void;
}

interface State {
    center: Coords;
    mapZoom: number;
    showRegionSearch: boolean;
}

@inject('api')
@observer
export default class RestaurantsMap extends React.Component<Props> {
    private static _autocompleteService: any;

    private map?: any;
    private centerReactionDisposer?: IReactionDisposer;

    public readonly state: State = {
        center: GoogleMapsApi.latlngToCoords(this.injected.api.location.coordinate),
        mapZoom: INITIAL_ZOOM,
        showRegionSearch: false
    };

    public static get autocompleteService() {
        return RestaurantsMap._autocompleteService;
    }

    private static setAutocompleteService(autocompleteService: any) {
        return RestaurantsMap._autocompleteService = autocompleteService;
    }

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    @computed
    private get allRestaurants() {
        const { restaurants } = this.injected.api;

        return restaurants.filteredList.concat(restaurants.nonPartners);
    }

    @bind
    private findMyPosition() {
        UserLocation.get().then(location => {
            if (location) {
                this.setState({ showRegionSearch: false });
                this.injected.api.setLocation(location);
                this.restoreCenter();
            }
        });
    }

    @bind
    private handleApiLoaded({ map, maps }: { map: any, maps: any }) {
        this.map = map;

        if (!RestaurantsMap.autocompleteService) {
            RestaurantsMap.setAutocompleteService(new maps.places.AutocompleteService());
        }

        if (this.props.onLoaded) {
            this.props.onLoaded();
        }
    }

    @bind
    private handleRegionChange({ center, bounds: { nw, se }, zoom }: ChangeEventValue) {
        const displayedRadius = Math.max(...this.allRestaurants.map(restaurant => restaurant.initialDistanceKm || 0), 0)
            || GoogleMapsApi.getDiagonalDistance({
                latitude: center.lat,
                longitude: center.lng,
                latitudeDelta: se.lat - nw.lat,
                longitudeDelta: se.lng - nw.lng
            });
        const maxDistance = displayedRadius / 2;
        const distance = GoogleMapsApi.getDistance(Locations.normalize(center), Locations.normalize(this.state.center));

        this.setState({
            showRegionSearch: (maxDistance > 0 && distance > maxDistance),
            mapZoom: zoom
        });
    }

    @bind
    private async searchThisArea() {
        if (this.map) {
            const mapCenter = this.map.getCenter();
            const latitude = mapCenter?.lat();
            const longitude = mapCenter?.lng();

            this.setState({ showRegionSearch: false });
            this.injected.api.setLocation(await CurrentLocation.withAutomaticName(Locations.normalize({ latitude, longitude })));
        }
    }

    @bind
    private restoreCenter() {
        const center = { ...this.state.center };
        const tempCenter = { ...center };

        tempCenter.lat += 1e-5; // necessary to trigger an update and make the map pan
        this.setState({ center: tempCenter }, () => this.setState({ center }));
    }

    private renderMarkers() {
        return this.allRestaurants.filter(restaurant => restaurant.location).map((restaurant, index) => (
            <Marker
                key={index}
                {...GoogleMapsApi.latlngToCoords(restaurant.location!)}
                onClick={() => this.props.onItemSelect(restaurant)}
                type={restaurant.isPremium ? 'premium' : undefined}
                secondary={restaurant.type === CooperationType.NonPartner}
            />
        ));
    }

    private renderCenterMarker() {
        const { center } = this.state;

        return !center ? null :(
            <Marker key="my-position" {...center} type="user" />
        );
    }

    public componentDidMount() {
        this.centerReactionDisposer = autorun(() => {
            const center = GoogleMapsApi.latlngToCoords(this.injected.api.location.coordinate);
            const { lat, lng } = this.state.center;

            // need to deep-check center to avoid (unnecessary) update overflow
            if (center.lat !== lat || center.lng !== lng) {
                this.setState({ center });
            }
        });
    }

    public componentWillUnmount() {
        if (this.centerReactionDisposer) {
            this.centerReactionDisposer();
        }
    }

    public render() {
        const { center, mapZoom, showRegionSearch } = this.state;
        const bootstrapURLKeys = {
            key: Constants.PARTNER_FIREBASE_CONFIG.apiKey,
            libraries: 'places'
        };

        return (
            <div style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
                <GoogleMapReact
                    bootstrapURLKeys={bootstrapURLKeys}
                    yesIWantToUseGoogleMapApiInternals
                    center={center}
                    defaultZoom={INITIAL_ZOOM}
                    onGoogleApiLoaded={this.handleApiLoaded}
                    onChange={this.handleRegionChange}
                    options={{
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: false
                    }}
                >
                    {(mapZoom < 16)
                        ? [ this.renderMarkers(), this.renderCenterMarker() ]
                        : [ this.renderCenterMarker(), this.renderMarkers() ]
                    }
                </GoogleMapReact>
                <ButtonGroupContainer>
                    <ButtonGroup variant="contained" style={{ pointerEvents: 'auto' }}>
                        {showRegionSearch && (
                            <Button onClick={this.searchThisArea}>
                                {Env.i18n.t('SearchThisArea')}
                            </Button>
                        )}
                        <Button onClick={this.restoreCenter}>
                            {Env.i18n.t('NewCenter')}
                        </Button>
                    </ButtonGroup>
                </ButtonGroupContainer>
                {('geolocation' in navigator) && (
                    <MyLocationContainer onClick={this.findMyPosition}>
                        <GpsFixed />
                    </MyLocationContainer>
                )}
            </div>
        );
    }
}
