import { Button } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { PlaceResponse } from '../../../../lib/src/types/models/Response';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import LinkTo from '../../helpers/LinkTo';
import { GRID_SIZE } from '../../styles/base';
import { theme } from '../../theme';
import AutocompleteInput from '../common/AutocompleteInput';
import Icon from '../common/Icon';
import { TitleText } from '../text';
import MenuDrawer from './MenuDrawer';

const WRAP_THRESHOLD = 850;

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;
    width: auto;
    background-color: ${colors.navy_blue};

    @media (max-width: ${WRAP_THRESHOLD}px) {
        flex-wrap: wrap;
    }
`;

const Logo = styled.img.attrs({
    src: require('../../assets/svg/Logo_Website.svg')
})`
    align-self: flex-start;
    cursor: pointer;
    height: 58px;
    margin-left: 5%;
    width: 60px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 6%;
    white-space: nowrap;

    @media (max-width: ${WRAP_THRESHOLD}px) {
        flex-wrap: wrap;
        margin-left: 5%;
    }
`;

const DownloadButton = styled(Button).attrs({
    variant: 'outlined'
})`
    &&& {
        border-color: ${colors.beige_light};
        color: ${colors.beige_light};
        margin-right: ${GRID_SIZE * 2}px;

        ${theme.breakpoints.up('sm')} {
            display: none;
        }
    }

    @media (max-width: ${WRAP_THRESHOLD}px) {
        flex-wrap: wrap;
    }
`;

const SearchContainer = styled.div`
    margin: ${GRID_SIZE * 1.5}px 7%;
    position: relative;
    width: 50%;

    @media (max-width: ${WRAP_THRESHOLD}px) {
        order: 4;
        margin: ${GRID_SIZE * 1.5}px;
        width: 100%;
    }
`;

const Searchbar = styled.div`
    align-items: center;
    border: none;
    border-radius: ${GRID_SIZE}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    flex-grow: 1;
`;

const FilterButton = styled.div<{ count: number }>`
    align-items: center;
    align-self: stretch;
    background-color: ${props => props.count ? colors.teal_50 : 'transparent'};
    color: ${props => props.count ? colors.teal_900 : colors.beige_light};
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: stretch;
    padding: 0 10px;
`;

const DrawerButtton = styled.div`
    align-items: center;
    align-self: stretch;
    background-color: ${colors.navy_blue};
    color: ${colors.beige_light};
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: stretch;
    margin-left: 10px;
    padding: 0 10px;
`;

interface Props {
    onPlaceSelect: (place: PlaceResponse) => void;
    onPartnerSelect: (partner: RestaurantEntry) => void;
    onNonPartnerSelect: (partner: RestaurantEntry) => void;
    // onMealSelect: (meal: MealResponse) => void;
    onOpenModal: (modal: string, params?: any) => void;
}

interface State {
    open: boolean
}

@inject('api')
@observer
export default class Header extends React.PureComponent<Props> {
    public readonly state: State = {
        open: false,
    };

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public render() {
        const { filterCount, location } = this.injected.api;
        const { onPlaceSelect, onOpenModal, children } = this.props;

        return (
            <HeaderContainer>
                <DrawerButtton onClick={this.openDrawer} >
                    <Icon src={Menu} />
                </DrawerButtton>
                <MenuDrawer open={this.state.open} onClose={this.handleDrawerClose} onOpenModal={onOpenModal} />
                <Logo onClick={this.goToLunchnow} />
                <SearchContainer>
                    <Searchbar>
                        <div style={{ marginRight: 8, width: '100%', borderBottom: `1px solid ${colors.beige_light}` }}>
                            <AutocompleteInput inputValue={location.name} onPlaceSelect={onPlaceSelect} />
                        </div>
                        <FilterButton onClick={this.openFiltersModal} count={filterCount}>
                            <Icon src={require('../../assets/svg/ic_primary_filter.svg')} />
                            {filterCount > 0 && (
                                <TitleText style={{ marginRight: 8, color: 'currentColor' }}>
                                    {filterCount}
                                </TitleText>
                            )}
                        </FilterButton>
                    </Searchbar>
                    {children}
                </SearchContainer>
                <ButtonContainer>
                    <DownloadButton onClick={this.openStoreModal}>
                        {/* TODO: localize */}
                        APP HERUNTERLADEN
                    </DownloadButton>
                </ButtonContainer>
            </HeaderContainer>
        );
    }

    @bind
    private openStoreModal() {
        this.props.onOpenModal('download');
    }

    @bind
    private openFiltersModal() {
        this.props.onOpenModal('filters');
    }

    @bind
    private openDrawer() {
        this.setState({ open: true });
    };

    @bind
    public handleDrawerClose() {
        this.setState({ open: false });
    }

    @bind
    private goToLunchnow() {
        LinkTo.lunchnow();
    }
}
