import { Tab, Tabs } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { bind } from 'decko';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import colors, { alpha } from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { RegularText } from '../text';
import IconButton from './IconButton';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%; /* needed to make Slider work correctly */
`;

const TabBar = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const TabsContainer = styled.div`
    flex: 1;
`;

// @ts-ignore ts(2615)
const TabLabel = styled(RegularText)`
    color: ${colors.matte_black};
    font-size: 1rem;
    margin: 0;
    padding: ${GRID_SIZE * 2}px;
    text-transform: none;
`;

const AddButton = styled(IconButton).attrs({
    icon: Add
})`
    border-radius: 50%;
    box-shadow: 0px 4px 6px ${alpha(colors.matte_black, 0.16)};
    margin-right: ${SCREEN_PADDING}px;
    padding: ${GRID_SIZE / 2}px;
`;

interface Props {
    tabs: string[];
    initialTab?: number;
    onChange?: (index: number) => void;
    onAdd?: (index: number) => void;
    height?: 'adaptive' | 'full' | 'auto';
}

interface State {
    slide: number;
}

export default class TabViewPager extends React.PureComponent<Props, State> {
    public readonly state: State = {
        slide: this.props.initialTab || 0
    };

    private slider = React.createRef<Slider>();

    @bind
    public getTab() {
        return this.state.slide;
    }

    @bind
    public setTab(index: number) {
        this.setState({ slide: index });
        this.slider.current?.slickGoTo(index);
    }

    public render() {
        const { tabs, height, onAdd, children } = this.props;

        return (
            <Container>
                <TabBar>
                    <TabsContainer>
                        <Tabs value={this.state.slide} onChange={this.handleTabChange} variant="scrollable" scrollButtons="off">
                            {tabs.map((menuType, index) => (
                                <Tab
                                    key={index}
                                    label={
                                        <TabLabel>
                                            {menuType}
                                        </TabLabel>
                                    }
                                    disableRipple={tabs.length < 2}
                                />
                            ))}
                        </Tabs>
                    </TabsContainer>
                    {onAdd && (
                        <AddButton onClick={this.handleAdd} />
                    )}
                </TabBar>
                <Slider
                    ref={this.slider}
                    arrows={false}
                    dots={false}
                    infinite={false}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    adaptiveHeight={height === 'adaptive'}
                    beforeChange={this.beforeChange}
                    className={height === 'full' ? 'full-height' : undefined}
                >
                    {children}
                </Slider>
            </Container>
        );
    }

    @bind
    private handleTabChange(event: React.ChangeEvent<{}>, slide: number) {
        this.setTab(slide);

        if (this.props.onChange) {
            this.props.onChange(slide);
        }
    }

    @bind
    private beforeChange(currentSlide: number, nextSlide: number) {
        this.setState({ slide: nextSlide });
    }

    @bind
    private handleAdd() {
        if (this.props.onAdd) {
            this.props.onAdd(this.state.slide);
        }
    }
}
