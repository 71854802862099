import { bind } from 'decko';
import { computed, IReactionDisposer, observable, reaction } from 'mobx';
import moment, { Moment } from 'moment';

import Env from '../Env';
import Lists from '../helpers/Lists';
import { assert } from '../helpers/Validate';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import { VatTypeId } from '../types/lunchnow';
import DiscountEntry from '../types/models/DiscountEntry';
import Invitation from '../types/models/Invitation';
import {
    DiscountRef,
    getMinOrderDate,
    getOrderItemPrice,
    logOrder,
    Order,
    OrderDraft,
    OrderItem,
    OrderVatByType,
    PaymentOption
} from '../types/models/Order';
import RestaurantEntry, { MealEntry } from '../types/models/RestaurantEntry';
import UserInvitation, { getInvitationDate, isInvitationExpired } from '../types/models/UserInvitation';
import DataList from './DataList';

export function convertPriceToInt(price?: number) {
    return Math.round((price || 0) * 100);
}

type MealCartItem = Partial<OrderItem> & {
    key: string;
};

type ApiType = ApiManager<AccountManager<ApiType>>;

const PARTNER_APP_CONNECT_TIMEOUT_MS = 6 * 60 * 1000; // = 6 minutes

// TODO: avoid public properties, use getters/setters instead
export default class Cart implements OrderDraft {
    @observable
    public paymentOption: PaymentOption;

    @observable
    private _meetUpDate: Date | null = null;

    @observable
    private _meetUp?: string;

    @observable
    private _isTakeAway?: boolean;

    @observable
    private _discount?: DiscountEntry;

    /*
     * Note: To test the ordering process for a restaurant without a connected partner app,
     * set its `observables/customer/lastPartnerAppConnect` in the partner database to a date in the far future
     */
    @observable
    private _partnerAppConnected?: boolean;

    @observable
    private _restaurantOffersDrinks?: boolean;

    private _restaurant: RestaurantEntry;
    private _items = new DataList<OrderItem>();
    private _api: ApiType;
    private mealsListenerDisposer?: () => void;
    private discountsReactionDisposer?: IReactionDisposer;
    private takeAwayReactionDisposer: IReactionDisposer;
    private dateReactionDisposer: IReactionDisposer;
    private partnerAppConnectTimeout: any;

    public static fromOrder(order: Order, api: ApiType) {
        const { paymentOption, restaurant, meetUpDate, meetUp, items, isTakeAway, discount } = order;

        assert(restaurant, `Restaurant of order ${order.key} was not found!`);

        const cart = new Cart(paymentOption, restaurant!, api);

        cart._items.set(...items);
        cart._meetUpDate = meetUpDate;
        cart._meetUp = meetUp;
        cart.isTakeAway = isTakeAway;
        cart._discount = discount && api.account.accountDiscounts.get(discount.id);
        cart.startSynchronization();

        return cart;
    }


    /**
     * Call `release()` when no longer used!
     */
    public constructor(paymentOption: PaymentOption, restaurant: RestaurantEntry, api: ApiType) {
        this.paymentOption = paymentOption;
        this._restaurant = restaurant;
        this._api = api;

        if (restaurant.onlyTakeAway) {
            this.isTakeAway = true;
        }

        this.takeAwayReactionDisposer = reaction(
            () => this.isTakeAway,
            () => this.items.forEach(item => item.vat = this._api.getItemVat(item, this.isTakeAway))
        );
        this.dateReactionDisposer = reaction(
            () => this.meetUpDate,
            () => this.updateDiscounts(this._api.account.allDiscounts, false)
        );
    }

    @computed
    public get meetUpDate() {
        return this._meetUpDate;
    }

    @computed
    public get meetUp() {
        return this._meetUp;
    }

    @computed
    public get isTakeAway() {
        return this._isTakeAway;
    }

    public set isTakeAway(takeAway: boolean | undefined) {
        this._isTakeAway = takeAway;
    }

    @computed
    public get items() {
        return this._items.list.filter(item => item.amount > 0);
    }

    @computed
    public get discount(): DiscountRef | undefined {
        const { _discount, totalItemPrice } = this;

        return (!_discount || !this.hasValidTime(_discount)) ? undefined : {
            id: _discount.key,
            value: _discount.getValue(totalItemPrice),
            name: _discount.name
        };
    }

    public get restaurant() {
        return this._restaurant;
    }

    /**
     * Total price in cents
     */
    @computed
    public get totalPrice() {
        const { totalItemPrice } = this;

        return totalItemPrice + (this._discount?.getValue(totalItemPrice) || 0);
    }

    @computed
    public get hasItemDiscount() {
        return this.items.some(item => !!item.discount);
    }

    @computed
    public get shouldSuggestDrinks() {
        return this._restaurantOffersDrinks && !this._items.list.some(item => item.vatType === VatTypeId.DRINK && item.amount > 0);
    }

    @computed
    public get isValid() {
        return !!this.meetUpDate && !!this.paymentOption && this.totalPrice > 0 && this.isTakeAway !== undefined;
    }

    public get status(): 'DRAFT' {
        return 'DRAFT';
    }

    @computed
    public get vatByType() {
        if (this.isTakeAway !== undefined) {
            const vatByType: Partial<OrderVatByType> = {};
            const vatTypeVariant = this.isTakeAway ? 'takeAway' : 'default';

            this._api.vatTypes.list.forEach(vatType => vatByType[vatType.key] = vatType[vatTypeVariant]);

            return vatByType as OrderVatByType
        }
    }

    /**
     * Total price of `items` in cents (not including order-wide discount)
     */
    @computed
    private get totalItemPrice() {
        return this.items.reduce((sum, item) => sum + getOrderItemPrice(item), 0);
    }

    public createOrder(): OrderDraft {
        const { paymentOption, meetUp, meetUpDate, totalPrice, discount, isTakeAway, status, vatByType } = this;
        const { restaurant } = this;
        const items = this.items.filter(item => item.amount > 0);

        return { paymentOption, meetUp, meetUpDate, totalPrice, discount, isTakeAway, status, vatByType, restaurant, items };
    }

    public change(item: MealEntry, amount: number) {
        const newAmount = Math.max(0, amount);
        const cartItem = this._items.get(item.key);
        const amountDiff = newAmount - (cartItem?.amount || 0);

        if (cartItem && newAmount > 0) {
            cartItem.vat = this._api.getItemVat(cartItem, this.isTakeAway);
        }

        if (amountDiff !== 0) {
            if (!cartItem) {
                this.setItem(item, newAmount);
            } else if (newAmount > 0) {
                cartItem.amount = newAmount;
            } else {
                this._items.remove(item.key);
            }

            logOrder(amountDiff > 0 ? 'add_to_cart' : 'remove_from_cart', this, { [item.key]: amountDiff });
        }
    }

    public amount(item: MealCartItem) {
        return this._items.get(item.key)?.amount || 0;
    }

    public toggleDiscount(discount?: DiscountEntry) {
        this._discount = (discount?.key !== this._discount?.key) ? discount : undefined;
    }

    public setTime(time: Date | Moment) {
        const timeMoment = moment(time);

        this._meetUp = undefined;
        this._meetUpDate = moment().startOf('day').hours(timeMoment.hours()).minutes(timeMoment.minutes()).toDate();
    }

    public setMeetUp(meetUp?: UserInvitation | Invitation) {
        if (meetUp) {
            const date = getInvitationDate(meetUp);

            assert(this.isToday(date), '`Cart.setMeetUp()` cannot link cart to invitation for different day');

            this._meetUp = meetUp.key;
            this._meetUpDate = date;
            this.isTakeAway = meetUp.isTakeAway || false;
        } else {
            this._meetUp = undefined;
            this.isTakeAway = undefined;
        }
    }

    @bind
    public fitsInvitation(invitation: UserInvitation | Invitation) {
        return invitation.restaurant === this.restaurant.key
            && !isInvitationExpired(invitation)
            && this.isToday(getInvitationDate(invitation))
            && (!this.isToday(new Date()) || getMinOrderDate().isSameOrBefore(getInvitationDate(invitation)));
    }

    public release() {
        this.stopSynchronization();
        this.takeAwayReactionDisposer();
        this.dateReactionDisposer();
    }

    /**
     * Keeps `items` and `discount` in sync with the database. Triggers alert on changes.
     * Automatically called on setting items.
     */
    public startSynchronization() {
        if (!this._items.empty) {
            if (!this.mealsListenerDisposer) {
                const meals = this._restaurant.offers;

                if (meals) {
                    this.updateDrinks(meals);
                }

                this.mealsListenerDisposer = this._restaurant.addMealsListener(meals => this.updateMeals(meals, true), !meals);
            }

            if (!this.discountsReactionDisposer) {
                this.discountsReactionDisposer = reaction(
                    () => this._api.account.allDiscounts,
                    discounts => this.updateDiscounts(discounts, true),
                    { fireImmediately: true }
                );
            }
        }
    }

    public stopSynchronization() {
        if (this.mealsListenerDisposer) {
            this.mealsListenerDisposer();
        }

        if (this.discountsReactionDisposer) {
            this.discountsReactionDisposer();
        }
    }

    private getDiscountForMeal(meal: MealEntry | string) {
        return this._api.account.allDiscounts.find(discount => discount.quantityLeft && discount.availableForMeal(meal) && this.hasValidTime(discount));
    }

    private setItem(meal: MealEntry, amount: number) {
        const vatType = (this._api.mealTypes[meal.typeKey || '']?.baseName === 'Getränke') ? VatTypeId.DRINK : VatTypeId.FOOD;
        const discountEntry = this.getDiscountForMeal(meal);
        const price = convertPriceToInt(meal.price);
        const item: OrderItem = {
            key: meal.key,
            mealKey: meal.fromMeal?.id,
            amount,
            name: meal.name || '',
            description: meal.description || '',
            price,
            vatType
        };

        if (discountEntry) {
            item.discount = {
                id: discountEntry.key,
                value: discountEntry.getValue(price),
                name: discountEntry.name
            };
        }

        item.vat = this._api.getItemVat(item, this.isTakeAway);
        this._items.add(item);

        // defer synchronization until cart is first filled
        // (not removing listeners if cart is emptied, since that action is probably more frequent than listener events)
        this.startSynchronization();
    }

    private isToday(date: Date | Moment) {
        return moment().isSame(date, 'day');
    }

    private updateDrinks(meals: MealEntry[]) {
        const drinksTypeKey = Lists.findIndex(this._api.mealTypes, type => type.baseName === 'Getränke');

        this._restaurantOffersDrinks = meals.some(meal => meal.typeKey === drinksTypeKey);
    }

    @bind
    private updateMeals(meals: MealEntry[], alertChanges: boolean) {
        const priceChanged: string[] = [];
        const gone: string[] = [];

        this.updateDrinks(meals);
        this._items.list.forEach(item => {
            const meal = meals.find(({ key }) => key === item.key);

            if (meal) {
                const newPrice = convertPriceToInt(meal.price);

                if (item.price !== newPrice) {
                    this.collectChangedItem(item, priceChanged);
                    item.price = newPrice;
                }
            } else {
                this.collectChangedItem(item, gone);
                this._items.remove(item);
            }
        });

        if (alertChanges) {
            const messages = this.printChangedDiscounts('CartChangeMealsGone', gone)
                + this.printChangedDiscounts('CartChangeMealsPriceChanged', priceChanged);

            if (messages) {
                const message = Env.i18n.t('CartChangeMessage', { restaurant: this.restaurant.name }) + messages.trim();

                Env.alert(Env.i18n.t('CartChangeTitle'), message);
            }
        }
    }

    @bind
    private updateDiscounts(discounts: DiscountEntry[], alertChanges: boolean) {
        const names = {
            changed: new Array<string>(),
            gone: new Array<string>(),
            addedMeals: new Array<string>(),
            changedMeals: new Array<string>(),
            goneMeals: new Array<string>()
        };

        // TODO: currently not listening for newly available vouchers
        if (this._discount) {
            const discount = discounts.find(_discount => _discount.key === this._discount!.key);

            if (!discount || !(discount.forAllMeals || discount.availableForRestaurant(this.restaurant))) {
                names.gone.push(this._discount.name[Env.currentLanguageCode()]);
                this._discount = undefined;
            } else if (discount.getValue(this.totalItemPrice) !== this._discount.getValue(this.totalItemPrice)) {
                names.changed.push(discount.name[Env.currentLanguageCode()]);
                this._discount = discount;
            }
        }

        this._items.list.forEach(item => {
            const currentDiscount = item.discount;
            const discount = item.mealKey && this.getDiscountForMeal(item.mealKey);

            if (discount) {
                const value = discount.getValue(item.price);

                if (!currentDiscount) {
                    this.collectChangedItem(item, names.addedMeals);
                    item.discount = {
                        id: discount.key,
                        value,
                        name: discount.name
                    };
                } else if (value !== currentDiscount.value) {
                    this.collectChangedItem(item, names.changedMeals);
                    currentDiscount.value = value;
                }
            } else if (currentDiscount) {
                this.collectChangedItem(item, names.goneMeals);
                delete item.discount;
            }
        });

        if (alertChanges) {
            const messages = this.printChangedDiscounts('DiscountMealChangeGone', names.goneMeals)
                + this.printChangedDiscounts('DiscountChangeGone', names.gone)
                + this.printChangedDiscounts('DiscountMealChangeValue', names.changedMeals)
                + this.printChangedDiscounts('DiscountChangeValue', names.changed)
                + this.printChangedDiscounts('DiscountMealChangeAdded', names.addedMeals);

            if (messages) {
                const message = Env.i18n.t('DiscountChangeMessage', { restaurant: this.restaurant.name }) + messages.trim();

                Env.alert(Env.i18n.t('CartChangeTitle'), message);
            }
        }

        const selectedDiscountId = this._api.account.data.selectedDiscountId;
        const selectedDiscount = selectedDiscountId && discounts.find(discount => discount.key === selectedDiscountId);

        if (!this._discount && selectedDiscount) {
            this.toggleDiscount(selectedDiscount);
        }
    }

    private collectChangedItem(item: OrderItem, collection: string[]) {
        if (item.amount) {
            collection.push(item.name);
        }
    }

    private printChangedDiscounts(key: string, collection: string[]) {
        return collection.length
            ? Env.i18n.t(key, { count: collection.length, names: collection.join(',\n') }) + '\n\n'
            : '';
    }

    private hasValidTime(discount: DiscountEntry) {
        return !this._meetUpDate || discount.availableForTime(moment(this._meetUpDate));
    }
}
