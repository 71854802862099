import { DialogTitle } from '@material-ui/core';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import Validate, { FirebaseErrorHandler, logError, Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import Alert from '../../helpers/Alert';
import { PrimaryButton, SecondaryButton } from '../button';
import Icon from '../common/Icon';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Checkbox from '../forms/Checkbox';
import Input, { InputValue } from '../forms/Input';
import { ImprintText, RegularText } from '../text';

interface State extends ModalState<{}> {
    passwordValid: boolean;
    showPassword: boolean;
}

@inject('api', 'auth')
export default class DeleteAccount extends Modal<{}, State> {
    public readonly state: State = {
        passwordValid: false,
        showPassword: false,
        params: {}
    };

    private passwordInputRef = React.createRef<Input>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedAuthProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.auth.user?.isAnonymous === false).get();
    }

    public render() {
        let showPasswordIcon = VisibilityOffOutlined;
        let showPasswordIconColor = colors.grey_02;

        if (this.state.showPassword) {
            showPasswordIcon = VisibilityOutlined;
            showPasswordIconColor = colors.matte_black;
        }

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderCta}>
                <ScreenHeader title={Env.i18n.t('DeleteAccountTitle')} onBack={this.back}>
                    <RegularText>
                        {Env.i18n.t('DeleteAccountDescription')}
                    </RegularText>
                </ScreenHeader>
                {this.injected.auth.isThirdPartyUser ? (
                    <Checkbox onToggle={this.confirmCheckbox}>
                        <RegularText>
                            {Env.i18n.t('ConfirmDeleteAccount')}
                        </RegularText>
                    </Checkbox>
                ) : (
                    <Input
                        ref={this.passwordInputRef}
                        placeholder={Env.i18n.t('YourPassword')}
                        validate={this.validatePassword}
                        sanitizeValue={false}
                        type={this.state.showPassword ? 'text' : 'password'}
                    >
                        <Icon src={showPasswordIcon} color={showPasswordIconColor} onClick={this.togglePassword}/>
                    </Input>
                )}
            </Screen>
        );
    }

    @bind
    private validatePassword(password: InputValue) {
        const validation = Validate.password(password.toString(), false);

        this.setState({ passwordValid: validation.valid });

        return validation;
    }

    @bind
    private handleAuthError(error: any) {
        const validation = FirebaseErrorHandler.forPassword(error);

        const validationError = validation.valid ? Validation.error(Env.i18n.t('DeleteAccountFail')) : validation;

        if (this.passwordInputRef.current) {
            this.passwordInputRef.current.setError(validationError);
        } else if (validationError.message) {
            Env.snackbar.error(validationError.message);
        }
    }

    /*
     * NOTE: `this.props.account.user.delete()` triggers the `onUserChanged()` handler of `Account`,
     * leading to `this.props.account.user = undefined`. So if you get an '... is undefined' error
     * after deleting the user, you probably use something like `this.props.account.user!` where you
     * shouldn't (maybe even on a different, but mounted screen).
     */
    @bind
    private confirmDelete() {
        const password = this.passwordInputRef.current?.validateValue();

        if (this.state.passwordValid) {
            this.injected.api.waitFor(
                this.injected.auth.confirmAccount(password?.toString())
                    .then(async confirmed => {
                        if (confirmed) {
                            try {
                                await Backend.deleteAccount();
                                await this.injected.auth.logout();
                                this.close();
                                Alert.notify(Env.i18n.t('DeleteAccountSuccess'), Env.i18n.t('DeleteAccountSuccessDesc'));
                                Env.snackbar.success(Env.i18n.t('DeleteAccountSuccess'));
                            } catch (error) {
                                logError('DeleteAccountScreen.confirmDelete', error);
                                Env.snackbar.error(Env.i18n.t('DeleteAccountFail'));
                            }
                        }
                    })
                    .catch(this.handleAuthError)
            );
        }
    }

    @bind
    private togglePassword() {
        this.setState(state => ({ showPassword: !state.showPassword }));
    }

    @bind
    private confirmCheckbox(checked: boolean) {
        this.setState({ passwordValid: checked });
    }

    @bind
    private renderCta() {
        const SubmitButton = this.state.passwordValid ? PrimaryButton : SecondaryButton;

        return (
            <DialogTitle>
                {this.injected.auth.isThirdPartyUser && (
                    <ImprintText>
                        {Env.i18n.t('HintThirdPartyConfirmation')}
                    </ImprintText>
                )}
                <SubmitButton onClick={this.confirmDelete}>
                    {Env.i18n.t('DeletePermanently')}
                </SubmitButton>
            </DialogTitle>
        );
    }
}
