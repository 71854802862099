import { bind } from 'decko';
import { computed, IReactionDisposer, observable, reaction } from 'mobx';

import Arrays from '../helpers/Arrays';
import { MealFilterConfig, RestaurantFilterConfig } from '../helpers/RestaurantDetails';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import List from '../types/List';
import { CooperationType } from '../types/lunchnow';
import RestaurantEntry, { MealEntry } from '../types/models/RestaurantEntry';
import FilteredDataList from './FilteredDataList';

type ApiType = ApiManager<AccountManager<ApiType>>;
type FilterMode = 'some' | 'every';

interface RestaurantFilters {
    mode: FilterMode;
    filters: RestaurantFilterConfig[];
}

export interface MealEntryWithRestaurant extends MealEntry {
    restaurant: RestaurantEntry;
}

export default class RestaurantList extends FilteredDataList<RestaurantEntry> {
    private api: ApiType;
    private mealReactionDisposer?: IReactionDisposer;
    private mealListenerDisposers: (() => void)[] = [];

    @observable
    private _restaurantFilters: List<RestaurantFilters> = {};

    @observable
    private _lunchMeals: List<MealEntryWithRestaurant[]> = {};

    private static compareByPremium(restaurant1: RestaurantEntry, restaurant2: RestaurantEntry) {
        return Number(!restaurant1.isPremium) - Number(!restaurant2.isPremium);
    }

    @bind
    private compareByOpeningHours(restaurant1: RestaurantEntry, restaurant2: RestaurantEntry) {
        return Number(!restaurant1.getOpeningHours().length) - Number(!restaurant2.getOpeningHours().length);
    }

    /**
     * Call `release()` when no longer used!
     */
    constructor(api: ApiType) {
        super();

        this.api = api;
        this.orderBy(RestaurantEntry.compareByDistance);
        this.addFilter('__typeFilter__', restaurant => restaurant.type !== CooperationType.NonPartner);
        this.startWatching([]);
    }

    @computed
    public get nonPartners() {
        return this._list
            .filter(restaurant => restaurant.type === CooperationType.NonPartner)
            .sort(RestaurantEntry.compareByDistance);
    }

    // @override
    public add(...items: RestaurantEntry[]) {
        super.add(...items);
        this.startWatching(items);
    }

    // @override
    public set(...items: RestaurantEntry[]) {
        this.release();
        super.set(...items);
        this.startWatching(items);
    }

    // @override
    // Only resolve when restaurant data was added
    public resolve() {
        if (this._list.length) {
            reaction(
                () => this._list.every(restaurant => restaurant.data),
                (dataLoaded, reactionHandle) => {
                    if (dataLoaded) {
                        reactionHandle.dispose();
                        this.updateFilteredList();
                        super.resolve();
                    }
                }
            );
        } else {
            super.resolve();
        }
    }

    // @override
    public reset() {
        this.release();
        super.reset();
    }

    public setRestaurantFilters(key: string, mode: FilterMode, originalFilters?: RestaurantFilterConfig[]) {
        const filters = originalFilters?.slice();
        const filter = filters?.length
            ? (restaurant: RestaurantEntry) => filters[mode](filter => filter.availableFor(restaurant))
            : undefined;

        this.setFilter(key, filter);

        if (!filters) {
            delete this._restaurantFilters[key];
        } else {
            this._restaurantFilters[key] = { mode, filters };
        }
    }

    public getByRoutingNameOrKey(value: string) {
        return this.getByRoutingName(value) || this.get(value);
    }

    public getByRoutingName(routingName: string) {
        return computed(() => this.list.find((entry: RestaurantEntry) => entry.routingName === routingName)).get();
    }

    public copy() {
        const copy = new RestaurantList(this.api);

        copy.add(...this.list);

        if (!this.pending) {
            copy.resolve();
        }

        return copy;
    }

    @computed
    public get sortedFilteredList() {
        return this.filteredList.slice().sort(this.compareByOpeningHours).sort(RestaurantList.compareByPremium);
    }

    @computed
    public get lunchMeals() {
        let meals = Arrays.concat(...this.filteredList.map(restaurant => {
            const lunchMeals = this._lunchMeals[restaurant.key];

            return (lunchMeals && !restaurant.isResting() && restaurant.isStillOpenToday) ? lunchMeals : [];
        }));

        if (meals.length) {
            Object.values(this._restaurantFilters).forEach(({ mode, filters }) => {
                const mealFilters = filters.filter(filter => filter instanceof MealFilterConfig) as MealFilterConfig[];

                if (mealFilters.length) {
                    meals = meals.filter(meal => mealFilters[mode](filter => filter.availableForMeal(meal)));
                }
            });
        }

        return meals;
    }

    public release() {
        if (this.mealReactionDisposer) {
            this.mealReactionDisposer();
            this.mealReactionDisposer = undefined;
        }

        this.mealListenerDisposers.forEach(disposer => disposer());
        this.mealListenerDisposers = [];
    }

    private startWatching(items: RestaurantEntry[]) {
        const { lunchMealTypeKeys } = this.api;

        if (!this.mealReactionDisposer) {
            // this reaction might not work correctly on dev after fast refresh
            this.mealReactionDisposer = reaction(
                () => this._list.filter(restaurant => !!restaurant.offers).length,
                loaded => {
                    this.updateFilteredList();
                    this._loaded = (loaded >= this._list.length);
                }
            );
        }

        if (items.length) {
            this.mealListenerDisposers.push(...items.map(restaurant => restaurant.addMealsListener(meals =>
                this._lunchMeals[restaurant.key] = meals
                    .filter(({ typeKey = '' }) => lunchMealTypeKeys.includes(typeKey))
                    .map(meal => ({ ...meal, restaurant }) as MealEntryWithRestaurant)
            )));
        }
    }
}
