import React from 'react';
import styled from 'styled-components';

import colors, { alpha } from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { DiskButton } from '../common/IconButton';

interface Props {
    onInfoPress: () => void;
    onSendPress?: () => void;
}

const OptionContainer = styled.div`
    margin-right: ${SCREEN_PADDING * -1}px;
    position: relative;
    z-index: 2000;
`;

const Container = styled.div`
    align-items: flex-start;
    bottom: 0;
    flex: 1;
    flex-direction: column;
    margin: ${SCREEN_PADDING}px;
    position: absolute;
    right: 0;
`;

// @ts-ignore ts(2615)
const OptionButton = styled(DiskButton).attrs(props => ({
    color: props.onClick ? colors.dark_blue : colors.grey_03,
    diskColor: colors.white,
    diskSize: GRID_SIZE * 5
}))`
    box-shadow: 0 4px 6px ${alpha(colors.matte_black, 0.16)};
    margin: ${GRID_SIZE}px;
`;

export default class Options extends React.PureComponent<Props> {
    public render() {
        const { onInfoPress, onSendPress } = this.props;

        return (
            <OptionContainer>
                <Container>
                    <OptionButton icon={require('../../assets/svg/info.svg')} onClick={onInfoPress} />
                    <OptionButton icon={require('../../assets/svg/send.svg')} onClick={onSendPress} />
                </Container>
            </OptionContainer>
        );
    }
}
