import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { InjectedChatsProps } from '../../../../lib/src/managers/ChatsManager';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import ContactEntity, { flattenContacts } from '../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../lib/src/types/models/Group';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton } from '../button';
import ScreenModal, { ScreenModalWrapper } from '../common/ScreenModal';
import ContactSelection from '../contacts/ContactSelection';
import Checkbox from '../forms/Checkbox';
import InviteDatePicker from '../invitations/InviteDatePicker';

interface Props {
    restaurant?: RestaurantEntry;
    onNavigate: (route: string, params?: string[]) => void;
}

interface State {
    sendInvitation?: boolean;
}

@inject('chats', 'invitationDraft')
@observer
export default class ShareRestaurantModal extends ScreenModalWrapper<Props, State> {
    public readonly state: State = {};

    private contactSelectionRef = React.createRef<ContactSelection>();
    private datePickerRef = React.createRef<InviteDatePicker>();

    private get injected() {
        return this.props as Props & InjectedChatsProps & InjectedInvitationDraftProps;
    }

    public render() {
        return (
            <ScreenModal ref={this.modalRef} title={Env.i18n.t('ShareRestaurant')} FooterComponent={this.renderCta()}>
                <Checkbox
                    checked={this.state.sendInvitation}
                    onToggle={sendInvitation => this.setState({ sendInvitation })}
                    style={{ padding: `0 ${GRID_SIZE}px`, marginTop: GRID_SIZE * 2 }}
                >
                    {Env.i18n.t('CreateInvitation')}
                </Checkbox>
                <ContactSelection
                    ref={this.contactSelectionRef}
                    filterBy={this.isInvitable}
                    counterDescription={Env.i18n.t('SelectContactsForSharingDescription')}
                />
                <InviteDatePicker ref={this.datePickerRef} onConfirm={this.continueInvitationFlow} />
            </ScreenModal>
        );
    }

    private renderCta() {
        return (
            <PrimaryButton onClick={this.submit}>
                {Env.i18n.t('Send')}
            </PrimaryButton>
        );
    }

    @bind
    private async submit() {
        const { restaurant, onNavigate } = this.props;
        const { chats, invitationDraft } = this.injected;
        const selection: ContactEntity[] = this.contactSelectionRef.current?.getSelection() || [];

        if (this.state.sendInvitation) {
            const attendees = (selection.length === 1 && selection[0] instanceof Group) ? selection[0] : flattenContacts(selection);

            invitationDraft.create(true);
            invitationDraft.restaurant = restaurant;
            invitationDraft.setAttendees(attendees);
            this.datePickerRef.current?.open()
        } else if (restaurant?.key) {
            if (await this.waitFor('', chats.getChatForContacts(selection).then(chat => chat?.sendMessage(restaurant)))) {
                onNavigate('chatmessages');
            }
        }
    }

    @bind
    private async continueInvitationFlow() {
        this.close();
        this.props.onNavigate('invitationdraft');
    }

    @bind
    private isInvitable(contact: ContactEntity): boolean {
        const group = (contact instanceof Group) ? contact : undefined;
        const isInvitableGroup = group?.toPersons().some(this.isInvitable);

        return isInvitableGroup || (!group && !this.injected.chats.account.isBlockedBy(contact));
    }
}
