import { Button } from '@material-ui/core';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import { Notification, NotificationType } from '../../../../lib/src/types/models/Notification';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE } from '../../styles/base';
import ProfilePicture from '../social/ProfilePicture';
import { RegularText, TitleText } from '../text';

const hideCss = css`
    transform: translateX(120%);
    opacity: 0;
`;

const showCss = css`
    transform: translateX(0);
    opacity: 1;
`;

const Container = styled.div<{ isClosed: boolean }>`
    display: flex;
    background-color: ${colors.white};
    border-radius: ${GRID_SIZE / 2}px;
    flex-direction: row;
    padding: ${GRID_SIZE * 2}px;
    ${props => props.isClosed ? hideCss : showCss};
`;

const TextColumn = styled.div`
    flex: 1;
    margin-left: ${GRID_SIZE * 2}px;
`;

const NotificationTitle = styled(TitleText)`
    padding-bottom: ${GRID_SIZE}px;
`;

const Buttons = styled.div`
    display: flex;
    margin-top: ${GRID_SIZE * 2}px;
`;

const ActionButton = styled(Button)`
    &&& {
        margin-right: ${GRID_SIZE * 3}px;
    }
`;

const ButtonLabel = styled(RegularText)<{ highlight?: boolean }>`
    &&& {
        ${props => props.highlight && css`
            color: ${colors.teal_900};
        `};
    }
`;

interface Props {
    notification: Notification;
    isClosed: boolean;
    handleOpen: () => void;
    handleHide: () => void;
    style?: React.CSSProperties;
    className?: string;
}

interface State {
    isClosed: boolean
}

interface NotificationAction {
    label: string;
    onClick?: () => void;
    highlighted?: boolean;
}

@inject('account')
@observer
export default class NotificationComponent extends React.Component<Props, State> {
    public readonly state: State = {
        isClosed: false
    };

    private get injected() {
        return this.props as Props & InjectedAccountProps;
    }

    @bind
    private handleClick(action: NotificationAction) {
        const { isClosed } = this.props;

        this.setState({ isClosed: !isClosed });
        // waiting 1s until CSS transition ends TODO: find a correct way to wait after animation ends
        setTimeout(() => this.setState({ isClosed }, action.onClick), 1000);
    }

    public render() {
        const { notification, handleOpen, handleHide, style, className } = this.props;
        let { body } = notification;
        const openAction: NotificationAction = {
            label: notification.actions?.open?.label || Env.i18n.t('Show'),
            onClick: handleOpen,
            highlighted: true
        };
        const actions = [ openAction ];

        if (notification.subject?.type === NotificationType.beingAdded) {
            if (this.injected.account.contacts.get(notification.sender!.key)) {
                body = Env.i18n.t('MutualContactNotificationMessage');
            } else {
                openAction.label = Env.i18n.t('Add');
            }
        }

        if (handleHide) {
            actions.push({
                label: notification.actions?.hide?.label || Env.i18n.t('Hide'),
                onClick: handleHide
            });
        }

        return (
            <Container className={className} style={style} isClosed={this.state.isClosed}>
                {notification.sender && (
                    <ProfilePicture name={notification.sender.displayName} uri={notification.sender.photoURL} />
                )}
                <TextColumn>
                    <NotificationTitle>
                        {notification.title}
                    </NotificationTitle>
                    {!!body && (
                        <RegularText>
                            {body}
                        </RegularText>
                    )}
                    {actions.length > 0 && (
                        <Buttons>
                            {actions.map((action, index) => (
                                <ActionButton key={index} onClick={() => this.handleClick(action)}>
                                    <ButtonLabel highlight={action.highlighted}>
                                        {action.label}
                                    </ButtonLabel>
                                </ActionButton>
                            ))}
                        </Buttons>
                    )}
                </TextColumn>
            </Container>
        );
    }
}
