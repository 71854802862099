import { bind, debounce } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import RestaurantList from '../../../../lib/src/store/RestaurantList';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import EmptyListIndicator from '../common/EmptyListIndicator';
import FilteredFlatList from '../common/FilteredFlatList';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import RestaurantCard, { RESTAURANT_CARD_SMALL_HEIGHT } from '../restaurants/RestaurantCard';
import InviteDatePicker from './InviteDatePicker';

interface State extends ModalState<{}> {
    restaurants: RestaurantList;
}

@inject('api', 'invitationDraft')
@observer
export default class InvitationRestaurant extends Modal<{}, State> {
    public readonly state: State = {
        restaurants: this.injected.api.restaurants,
        params: {}
    };

    private datePickerRef = React.createRef<InviteDatePicker>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public componentDidMount() {
        super.componentDidMount();
        this.cancelSearch();
    }

    public componentWillUnmount() {
        this.releaseRestaurantList();
    }

    public render() {
        const restaurants = this.state.restaurants;
        const emptyHint = restaurants.pending
            ? Env.i18n.t('IsSearching')
            : Env.i18n.t('NoMatchingResults');

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.handleClose} fullHeight={true}>
                <InviteDatePicker ref={this.datePickerRef} onConfirm={this.confirmDatePicker} />
                <ScreenHeader title="" onBack={this.back} />
                <FullSizeContent>
                    <FilteredFlatList<RestaurantEntry>
                        data={restaurants.list}
                        renderItem={this.renderItem}
                        getItemLayout={this.getItemSize}
                        ListEmptyComponent={
                            <EmptyListIndicator waitFor={true} icon={require('../../assets/svg/empty_state_card.svg')} hint={emptyHint}/>
                        }
                        onSearch={this.handleSearchInput}
                        onSearchClear={this.cancelSearch}
                        forceSearchBar={true}
                    />
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private handleClose() {
        Alert.confirmInvitationDiscard(this.injected.invitationDraft, confirmed => {
            if (confirmed) {
                this.close();
            }
        });
    }

    @bind
    private async setRestaurant(restaurant: RestaurantEntry) {
        Env.logEvent('meetup_restaurant_chosen', { resKey: restaurant.key });

        // `getRestaurant` is necessary in case `restaurant` was created via `restaurantResponseToEntryStub`
        this.injected.invitationDraft.restaurant = await this.injected.api.getRestaurant(restaurant.key);
        this.datePickerRef.current?.open();
    }

    private releaseRestaurantList() {
        if (this.state.restaurants !== this.injected.api.restaurants) {
            this.state.restaurants.release();
        }
    }

    @bind
    @debounce(800)
    private handleSearchInput(input: string) {
        if (input.length > 2) {
            const { api } = this.injected;
            const restaurants = new RestaurantList(api);

            this.releaseRestaurantList();
            this.setState({ restaurants });

            Backend.searchForRestaurants(input, CooperationType.Restaurant, api.location.coordinate).then(results => {
                restaurants.set(...results);
                restaurants.resolve();
            });
        }

        // never filters, but replaces the source data instead
        return undefined;
    }

    @bind
    private cancelSearch() {
        this.setState({ restaurants: this.injected.api.restaurants });
    }

    @bind
    private renderItem(item: RestaurantEntry) {
        return (
            <RestaurantCard.Small restaurant={item} onPress={() => this.setRestaurant(item)} />
        );
    }

    @bind
    private getItemSize() {
        return RESTAURANT_CARD_SMALL_HEIGHT;
    }

    @bind
    private confirmDatePicker() {
        this.redirectTo('invitationdraft', undefined, true);
    }
}
