import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { printPrice } from '../../../../lib/src/helpers/formatting';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry, { MealEntry } from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import LargeCard from './LargeCard';

const HINT_SECONDS = 8;

interface Props {
    meal: MealEntry;
    restaurant?: RestaurantEntry;
    onPress?: () => void;
    cart?: Cart;
    /** If set, pressing the card or icon does nothing but showing the value as a hint */
    disabledBecause?: string;
}

interface State {
    hint?: string;
}

@inject('api')
@observer
export default class MealCard extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    private hintTimeout: any;

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    @computed
    private get amount() {
        return this.props.cart?.amount(this.props.meal) || 0;
    }

    public componentWillUnmount() {
        this.hideHint();
    }

    public render() {
        const { meal, restaurant, cart, onPress, disabledBecause } = this.props;
        const price = printPrice(meal.price, meal.currency);
        const cardAction = disabledBecause ? this.showHint : onPress;
        const iconAction = disabledBecause ? this.showHint : this.addToCart;
        const iconColor = disabledBecause
            ? colors.grey_04
            : cart?.amount(meal)! ? colors.teal_400 : colors.navy_blue
        const discount = this.injected.api.account.allDiscounts.find(discount => discount.availableForMeal(meal));
        let discountPrice: string | undefined;
        let discountBadge: string | undefined;
        let discountQuantityleft: number | undefined;

        if (discount) {
            const priceCents = (meal.price || 0) * 100;

            discountPrice = printPrice((priceCents + discount.getValue(priceCents)) / 100, meal.currency);
            discountBadge = cart && discount.typeText;
            discountQuantityleft = discount.quantityLeft;
        }

        return (
            <LargeCard
                restaurant={restaurant}
                onPress={cardAction}
                title={meal.name || ''}
                price={price}
                discountPrice={discountPrice}
                iconContent={cart && (
                    <div style={{ padding: GRID_SIZE }} onClick={iconAction}>
                        <Icon src={AddShoppingCart} color={iconColor} />
                    </div>
                )}
                badgeLabel={discountBadge}
                hint={disabledBecause && this.state.hint}
                quantityLeft={ discountQuantityleft}
            >
                {meal.description}
            </LargeCard>
        );
    }

    @bind
    private addToCart() {
        const { meal, cart } = this.props;

        cart?.change(meal, cart.amount(meal) + 1);
    }

    @bind
    private showHint() {
        this.setState({ hint: this.props.disabledBecause }, () => {
            clearTimeout(this.hintTimeout);
            this.hintTimeout = setTimeout(this.hideHint, HINT_SECONDS * 1000);
        });
    }

    @bind
    private hideHint() {
        clearTimeout(this.hintTimeout);
        this.setState({ hint: undefined });
    }
}
