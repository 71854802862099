import { computed, IReactionDisposer, observable, reaction } from 'mobx';

import Env from '../../Env';
import { getPaymentUserRef } from '../../helpers/Firestore';
import RestaurantsBackend from '../../helpers/RestaurantsBackend';
import ChatsManager from '../../managers/ChatsManager';
import Timestamps from '../Timestamps';
import { ChatMessage, ChatMessageType } from './Chat';
import { Order } from './Order';
import RestaurantEntry from './RestaurantEntry';
import UserInvitation from './UserInvitation';

export default class EnhancedChatMessage implements ChatMessage {
    public readonly key: string;
    public readonly sender: string;
    public readonly timestamp: Date;
    public readonly content: string | ChatMessageType;
    public readonly contentId?: string;
    public readonly isOwn: boolean;

    @observable
    private _restaurant?: RestaurantEntry;

    @observable
    private _invitation?: UserInvitation;

    @observable
    private _order?: Order;

    @observable
    private _loading = false;

    private _unsubscribeLoading?: IReactionDisposer;
    private _unsubscribeInvitation?: () => void;
    private _unsubscribeOrder?: () => void;
    private _waitingFor: Promise<any>[] = [];

    @computed
    public get restaurant() {
        return this._restaurant;
    }

    @computed
    public get invitation() {
        return this._invitation;
    }

    @computed
    public get order() {
        return this._order;
    }

    @computed
    public get loading() {
        return this._loading;
    }

    constructor(data: ChatMessage, chatManager: ChatsManager) {
        const userId = chatManager.account.user?.uid;

        this.key = data.key;
        this.sender = data.sender;
        this.timestamp = Timestamps.toDate(data.timestamp);
        this.content = data.content || '';
        this.contentId = data.contentId;
        this.isOwn = (data.sender === userId);

        if (this.contentId) {
            this._loading = true;
            this._unsubscribeLoading = reaction(
                () => [ this._restaurant, this._invitation, this._order ],
                () => Promise.all(this._waitingFor).then(() => {
                    this._waitingFor = [];
                    this._loading = false;
                })
            );

            if (this.content === 'restaurant') {
                RestaurantsBackend.getRestaurantById(this.contentId).then(restaurant => this._restaurant = restaurant);
            } else if (this.content === 'invitation') {
                this._unsubscribeInvitation = Env.firebase.firestore()
                    .collection('users').doc(userId)
                    .collection('invitations').doc(this.contentId)
                    .onSnapshot(async invitationSnap => {
                        this._invitation = { key: invitationSnap.id, ...invitationSnap.data() } as UserInvitation;
                        this._restaurant = await RestaurantsBackend.getRestaurantById(this._invitation.restaurant);

                        if (this._invitation.order && !this._unsubscribeOrder) {
                            this._unsubscribeOrder = getPaymentUserRef(userId || '')
                                .collection('orders').doc(this._invitation.order)
                                .onSnapshot(async orderSnap => this._order = { key: orderSnap.id, ...orderSnap.data() } as Order);
                        }
                    });
            }
        }
    }

    public waitFor(promise: Promise<any>) {
        this._loading = true;
        this._waitingFor.push(promise);

        return promise;
    }

    public release() {
        if (this._unsubscribeLoading) {
            this._unsubscribeLoading();
        }

        if (this._unsubscribeInvitation) {
            this._unsubscribeInvitation();
        }

        if (this._unsubscribeOrder) {
            this._unsubscribeOrder();
        }
    }
}
