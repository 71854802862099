import * as firebase from 'firebase';

import Env from '../Env';
import { DataListEntry } from '../store/DataList';

export function getPaymentUserRef(uid: string) {
    return Env.firebase.firestore().collection('payment_users').doc(uid);
}

export function getFirebaseObject<T extends DataListEntry>(
    snap: firebase.firestore.DocumentSnapshot,
    deserialize: (data: any) => any = (data) => data
): T | undefined {
    if (snap.exists) {
        return {
            key: snap.id,
            ...deserialize(snap.data())
        } as T;
    }
}

export async function handleHttpResponse<T>(promise: Promise<any>) {
    const response = await promise;

    return JSON.parse(response) as T;
}
