import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { DataListEntry } from '../../../../lib/src/store/DataList';
import TransactionList, { TransactionTime } from '../../../../lib/src/store/TransactionList';
import { GRID_SIZE } from '../../styles/base';
import LoadingIconButton from '../button/LoadingIconButton';
import DynamicList from './DynamicList';
import SafariFix from './SafariFix';
import TabViewPager from './TabViewPager';

const LoadMoreButton = styled(LoadingIconButton)`
    &&& {
        display: flex;
        margin: 0 auto ${GRID_SIZE * 3}px;
    }
`;

interface Props<D extends DataListEntry> {
    transactions?: TransactionList<any, D>;
    renderItem: (item: D, index: number) => React.ReactElement;
    ListHeaderComponent?: (time: TransactionTime) => React.ReactElement | undefined;
    ListFooterComponent?: (time: TransactionTime) => React.ReactElement | undefined;
    /** Only dispayed, if `transactions?.list(time).list === 0 && transactions?.loaded(time)` */
    ListEmptyComponent?: (time: TransactionTime) => React.ReactElement | undefined;
    /** Gets the number of future transactions passed as `%{count}` */
    futureTabLabelKey?: string;
    /** Gets the number of past transactions passed as `%{count}` */
    pastTabLabelKey?: string;
    onAdd?: (time: TransactionTime) => void;
    contentContainerStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
}

@observer
export default class TransactionsList<D extends DataListEntry> extends React.PureComponent<Props<D>> {
    public render() {
        const { futureTabLabelKey = 'UpcomingTransactions', pastTabLabelKey = 'ExpiredTransactions', onAdd } = this.props;

        return (
            <SafariFix.ColumnStretchWrapper>
                <TabViewPager
                    height="full"
                    onAdd={onAdd && this.handleAdd}
                    tabs={[
                        Env.i18n.t(futureTabLabelKey, { count: this.getCount('future') }),
                        Env.i18n.t(pastTabLabelKey, { count: this.getCount('past') })
                    ]}
                >
                    {this.createTab('future')}
                    {this.createTab('past')}
                </TabViewPager>
            </SafariFix.ColumnStretchWrapper>
        );
    }

    private getCount(time: TransactionTime) {
        const { transactions } = this.props;
        const list = transactions?.list(time) || [];
        const loaded = transactions?.loaded(time);
        let count = loaded ? list.length : '…';

        if (loaded && time === 'past' && transactions?.hasNextPastPage) {
            count += '+';
        }

        return count;
    }

    private createTab(time: TransactionTime) {
        const { transactions, renderItem, contentContainerStyle } = this.props;

        return (
            <div>
                <DynamicList<D>
                    data={transactions?.list(time) || []}
                    renderItem={renderItem}
                    ListHeaderComponent={this.renderHeader(time)}
                    ListFooterComponent={this.renderFooter(time)}
                    ListEmptyComponent={this.renderEmptyList(time)}
                    style={contentContainerStyle}
                />
            </div>
        );
    }

    private renderHeader(time: TransactionTime) {
        const { ListHeaderComponent } = this.props;

        return ListHeaderComponent && ListHeaderComponent(time);
    }

    private renderFooter(time: TransactionTime) {
        const { transactions, ListFooterComponent, buttonStyle } = this.props;

        return (
            <>
                {time === 'past' && transactions?.loaded(time) && transactions?.hasNextPastPage && (
                    <LoadMoreButton onPress={this.loadNextPastPage} icon={KeyboardArrowDown} style={buttonStyle}>
                        {Env.i18n.t('ShowMoreItems')}
                    </LoadMoreButton>
                )}
                {ListFooterComponent && ListFooterComponent(time)}
            </>
        )
    }

    private renderEmptyList(time: TransactionTime) {
        const { transactions, ListEmptyComponent } = this.props;

        return (ListEmptyComponent && transactions?.loaded(time)) ? ListEmptyComponent(time) : undefined;
    }

    @bind
    private async loadNextPastPage() {
        await this.props.transactions?.loadNextPastPage()
    }

    @bind
    private handleAdd(tabIndex: number) {
        if (this.props.onAdd) {
            this.props.onAdd(tabIndex === 0 ? 'future' : 'past');
        }
    }
}
