import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Map from '@material-ui/icons/Map';
import ViewList from '@material-ui/icons/ViewList';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import Locations from '../../../../lib/src/helpers/Locations';
import CurrentLocation from '../../../../lib/src/store/CurrentLocation';
import colors from '../../../../lib/src/styles/colors';
import LatLng from '../../../../lib/src/types/LatLng';
import List from '../../../../lib/src/types/List';
import { PlaceResponse } from '../../../../lib/src/types/models/Response';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import { theme } from '../../theme';
import ChatInboxScreen from '../chat/ChatInboxScreen';
import ChatMessagesScreen from '../chat/ChatMessagesScreen';
import { ModalNavigation } from '../common/Modal';
import BlockedUsers from '../contacts/BlockedUsers';
import Contacts from '../contacts/Contacts';
import Details from '../details/Details';
import MediaGalleryModal from '../details/MediaGalleryModal';
import StoreModal from '../details/StoreModal';
import Filters from '../filters/Filters';
import InvitationDraft from '../invitations/InvitationDraft';
import InvitationRestaurant from '../invitations/InvitationRestaurant';
import Invitations from '../invitations/Invitations';
import Order from '../payment/Order';
import OrderRating from '../payment/OrderRating';
import Orders from '../payment/Orders';
import Registration from '../registration/Registration';
import RegistrationOptions from '../registration/RegistrationOptions';
import Settings from '../settings/Settings';
import ChangeEmail from '../social/ChangeEmail';
import DeleteAccount from '../social/DeleteAccount';
import EditName from '../social/EditName';
import EditPicture from '../social/EditPicture';
import Profile from '../social/Profile';
import Vouchers from '../social/Vouchers';
import SupportOptions from '../support/Support';
import { RegularText } from '../text';
import Header from './Header';
import RestaurantsList from './RestaurantsList';
import RestaurantsMap from './RestaurantsMap';
import TagSelectionScreen from './TagSelectionScreen';

const Tabs = styled.div`
    align-self: flex-end;
    display: none;
    text-align: center;

    ${theme.breakpoints.down('md')} {
        display: flex;
        justify-content: space-around;
    }
`;

const Tab = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: ${colors.grey_05};
    border-radius: ${GRID_SIZE}px;
    padding: 6px 7px 7px 6px;
    margin: ${GRID_SIZE}px;
    white-space: nowrap;
`;

// @ts-ignore
const TabLabel = styled(RegularText)`
    margin-left: ${GRID_SIZE / 2}px;
    color: ${colors.grey_01};
`;

const Columns = styled.div`
    background-color: ${colors.grey_05};
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    width: 100%;

    ${theme.breakpoints.down('md')} {
        display: block;
        margin: 0;
    }
`;

const Column = styled.div<{ active: boolean, width: string }>`
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: ${props => props.width};

    ${theme.breakpoints.down('md')} {
        ${props => props.active ? `
            width: 100%;
        ` : `
            /* We can't hide the Column completely since RestaurantsMap.handleRegionChange won't trigger correctly then! */
            height: 1px;
            visibility: hidden;
            margin-top: -1px;
        `}
    }
`;

interface TabConfig {
    tabLabel: string;
    tabIcon: React.ComponentType<SvgIconProps>;
}

const tabs: List<TabConfig> = {
    map: {
        tabLabel: 'Kartenansicht', // TODO: localize
        tabIcon: Map
    },
    list: {
        tabLabel: 'Listenansicht', // TODO: localize
        tabIcon: ViewList
    }
};

interface MatchParams {
    placeId?: string;
}

type Props = RouteComponentProps<MatchParams> & InjectedApiProps;

interface State extends TabConfig {
    showList: boolean;
}

@inject('api')
@observer
export default class Restaurants extends React.Component<Props, State> {
    public readonly state: State = {
        ...tabs.map,
        showList: true
    };

    private modalRef = React.createRef<ModalNavigation>();

    public componentDidMount() {
        if (window.outerWidth < 500 && !this.modalRef.current?.getHash()) {
            this.openModal('download');
        }
    }

    private setLocation(coords: LatLng, name: string) {
        this.props.api.setLocation(new CurrentLocation(coords, name));
    }

    private async loadPlaceById(id?: string) {
        const { match, api } = this.props;
        const lastPlaceIdKey = 'last_place_id';
        const placeId = id || match.params.placeId || localStorage.getItem(lastPlaceIdKey) || 'ChIJuRMYfoNhsUcRoDrWe_I9JgQ'; // Hamburg

        localStorage.setItem(lastPlaceIdKey, placeId);
        api.setLocation(await CurrentLocation.fromGooglePlace({ place_id: placeId } as PlaceResponse));
    }

    @bind
    private async loadInitialPlace() {
        const { modalKey, params } = this.modalRef.current?.parseHash() || {};
        const [ routingName ] = params || [];
        let placeId;

        if (routingName && modalKey === 'details') {
            const restaurant = await this.props.api.getRestaurant(routingName);

            if (restaurant) {
                this.openDetailsModal(restaurant);
                placeId = restaurant.data?.address.placeId;
            }
        }

        this.loadPlaceById(placeId);
    }

    @bind
    private openModal(modal: string, ...params: string[]) {
        this.modalRef.current?.open(modal, params);
    }

    @bind
    private openDetailsModal(restaurant: RestaurantEntry) {
        this.openModal('details', restaurant.routingName || '');
    }

    @bind
    private handlePlaceChange(place: PlaceResponse) {
        this.loadPlaceById(place.place_id);
    }

    @bind
    private handleRestaurantChange(restaurant: RestaurantEntry) {
        this.setLocation(Locations.normalize(restaurant.location), restaurant.name || '');
    }

    @bind
    private handleTabChange() {
        this.setState(state => ({
            showList: !state.showList,
            ...(state.showList ? tabs.list : tabs.map)
        }));
    }

    public render() {
        const { tabIcon: TabIcon, tabLabel, showList } = this.state;

        return (
            <>
                <Header
                    onPlaceSelect={this.handlePlaceChange}
                    onPartnerSelect={this.handleRestaurantChange}
                    onNonPartnerSelect={this.handleRestaurantChange}
                    onOpenModal={this.openModal}
                />

                {/* Tabs for minimum width devices */}
                <Tabs>
                    <Tab onClick={this.handleTabChange}>
                        <TabIcon style={{ color: colors.grey_01, width: 22, height: 22 }} />
                        <TabLabel>
                            {tabLabel}
                        </TabLabel>
                    </Tab>
                </Tabs>
                <Columns>
                    <Column active={showList} width="33.33%">
                        <RestaurantsList onItemSelect={this.openDetailsModal} onOpenModal={this.openModal} />
                    </Column>
                    <Column active={!showList} width="66.66%">
                        <RestaurantsMap onItemSelect={this.openDetailsModal} onLoaded={this.loadInitialPlace} />
                    </Column>
                </Columns>

                <ModalNavigation
                    ref={this.modalRef}
                    modals={{
                        details: Details,
                        filters: Filters,
                        download: StoreModal,
                        registration: RegistrationOptions,
                        login: Registration,
                        orders: Orders,
                        order: Order,
                        orderrating: OrderRating,
                        profile: Profile,
                        settings: Settings,
                        editname: EditName,
                        editpicture: EditPicture,
                        changeemail: ChangeEmail,
                        deleteaccount: DeleteAccount,
                        blockedusers: BlockedUsers,
                        contacts: Contacts,
                        invitations: Invitations,
                        invitationrestaurant: InvitationRestaurant,
                        invitationdraft: InvitationDraft,
                        vouchers: Vouchers,
                        support: SupportOptions,
                        tagselection: TagSelectionScreen,
                        chatinbox: ChatInboxScreen,
                        chatmessages: ChatMessagesScreen
                    }}
                />

                <MediaGalleryModal />
            </>
        );
    }
}
