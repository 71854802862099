import Add from '@material-ui/icons/Add';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedAccountProps } from '../../Account';
import { SCREEN_PADDING } from '../../styles/base';
import { PrimaryFab } from '../button';
import ActionSheet from '../common/ActionSheet';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import BlockUsersModal from './BlockUsersModal';
import FilteredContactList from './FilteredContactList';

interface State extends ModalState<{}> {
    selectedUser?: ContactPerson;
}

@inject('account')
@observer
export default class BlockedUsers extends Modal<{}, State> {
    public readonly state: State = {
        params: {}
    };

    private actionSheetRef = React.createRef<ActionSheet>();
    private contactListRef = React.createRef<FilteredContactList<ContactPerson>>();
    private blockUsersModal = React.createRef<BlockUsersModal>();

    private get injected() {
        return this.props as ModalProps & InjectedAccountProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.verified).get();
    }

    public render() {
        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('BlockedUsers')} onBack={this.back} />
                <FullSizeContent style={{ marginTop: SCREEN_PADDING * -1 }}>
                    <FilteredContactList
                        ref={this.contactListRef}
                        contacts={this.injected.account.blockedUsers.list}
                        onItemPress={this.handleUserPress}
                        ListEmptyComponent={this.renderEmptyState}
                    />
                    <ActionSheet
                        ref={this.actionSheetRef}
                        title={this.state.selectedUser?.name}
                        onClose={this.cancelEditUser}
                        options={[
                            {
                                label: Env.i18n.t('UnblockUser'),
                                action: () => this.unblockUser(false)
                            },
                            {
                                label: Env.i18n.t('UnblockAndAddUser'),
                                action: () => this.unblockUser(true)
                            }
                        ]}
                    />
                    <PrimaryFab onClick={this.addBlockedUser} style={{ margin: SCREEN_PADDING }}>
                        <Add />
                    </PrimaryFab>
                </FullSizeContent>
                <BlockUsersModal ref={this.blockUsersModal} />
            </Screen>
        );
    }

    @bind
    private renderEmptyState() {
        return (
            <EmptyListIndicator
                waitFor={!this.injected.account.blockedUsers.pending}
                icon={require('../../assets/svg/empty_state_blocked.svg')}
                hint={Env.i18n.t('NoBlockedUsers')}
            />
        )
    }

    @bind
    private handleUserPress(selectedUser: ContactPerson, target?: HTMLElement) {
        if (!this.state.selectedUser) {
            this.setState({ selectedUser }, () => this.actionSheetRef.current?.show(target));
        }
    }

    @bind
    private cancelEditUser() {
        this.setState({ selectedUser: undefined });
    }

    @bind
    private async handlePromise(
        promiseMaker: (contact: ContactPerson) => Promise<any>,
        successMessage: string,
        errorMessage: string
    ) {
        const user = this.state.selectedUser;

        if (user) {
            this.contactListRef.current?.waitFor(user, promiseMaker, successMessage, errorMessage);
        }
    }

    @bind
    private async unblockUser(addAsContact: boolean) {
        this.handlePromise(
            user => Backend.unblockUsers([user.key], addAsContact),
            Env.i18n.t('SuccessUnblockUser'),
            Env.i18n.t('ErrorUnblockUser')
        );
    }

    @bind
    private addBlockedUser() {
        this.blockUsersModal.current?.open();
    }
}
