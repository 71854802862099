import { DialogTitle } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import { logError } from '../../../../lib/src/helpers/Validate';
import { InjectedChatsProps } from '../../../../lib/src/managers/ChatsManager';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryButton } from '../button';
import { FunctionButton } from '../common/IconButton';
import Modal, { ModalProps } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import ContactList from '../contacts/ContactList';
import StaticMap from '../details/StaticMap';
import RestaurantMeta from '../restaurants/RestaurantMeta';
import { RegularText, TealLink } from '../text';
import InvitationContactsModal from './InvitationContactsModal';
import InviteDatePicker from './InviteDatePicker';

const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -20px;
    margin-left: ${GRID_SIZE * 2}px;
    position: relative;
    z-index: 1;
`;

const detailsLinkStyle = css`
    margin-top: ${GRID_SIZE}px;
    padding: 0;
`;

const DetailsLink = styled(TealLink)`
    ${detailsLinkStyle};
`;

const NoDetailsHint = styled(RegularText)`
    ${detailsLinkStyle};
`;

@inject('api', 'invitationDraft', 'chats')
@observer
export default class InvitationDraft extends Modal {
    private datePickerRef = React.createRef<InviteDatePicker>();
    private invitationContactsModalRef = React.createRef<InvitationContactsModal>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps & InjectedChatsProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => {
            const { api, invitationDraft } = this.injected;

            return (api.account.verified && !invitationDraft.key && !!invitationDraft.date);
        }).get();
    }

    public render() {
        const { invitationDraft } = this.injected;
        const { restaurant } = invitationDraft;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.handleClose}
                fullHeight={true}
                FooterComponent={(
                    <DialogTitle>
                        <PrimaryButton onClick={this.saveDraft}>
                            {Env.i18n.t('SendInvitation')}
                        </PrimaryButton>
                    </DialogTitle>
                )}
            >
                {!isInvitationExpired(invitationDraft) && (
                    <Toolbar>
                        <FunctionButton icon={AccessTime} onClick={this.showDatePicker}/>
                        <FunctionButton icon={PersonAdd} onClick={this.addContacts} style={{ marginRight: 0 }} />
                    </Toolbar>
                )}
                <ScreenHeader
                    onBack={this.back}
                    title={
                        <>
                            {invitationDraft.date && (
                                <>
                                    {formatDayAndTime(invitationDraft.date)}
                                    <br />
                                </>
                            )}
                            {restaurant?.name}
                        </>
                    }
                >
                    {restaurant?.location && (
                        <>
                            <RestaurantMeta restaurant={restaurant!} displayOpeningTime={false}>
                                {(invitationDraft.isTakeAway !== undefined) && (
                                    Env.i18n.t(invitationDraft.isTakeAway ? 'TakeAway' : 'InStore')
                                )}
                            </RestaurantMeta>
                            {restaurant.type === CooperationType.NonPartner ? (
                                <NoDetailsHint>
                                    {Env.i18n.t('ViewNonPartner')}
                                </NoDetailsHint>
                            ) : (
                                <DetailsLink onClick={this.openDetails}>
                                    {Env.i18n.t('ViewRestaurant')}
                                </DetailsLink>
                            )}
                        </>
                    )}
                </ScreenHeader>
                <InviteDatePicker ref={this.datePickerRef} />
                <InvitationContactsModal ref={this.invitationContactsModalRef} onNavigate={this.redirectTo} />
                <FullSizeContent>
                    <SafariFix.ColumnStretchWrapper>
                        <ContactList
                            contacts={invitationDraft.attendees}
                            disableSections={true}
                            ListFooterComponent={this.renderMap()}
                            ListEmptyComponent={this.renderMap()}
                        />
                    </SafariFix.ColumnStretchWrapper>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private renderMap() {
        const { restaurant } = this.injected.invitationDraft;
        const location = restaurant?.location;

        return !location ? undefined : (
            // `<div>` is needed to correctly render `padding-bottom`
            <div style={{ padding: SCREEN_PADDING }}>
                <StaticMap restaurant={restaurant!} />
            </div>
        );
    }

    @bind
    private handleClose() {
        Alert.confirmInvitationDiscard(this.injected.invitationDraft, confirmed => {
            if (confirmed) {
                this.close();
            }
        });
    }

    @bind
    private showDatePicker() {
        this.datePickerRef.current?.open();
    }

    @bind
    private addContacts() {
        this.invitationContactsModalRef.current?.open();
    }

    @bind
    private openDetails() {
        const { restaurant } = this.injected.invitationDraft;

        if (restaurant) {
            this.redirectTo('details', [ restaurant.data!.routingName, 'invitation' ]);
        }
    }

    @bind
    private saveDraft() {
        const { api, invitationDraft, chats } = this.injected;

        api
            .waitFor(async () => {
                const invitation = await invitationDraft.complete();

                if (invitationDraft.forSharing && invitation.attendees.length > 1) {
                    const chat = await chats.getChatForInvitation(invitation);

                    if (chat) {
                        chat.sendMessage(invitation);
                        this.redirectTo('chatmessages', undefined, true);
                    } else {
                        this.back();
                    }
                } else {
                    this.back();
                }

                Env.snackbar.success(Env.i18n.t('SuccessInvitationSent'));
            })
            .catch(error => {
                logError('InvitationDraftScreen.saveDraft', error);
                Env.snackbar.error(Env.i18n.t('ErrorInvitationNotSent'));
            });
    }
}
