import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import Validate, { logError, Validation } from '../../../../lib/src/helpers/Validate';
import { InjectedGroupsProps } from '../../../../lib/src/managers/GroupsManager';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../lib/src/types/models/Group';
import { InjectedAccountProps } from '../../Account';
import { PrimaryButton } from '../button';
import ModalSection from '../common/ModalSection';
import ScreenModal, { ScreenModalWrapper } from '../common/ScreenModal';
import Input, { InputValue } from '../forms/Input';
import ContactSelectionList from './ContactSelectionList';

interface State {
    editedGroup?: Group;
}

@inject('account', 'groups')
@observer
export default class EditGroupModal extends ScreenModalWrapper<{}, State> {
    public readonly state: State = {};

    private nameInputRef = React.createRef<Input>();
    private contactSelectionRef = React.createRef<ContactSelectionList<ContactPerson>>();

    private get injected() {
        return this.props as InjectedAccountProps & InjectedGroupsProps;
    }

    @bind
    public async open(editedGroup?: Group) {
        this.setState({ editedGroup });
        await this.modalRef.current?.open(); // somehow `super.open()` caused bugs...
    }

    public render() {
        const { editedGroup } = this.state;
        const title = Env.i18n.t(editedGroup ? 'ContactGroupEditMembers' : 'AddGroup');

        return (
            <ScreenModal ref={this.modalRef} title={title} FooterComponent={this.renderCta()}>
                <ModalSection title={Env.i18n.t('NameGroup')} description={Env.i18n.t('NameGroupDescription')}>
                    <Input
                        ref={this.nameInputRef}
                        placeholder={Env.i18n.t('GroupName')}
                        defaultValue={editedGroup?.name || ''}
                        validate={this.validateGroupName}
                        autoCapitalize="words"
                    />
                </ModalSection>
                <ModalSection title={Env.i18n.t('SelectMembers')} description={Env.i18n.t('SelectMembersDescription')} />
                <ContactSelectionList
                    ref={this.contactSelectionRef}
                    contacts={this.injected.account.contacts.list}
                    selected={editedGroup?.toPersons()}
                    disableItems={editedGroup?.participantKeys}
                    minSelection={Constants.MIN_GROUP_MEMBERS}
                    maxSelection={Constants.MAX_ATTENDEES - 1}
                />
            </ScreenModal>
        );
    }

    @bind
    private renderCta() {
        return (
            <PrimaryButton onClick={this.submit}>
                {Env.i18n.t('Save')}
            </PrimaryButton>
        );
    }

    @bind
    private validateGroupName(groupNameValue: InputValue) {
        const groupName = groupNameValue.toString();
        let validation = Validate.groupName(groupName);

        if (validation.valid) {
            const existingGroup = this.injected.groups.groups.list.find(({ name }) => name === groupName);

            if (existingGroup && existingGroup.key !== this.state.editedGroup?.key) {
                validation = Validation.warning(Env.i18n.t('ErrorGroupNameTaken'));
            }
        }

        return validation;
    }

    @bind
    private async submit() {
        const name = this.nameInputRef.current?.validateValue()?.toString();
        const selection = this.contactSelectionRef.current?.getSelection() || [];
        const valid = (name !== undefined) && (selection.length >= Constants.MIN_GROUP_MEMBERS);

        if (valid) {
            const { editedGroup } = this.state;
            const promise = editedGroup
                ? editedGroup.edit(name!, ...selection)
                : this.injected.groups.create(selection, name, true);

            this.waitFor('', promise)!
                .then(group => {
                    this.close();

                    if (group) {
                        Env.snackbar.success(Env.i18n.t(editedGroup ? 'SuccessGroupUpdated' : 'SuccessGroupSent'));
                    }
                })
                .catch(error => {
                    logError('EditGroupModal.submit', error);
                    Env.snackbar.error(Env.i18n.t(editedGroup ? 'ErrorGroupNotUpdated' : 'ErrorGroupNotSent'));
                });
        }
    }
}
