import { bind } from 'decko';
import firebase from 'firebase';
import { computed, observable } from 'mobx';

import Env from '../Env';
import Backend from '../helpers/Backend';
import ChatList from '../store/ChatList';
import ChatMessageList from '../store/ChatMessageList';
import { ChatData, ChatHeader } from '../types/models/Chat';
import ContactEntity, { flattenContacts } from '../types/models/ContactEntity';
import Group from '../types/models/Group';
import Invitation from '../types/models/Invitation';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';
import GroupsManager from './GroupsManager';

type ApiType = ApiManager<AccountManager<ApiType>>;

export interface InjectedChatsProps {
    chats: ChatsManager;
}

export default class ChatsManager {
    private _api: ApiType;
    private _groups: GroupsManager;

    @observable
    private _currentChat?: ChatMessageList;

    public static get collectionRef() {
        return Env.firebase.firestore().collection('chats');
    }

    public get account() {
        return this._api.account;
    }

    public get groups() {
        return this._groups;
    }

    @computed
    public get currentChat() {
        return this._currentChat;
    }

    constructor(api: ApiType, groups: GroupsManager) {
        this._api = api;
        this._groups = groups;
    }

    public getChats() {
        return new ChatList(this);
    }

    public async getChat(key: string) {
        if (key !== this._currentChat?.key) {
            this._currentChat = await ChatMessageList.createForKey(key, this);
        }

        return this._currentChat;
    }

    public async getChatForContact(contact: ContactEntity) {
        const userId = this.account.user?.uid!;
        let chatDoc: firebase.firestore.DocumentSnapshot | undefined;

        if (contact instanceof Group) {
            chatDoc = await ChatsManager.collectionRef.doc(contact.key).get()
                .catch(() => undefined /* probably not existing; ignore */);
        } else {
            const chats = await ChatsManager.collectionRef
                .where('participantKeys', 'array-contains', userId) // needed to comply to rules
                .where('participantKeysStr', '==', Group.participantKeysToString([ contact.key, userId ]))
                .limit(1)
                .get();

            chatDoc = chats.docs[0];
        }

        if (chatDoc?.exists) {
            // re-activate, if needed
            if (!(chatDoc.data() as ChatData).activeParticipantKeys?.includes(userId)) {
                await chatDoc.ref.update({ activeParticipantKeys: Env.firebase.firestore.FieldValue.arrayUnion(userId) });
            }

            return this.getChat(chatDoc.id);
        } else {
            this._currentChat = await ChatMessageList.createForContact(contact, this);

            return this._currentChat;
        }
    }

    public async getChatForContacts(contacts: ContactEntity[]) {
        const contactEntity = (contacts.length > 1) ? await this._groups.getOrCreate(flattenContacts(contacts)) : contacts[0];

        if (contactEntity) {
            return this.getChatForContact(contactEntity);
        }
    }

    public async getChatForInvitation(invitation: Invitation) {
        if (invitation.group) {
            const group = this._groups.groups.get(invitation.group);

            return group && this.getChatForContact(group);
        } else {
            const userId = this.account.user?.uid!;
            const otherAttendees = invitation.attendees.filter(({ key }) => key !== userId);

            if (otherAttendees.length) {
                let chat = await this.getChatForContacts(otherAttendees);

                if (chat && otherAttendees.length > 1) {
                    const groupId = await Backend.setInvitationGroup(invitation.key, chat.key);

                    if (!groupId) {
                        Env.snackbar.error(Env.i18n.t('ErrorUnknown'));
                        this.closeChat();

                        return;
                    } else if (groupId !== chat.key) {
                        // another group was already set for the invitation
                        chat = await this.getChat(groupId);
                    }

                    invitation.group = groupId;
                }

                return chat;
            }
        }
    }

    public closeChat() {
        if (this._currentChat) {
            this._currentChat.release();
            this._currentChat = undefined;
        }
    }

    // placed here so chat doesn't need to be loaded before it is hidden
    public async hideChat(key: string) {
        try {
            await ChatsManager.collectionRef.doc(key).update({
                activeParticipantKeys: Env.firebase.firestore.FieldValue.arrayRemove(this.account.user?.uid)
            });
        } catch (error) {
            // probably no valid chat exists for `key`; ignore
        }
    }

    @bind
    public createChatHeader(doc: firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot): ChatHeader {
        const data = { participants: {}, ...doc.data() } as ChatData; // `participants` fallback needed for old data
        const { [this.account.user?.uid!]: me, ...otherParticipants } = data.participants;

        return { ...data, key: doc.id, otherParticipants };
    }
}
