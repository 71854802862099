import 'firebase/messaging';

import Env from '../../lib/src/Env';
import AccountManager from '../../lib/src/managers/AccountManager';
import Api from './Api';
import Auth from './Auth';
import Legal from './helpers/Legal';
import {Notification as NotificationDefaultType, NotificationType} from '../../lib/src/types/models/Notification';
import Backend from '../../lib/src/helpers/Backend';
import User from '../../lib/src/types/models/User';
import {reaction} from 'mobx';

export interface InjectedAccountProps {
    account: Account;
}

export default class Account extends AccountManager<Api> {
    private _auth?: Auth;
    private static readonly _VAPID_KEY: string = "BFBFG1K-KHLb1hjyChEFSpsLK6qEmd0TE9kEOYYs6SiAad8D2cuDecDdQDZ90IJXTFsjk3JWEQVfi5ATD5MqgoI";

    constructor(waitFor: Promise<any> = Promise.resolve()) {
        super(waitFor);

        // triggers on log-in and log-out, but not on registration (because it only links the account)
        Env.firebase.auth().onAuthStateChanged(this.onUserChange);
    }

    public static get VAPID_KEY(): string {
        return this._VAPID_KEY;
    }

    public setAuth(auth: Auth) {
        this._auth = auth;
    }

    // TODO: if possible, move to super class later
    public async connectUsers() {
    // TODO: re-activate for contacts
    //     if (this.verified) {
    //         const contactsToAdd = await LocalData.get('CONNECT_USERS');

    //         if (contactsToAdd && Array.isArray(contactsToAdd) && contactsToAdd.length > 0) {
    //             try {
    //                 await Backend.putConnectUsers(contactsToAdd);
    //                 LocalData.remove('CONNECT_USERS');
    //                 Env.snackbar.success(Env.i18n.t('SuccessContactsAdded', { count: contactsToAdd.length }));
    //             } catch (error) {
    //                 logError('Account.connectUsers', error);
    //             }
    //         }
    //     }
    }

    // TODO: if possible, move to super class later
    protected handleUserDataChange() {
        // Update Firebase Messaging Token
        this.toggleFcmToken(true);
    }

    // TODO: if possible, move to super class later
    protected reactivateDeletedAccount() {
        return Legal.confirmVisibility();
    }

    // TODO: if possible, move to super class later
    protected demandPolicyAcceptance() {
        this.api.schedule(Legal.acceptUpdatedPolicy).then((accepted: boolean) => {
            if (accepted) {
                this.acceptPolicy();
            } else {
                this.api.waitFor(this._auth!.logout())
                    .then(() => Env.snackbar.success(Env.i18n.t('SuccessLogout')))
                    .catch(() => undefined);
            }
        });
    }

    public async toggleFcmToken(set: boolean) {
        if(Env.firebase.messaging.isSupported() && this.loggedIn) {
            try {
                const fcmToken: string = await new Promise(resolve => resolve(Env.firebase.messaging().getToken()));

                if(fcmToken && Notification.permission === 'granted') {
                    const uid = this.user?.uid;

                    const userData: User = await new Promise(resolve => reaction(
                        () => this.data,
                        (data, handle) => {
                            if (data.key === uid) {
                                handle.dispose();
                                resolve(data);
                            }
                        },
                        { fireImmediately: true }
                    ));

                    const message = set ? 'Setting FCM Token' : 'Removing FCM Token';
                    const deviceTokens = set
                        ? Env.firebase.firestore.FieldValue.arrayUnion(fcmToken)
                        : Env.firebase.firestore.FieldValue.arrayRemove(fcmToken);

                    // If new user || If new token || If removing token
                    if(!userData.deviceTokens || !userData.deviceTokens.includes(fcmToken) || !set) {
                        Env.firebase
                            .firestore()
                            .collection('users')
                            .doc(uid)
                            .update({ deviceTokens })
                            .then(() => console.log(`${message} done for user ${uid}`))
                            .catch(() => console.error(`Account.toggleFcmToken: ${message} failed`));
                    }
                }
            } catch (err) {
                console.warn("Something went wrong! Browser does not support FCM push notifications.");
            }
        } else {
            // Browser not supporting Push notifications.
            // Foreground notifications will be shown, when user already on page.
        }
    }

    public openNotification(notification: NotificationDefaultType) {
        if (notification.receiver && notification.receiver !== this.user?.uid) {
            return Env.alert(Env.i18n.t('WrongReceiverTitle'), Env.i18n.t('WrongReceiverText'));
        }

        if (notification.subject) {
            const type = notification.subject.type;
            const key = notification.subject.id;

            switch (type) {
                case NotificationType.contact:
                    window.location.hash = 'contacts';
                    break;
                case NotificationType.invitation:
                    // `ifReady` is needed to wait for account to be logged in
                    this.api.waitFor(this.api.ifReady.then(async () => {
                        try {
                            const invitation = await Env.firebase.firestore()
                                .collection('users').doc(this.user?.uid)
                                .collection('invitations').doc(notification.subject!.id)
                                .get();

                            if (invitation.exists) {
                                window.location.hash = 'invitations';
                            } else {
                                Env.alert(Env.i18n.t('InvitationDeletedTitle'), Env.i18n.t('InvitationDeletedText'));
                            }
                        } catch (error) {
                            console.error(`Account.openNotification ${NotificationType.invitation}`, error);
                            Env.snackbar.error(Env.i18n.t('ErrorNoInvitation'));
                        } finally {
                            // TODO: Is useful for web ?
                            // this.api.setTabMarked('Invitations', false);
                        }
                    }));

                    break;
                case NotificationType.beingAdded:
                    const senderUid = notification.sender!.key;

                    if (this.contacts.get(senderUid)) {
                        window.location.hash = 'contacts';
                    } else {
                        this.api.waitFor(Backend.addContacts([senderUid]))
                            .then(() => {
                                window.location.hash = 'contacts';
                            })
                            .catch((error: any) => {
                                console.error(`Account.openNotification ${NotificationType.beingAdded}`, error);
                                Env.snackbar.error(Env.i18n.t('ErrorContactsNotAdded', { count: 1 }));
                            });
                    }
                    break;
                case NotificationType.orderCanceledByRestaurant: // TODO: ? Supposed to be like that?
                case NotificationType.discount:
                    window.location.hash = 'vouchers';
                    break;
                case NotificationType.chatMessage:
                    window.location.hash = `chatmessages/${key}`;
                    break;
            }
        }
    }
}
