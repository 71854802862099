import { action, autorun, computed, observable } from 'mobx';

export interface DataListEntry {
    key: string;
}

export interface Snapshot {
    docs: Array<{
        id: string;
        data: () => any;
    }>;
}

/*
 * msamsel: Tried to make it extend `Array<T>`, but the array wrapping of
 * MobX's `@observable` broke it
 */
export default class DataList<T extends DataListEntry> {
    /**
     * Allows to handle `!this.pending` as a Promise.
     */
    public readonly ifReady = new Promise(resolve =>
        autorun(reaction => {
            if (!this._pending) {
                reaction.dispose();
                resolve(undefined);
            }
        })
    );

    @observable
    protected _pending = true;

    @observable
    protected _list: T[] = [];

    @observable
    protected _loaded = false;

    /**
     * @var T[] Raw content of the `DataList`
     */
    @computed
    public get list() {
        return this._list;
    }

    /**
     * @var boolean `true` if the `DataList` is waiting for its first content item
     */
    @computed
    public get pending() {
        return this._pending;
    }

    /**
     * @var boolean `true` if the `DataList` finished loading its content (i.e. `resolve()` has been called)
     */
    @computed
    public get loaded() {
        return this._loaded;
    }

    /**
     * @var boolean `true` if the `DataList` doesn't have any content (yet)
     */
    @computed
    public get empty() {
        return this._list.length < 1;
    }

    @action
    public reset() {
        this._loaded = false;
        this._pending = true;
        this._list = [];
    }

    @action
    public resolve() {
        this._loaded = true;
        this._pending = false;
    }

    @action
    public set(...items: T[]) {
        this._list = [ ...items ];
        this._pending = false;
    }

    // TODO: replace items with same key?
    @action
    public add(...items: T[]) {
        this._list.push(...items);
        this._pending = false;
    }

    @action
    public remove(item: T | string) {
        const keyToRemove = (typeof item === 'string') ? item : item.key;

        this.set(...this._list.filter(({ key }) => key !== keyToRemove));
    }

    @action
    public addQuerySnapshotChildren(snapshot: Snapshot, override = false) {
        const write = override ? this.set : this.add;

        write.bind(this)(...snapshot.docs.map(doc => Object.assign({}, doc.data(), { key: doc.id }) as T));
    }

    public get(key?: string) {
        return computed(() => this._list.find(entry => entry.key === key)).get();
    }
}
