import { Button } from '@material-ui/core';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import { DescriptionText } from '../text';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const WalkIcon = styled(Icon).attrs({
    color: colors.grey_02,
    size: GRID_SIZE * 1.5,
    src: DirectionsWalk
})``;

const MoiaIcon = styled.img.attrs({
    alt: '',
    src: require('../../assets/svg/moia_bus.svg')
})`
    margin: 0 ${GRID_SIZE / 4}px;
`;

const MoiaButton = styled(Button).attrs({
    size: 'small',
    variant: 'outlined'
})`
    &&& {
        margin-left: ${GRID_SIZE}px;
        font-size: 0.8rem;
    }
`;

interface Props {
    restaurant: RestaurantEntry;
    onOpenMoia?: () => void;
}

@observer
export default class Distance extends React.Component<Props> {
    public render() {
        const { restaurant, onOpenMoia } = this.props;
        const { distanceDescription } = restaurant.transportationInfo;
        const displayMoia = restaurant.data?.address.locality === 'Hamburg';

        return (
            <Container>
                <DescriptionText>
                    {distanceDescription}
                </DescriptionText>
                <WalkIcon />
                {displayMoia && (
                    <>
                        <DescriptionText>
                            {Constants.INFO_SEPARATOR}
                            MOIA
                        </DescriptionText>
                        <MoiaIcon />
                        {onOpenMoia && (
                            <>
                                <DescriptionText>
                                    {Constants.INFO_SEPARATOR}
                                </DescriptionText>
                                <MoiaButton onClick={onOpenMoia}>
                                    {Env.i18n.t('RequestMoia')}
                                </MoiaButton>
                            </>
                        )}
                    </>
                )}
            </Container>
        );
    }
}
