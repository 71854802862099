import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { InjectedChatsProps } from '../../../../lib/src/managers/ChatsManager';
import ChatList from '../../../../lib/src/store/ChatList';
import colors from '../../../../lib/src/styles/colors';
import { ChatHeader } from '../../../../lib/src/types/models/Chat';
import { PrimaryButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import NewScreenHeader from '../common/NewScreenHeader';
import Screen from '../common/Screen';
import ChatCard from './inbox/ChatCard';
import ChatParticipantsModal from './inbox/ChatParticipantsModal';

interface State extends ModalState<{}> {
    chats?: ChatList;
}

@inject('chats')
@observer
export default class ChatInboxScreen extends Modal<{}, State> {
    public readonly state: State = {
        params: {}
    };

    private chatParticipantsModalRef = React.createRef<ChatParticipantsModal>();

    private get injected() {
        return this.props as ModalProps & InjectedChatsProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.chats.account.loggedIn).get();
    }

    public componentDidMount() {
        super.componentDidMount();
        this.setState({ chats: this.injected.chats.getChats() });
    }

    public componentWillUnmount() {
        if (this.state.chats) {
            this.state.chats.release();
            this.setState({ chats: undefined });
        }
    }

    render() {
        const chats = this.state.chats?.list || [];
        const backgroundColor = chats.length ? colors.light_background : colors.white;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                fullHeight={true}
                HeaderComponent={this.renderHeader}
                FooterComponent={this.renderFooter}
                contentStyle={{ backgroundColor }}
            >
                {/* TODO: use FlatList */}
                {chats.length ? (
                    chats.map(this.renderChatCard)
                ) : (
                    <EmptyListIndicator
                        waitFor={true}
                        icon={require('../../assets/svg/empty_state_user.svg')}
                        hint={Env.i18n.t('NoChats')}
                    />
                )}
                <ChatParticipantsModal ref={this.chatParticipantsModalRef} onNavigate={this.redirectTo} />
            </Screen>
        );
    }

    @bind
    private renderHeader() {
        return (
            <NewScreenHeader title={Env.i18n.t('Inbox')} onBack={this.back} />
        );
    }

    @bind
    private renderFooter() {
        return (
            <PrimaryButton onClick={this.goToChatCreateScreen}>
                {Env.i18n.t('NewMessage')}
            </PrimaryButton>
        );
    }

    @bind
    private renderChatCard(item: ChatHeader) {
        return (
            <ChatCard key={item.key} chat={item} onPress={() => this.redirectTo('chatmessages', [ item.key ])} />
        );
    }

    @bind
    private goToChatCreateScreen() {
        this.chatParticipantsModalRef.current?.open();
    }
}
