import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import { InjectedGroupsProps } from '../../../../lib/src/managers/GroupsManager';
import ContactEntity, { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../lib/src/types/models/Group';
import { InjectedAccountProps } from '../../Account';
import ModalSection from '../common/ModalSection';
import SafariFix from '../common/SafariFix';
import TabViewPager from '../common/TabViewPager';
import { EmptyContactsList, EmptyGroupsList } from '../social/EmptyList';
import AddContactsModal from './AddContactsModal';
import ContactSelectionList, { ContactSelectionCounter } from './ContactSelectionList';
import EditGroupModal from './EditGroupModal';

const ContactSelectionListContainer = styled.div`
    flex-direction: column;
`;

const ContactSelectionHeader = styled(ModalSection)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

interface Props {
    selected?: ContactEntity[];
    filterBy?: (contact: ContactEntity) => boolean;
    counterDescription?: string;
    required?: boolean;
}

interface State {
    selections: [ ContactPerson[], Group[] ];
}

@inject('account', 'groups')
@observer
export default class ContactSelection extends React.PureComponent<Props, State> {
    public readonly state: State = {
        selections: [ [], [] ]
    };

    private tabsRef = React.createRef<TabViewPager>();
    private tabRefs: [ React.RefObject<ContactSelectionList<ContactPerson>>, React.RefObject<ContactSelectionList<Group>> ] = [
        React.createRef<ContactSelectionList<ContactPerson>>(),
        React.createRef<ContactSelectionList<Group>>()
    ];
    private addContactsModal = React.createRef<AddContactsModal>();
    private addGroupModal = React.createRef<EditGroupModal>();

    private get injected() {
        return this.props as InjectedAccountProps & InjectedGroupsProps;
    }

    @bind
    public getSelection() {
        return this.tabRefs[this.tabsRef.current?.getTab() ?? -1]?.current?.getSelection() || [];
    }

    public render() {
        const { selected, filterBy = Boolean, counterDescription = '', required } = this.props;
        const { account, groups } = this.injected;
        const invitableContacts = account.contacts.list.filter(filterBy);
        const invitableGroups = groups.groups.list.filter(filterBy);
        let selectedContacts: ContactPerson[] = [];
        let selectedGroups: Group[] = [];

        selected?.forEach(contact => {
            if (contact instanceof Group) {
                selectedGroups.push(contact);
            } else {
                selectedContacts.push(...contact.toPersons());
            }
        });

        return (
            <>
                <SafariFix.ColumnStretchWrapper>
                    <TabViewPager
                        ref={this.tabsRef}
                        height="full"
                        initialTab={(selectedGroups.length && !selectedContacts.length) ? 1 : 0}
                        tabs={[
                            Env.i18n.t('Contacts', { count: invitableContacts.length }),
                            Env.i18n.t('Groups', { count: invitableGroups.length })
                        ]}
                    >
                        <ContactSelectionListContainer>
                            <ContactSelectionList<ContactPerson>
                                ref={this.tabRefs[0]}
                                selected={selectedContacts}
                                contacts={invitableContacts}
                                selectionPlaceholder={Env.i18n.t('AddContactCTA')}
                                ListCounterComponent={state => (
                                    <ContactSelectionHeader title={Env.i18n.t('SelectAttendees')} description={counterDescription}>
                                        <ContactSelectionCounter {...state} max={Constants.MAX_ATTENDEES - 1} />
                                    </ContactSelectionHeader>
                                )}
                                ListEmptyComponent={this.renderEmptyContactsState}
                                minSelection={required ? 1 : undefined}
                                maxSelection={Constants.MAX_ATTENDEES - 1}
                            />
                        </ContactSelectionListContainer>
                        <ContactSelectionListContainer>
                            <ContactSelectionList<Group>
                                ref={this.tabRefs[1]}
                                selected={selectedGroups}
                                contacts={invitableGroups}
                                selectionPlaceholder={Env.i18n.t('AddGroupCTA')}
                                ListCounterComponent={state => (
                                    <ContactSelectionHeader title={Env.i18n.t('SelectAttendeeGroup')} description={counterDescription}>
                                        <ContactSelectionCounter {...state} max={1} />
                                    </ContactSelectionHeader>
                                )}
                                ListEmptyComponent={this.renderEmptyGroupsState}
                                minSelection={required ? 1 : undefined}
                                maxSelection={1}
                            />
                        </ContactSelectionListContainer>
                    </TabViewPager>
                </SafariFix.ColumnStretchWrapper>
                <AddContactsModal ref={this.addContactsModal} />
                <EditGroupModal ref={this.addGroupModal} />
            </>
        );
    }

    @bind
    private renderEmptyContactsState() {
        return (
            <EmptyContactsList onPress={() => this.addContactsModal.current?.open()} />
        );
    }

    @bind
    private renderEmptyGroupsState() {
        return (
            <EmptyGroupsList onPress={() => this.addGroupModal.current?.open()} />
        );
    }
}
