import { CircularProgress, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { bind } from 'decko';
import React, { ChangeEvent, CSSProperties } from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { BUTTON_ICON_SIZE } from '../common/Icon';
import { INPUT_TEXT_STYLE } from './Input';

const SearchBarContainer = styled.div`
    align-items: center;
    background-color: ${colors.grey_05};
    border-radius: ${GRID_SIZE}px;
    color: ${colors.grey_02};
    display: flex;
    flex-direction: row;
    height: ${GRID_SIZE * 5.5}px;
    padding-left: ${GRID_SIZE}px;
`;

const SearchBarInput = styled.input`
    ${INPUT_TEXT_STYLE};
    background: none;
    border: none;
    flex: 1;
    height: 100%;
    margin-left: ${GRID_SIZE}px;
    padding: 0;

    ::placeholder {
        color: ${colors.grey_02};
    }
`;

interface Props {
    onInput: (text: string) => void;
    value?: string;
    isFocused?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onBackPress?: () => void;
    onSearchSubmit?: () => void;
    placeholderText?: string;
    onClear?: () => void;
    loading?: boolean;
    style?: CSSProperties;
    className?: string;
}

export default class SearchBar extends React.PureComponent<Props> {
    public readonly state = {
        placeholderText: this.props.placeholderText,
        showClearButton: false
    };

    private searchBarInput = React.createRef<HTMLInputElement>();

    @bind
    public clearSearchBar() {
        // TODO: always call `this.props.onInput('')`?
        this.searchBarInput.current!.value = '';
        this.setState({ showClearButton: false });

        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    @bind
    public setPlaceHolder(placeholderText: string) {
        this.setState({ placeholderText });
    }

    public render() {
        return (
            <SearchBarContainer className={this.props.className} style={this.props.style}>
                {this.props.loading ? (
                    <IconButton size="small" disabled={true}>
                        <CircularProgress style={{ height: BUTTON_ICON_SIZE, width: BUTTON_ICON_SIZE }} />
                    </IconButton>
                ) : this.props.onBackPress ? (
                    <IconButton size="small" onClick={this.cancel}>
                        <ArrowBack style={{ color: colors.grey_02 }} />
                    </IconButton>
                ) : (
                    <IconButton size="small" disabled={true}>
                        <Search style={{ color: colors.grey_02 }} />
                    </IconButton>
                )}
                <SearchBarInput
                    placeholder={this.props.placeholderText || Env.i18n.t('Search')}
                    onChange={this.handleChange}
                    onKeyDown={this.handlePossibleSubmit}
                    defaultValue={this.props.value}
                    autoFocus={this.props.isFocused}
                    onFocus={this.handleFocus}
                    onBlur={this.props.onBlur}
                    ref={this.searchBarInput}
                />
                {(this.props.onBackPress || this.state.showClearButton) && (
                    <IconButton onClick={this.clearSearchBar} size="small" style={{ marginRight: 10 }}>
                        <Close style={{ color: colors.grey_02 }} />
                    </IconButton>
                )}
            </SearchBarContainer>
        );
    }

    @bind
    private handleChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.onInput(event.currentTarget!.value);
    }

    @bind
    private handleFocus() {
        this.setState({ showClearButton: true });

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    @bind
    private handlePossibleSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.onSearchSubmit && event.keyCode === 13) { // === Enter key
            this.props.onSearchSubmit();
        }
    }

    @bind
    private cancel() {
        this.searchBarInput.current?.blur();

        if (this.props.onBackPress) {
            this.props.onBackPress();
        }
    }
}
