import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { InjectedGroupsProps } from '../../../../lib/src/managers/GroupsManager';
import { InjectedAccountProps } from '../../Account';
import Modal, { ModalProps } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import TabViewPager from '../common/TabViewPager';
import ContactsTab, { ContactsTabType } from './ContactsTab';
import GroupsTab, { GroupsTabType } from './GroupsTab';

@inject('account', 'groups')
@observer
export default class Contacts extends Modal {
    private tabRefs: [ React.RefObject<ContactsTabType>, React.RefObject<GroupsTabType> ] = [
        React.createRef<ContactsTabType>(),
        React.createRef<GroupsTabType>()
    ];

    private get injected() {
        return this.props as ModalProps & InjectedAccountProps & InjectedGroupsProps;
    }

    protected async hydrateParams(params: string[]) {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.loggedIn).get();
    }

    public render() {
        const { account, groups } = this.injected;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('Contacts')} onClose={this.close} />
                <FullSizeContent>
                    <SafariFix.ColumnStretchWrapper>
                        <TabViewPager
                            height="full"
                            onAdd={this.addContact}
                            tabs={[
                                Env.i18n.t('AllContacts', { count: account.contacts.list.length }),
                                Env.i18n.t('Groups', { count: groups.groups.list.length })
                            ]}
                        >
                            <ContactsTab ref={this.tabRefs[0]} contacts={account.contacts.list} onNavigate={this.redirectTo} />
                            <GroupsTab ref={this.tabRefs[1]} contacts={groups.groups.list} onNavigate={this.redirectTo} />
                        </TabViewPager>
                    </SafariFix.ColumnStretchWrapper>
                    {this.tabRefs[0].current?.renderModals()}
                    {this.tabRefs[1].current?.renderModals()}
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private addContact(tabIndex: number) {
        this.tabRefs[tabIndex].current?.addNew();
    }
}
