export default {
    About: 'Über %{restaurant}',
    Accept: 'Teilnehmen',
    AcceptGDPR: 'Akzeptieren',
    AcceptInvitation: 'Verabredung zusagen',
    Accessible: 'Barrierefrei',
    Add: 'Hinzufügen',
    AddBlockedUsers: 'LunchNow-Nutzer blockieren',
    AddContactCTA: 'Noch keinen Kontakt ausgewählt',
    AddContactHeader: 'Zu Kontakten hinzufügen',
    AddDrink: 'Getränk dazubestellen',
    AddDrinkTitle: 'Noch ein Getränk zum Essen?',
    Added: 'Hinzugefügt',
    AddGroup: 'Gruppe erstellen',
    AddGroupCTA: 'Noch keine Gruppe ausgewählt',
    AdditionalFilters: 'Weitere Filter',
    AddOrder: 'Bestellung hinzufügen',
    Address: 'Adresse',
    AddressString: '%{street} %{streetNumber}\n%{postalCode} %{locality}',
    AddToCalendar: 'Zu deinem Kalendar hinzufügen',
    All: 'Alle',
    AllContacts: 'Alle Kontakte (%{count})',
    AmericanExpress: 'American Express',
    And: 'und',
    AnonCallToActionInvite: 'Verabrede dich zum Lunch. Deine Freunde, Kollegen und Geschäftspartner warten auf dich!',
    AnonCallToActionOrder: 'Wähle dein Lunch im Voraus und spare Zeit. Oder verabrede dich und bestelle bereits für alle!',
    AnonCallToActionTitle: 'Dein persönlicher LunchNow Account.',
    ApplePay: 'Apple Pay',
    Apply: 'Übernehmen',
    ApplyFilter: 'Ergebnisse anzeigen',
    ArrangeMeetup: 'Verabreden',
    BecomeAPartner: 'Partner werden',
    BecomeInvisible: 'Für alle LunchNow-Nutzer sichtbar',
    BecomeVisible: 'Für alle LunchNow-Nutzer unsichtbar',
    Block: 'Blockieren',
    Blocked: 'Blockiert',
    BlockedUsers: 'Blockierte LunchNow-Nutzer',
    BookatableMessage: 'Jetzt mit Bookatable reservieren',
    BookatableTitle: 'Online reservieren?',
    CallForCancel: 'Anrufen, um zu stornieren?',
    CallForReservation: 'Anrufen, um zu reservieren?',
    CallNumber: 'Anrufen?',
    CameraPhotoGalleryDescription: 'LunchNow benötigt Zugriff auf deine Kamera oder Fotos um dein Profilbild zu ändern',
    Cancel: 'Abbrechen',
    CanceledByRestaurant: 'abgelehnt',
    CanceledCompletely: 'vollständig storniert',
    CanceledPartly: 'teilweise storniert',
    CancelInvitation: 'Verabredung stornieren',
    CancellationAbbr: 'Storno',
    CancelOrderReasonTitle: 'Grund der Stornierung',
    CancelOrderReason_mistake: 'Aus Versehen bestellt',
    CancelOrderReason_no_time: 'Doch keine Zeit',
    CancelOrderReason_restaurant_declined: 'Vom Restaurant abgelehnt',
    CancelOrderReason_restaurant_declined_no_capacity: 'Keine Sitzplätze verfügbar',
    CancelOrderReason_restaurant_declined_no_reaction: 'Keine Reaktion vom Restaurant',
    CancelOrderReason_restaurant_declined_sold_out: 'Gericht nicht verfügbar',
    CancelOrderReason_too_much: 'Zu viel bestellt',
    CartButtonLabel: 'Warenkorb: %{value}',
    CartChangeMealsPriceChanged: {
        one: 'Der Preis dieses Gerichtes wurde angepasst:\n\n%{names}.',
        other: 'Die Preise dieser Gerichte wurden angepasst:\n\n%{names}.'
    },
    CartChangeMealsGone: {
        one: 'Folgendes Gericht ist nicht mehr erhältlich:\n\n%{names}.',
        other: 'Folgende Gerichte sind nicht mehr erhältlich:\n\n%{names}.'
    },
    CartChangeMessage: 'Die Speisekarte von %{restaurant} hat sich geändert. ',
    CartChangeTitle: 'Bitte überprüfe deinen Warenkorb',
    CashPayment: 'Bargeldzahlung',
    CartForRestaurant: 'Warenkorb bei %{restaurant}',
    ChangeEmail: 'E-Mail ändern',
    ChangeSuccess: 'Änderung gespeichert',
    ChooseBetween: 'Wähle aus zwischen',
    ChooseDateAndTime: 'Wähle Datum und Uhrzeit aus',
    ChooseOnlyDate: 'Wähle ein Datum aus',
    ChooseOnlyTime: 'Wähle eine Uhrzeit aus',
    Close: 'Schließen',
    Closed: 'Geschlossen!',
    ClosedForOrdering: 'Aufgrund der Öffnungszeiten kannst du gerade nicht bestellen. Versuche es ab %{date} noch einmal.',
    ClosedToday: 'Heute geschlossen',
    ClosesSoon: 'Schließt bald!',
    CodeCopiedToClipboard: 'Der Code wurde in die Zwischenablage kopiert.',
    Comments: 'Anmerkungen',
    CommonFilters: 'Häufige Filter',
    Confirm: 'Bestätigen',
    ConfirmCancelInvitation: 'Möchtest du die Verabredung wirklich stornieren? Sie wird dabei für alle Teilnehmer entfernt und alle damit verknüpften Bestellungen werden ebenfall storniert.',
    ConfirmDeclineInvitation: 'Möchtest du die Verabredung wirklich ablehnen? Du kannst deine Absage nicht zurückziehen.',
    ConfirmDeleteAccount: 'Bitte ankreuzen, wenn du wirklich deinen Account löschen willst',
    Confirmed: 'bestätigt',
    ConfirmInvitationDenial: 'Möchtest du die Verabredung wirklich ablehnen?',
    ConfirmPassword: 'Mit Passwort bestätigen',
    ConnectUser: 'LunchNow teilen',
    ContactBlock: 'Blockieren',
    ContactGroupEditMembers: 'Gruppe bearbeiten',
    ContactGroupRemove: 'Gruppe löschen',
    ContactRemove: 'Aus Kontakten entfernen',
    Contacts: 'Kontakte',
    Continue: 'Weiter',
    ContinueToRestaurants: 'Weiter zur Restaurantauswahl',
    CreateAccount: 'Deinen Account erstellen',
    CreateAccountCTA: 'Jetzt deinen Account erstellen oder einloggen',
    CreateAccountTitle: 'Erstelle einen Account',
    CreateInvitation: 'Verabredung erstellen',
    DateAndWeekDayFormat: 'dd, D. MMM',
    DateFormat: 'D. MMMM YYYY',
    DateFormat_Later: 'Do MMM HH:mm',
    DateFormat_NextWeek:'[nächsten] dddd HH:mm',
    DateFormat_ThisWeek: 'dddd HH:mm',
    DateFormat_TodayAt: '[um] HH:mm',
    DateFormat_Tomorrow: '[morgen] HH:mm',
    DayFormat: 'D. MMM',
    decimalPoint: ',',
    Decline: 'Absagen',
    DeclineInvitation: 'Verabredung absagen',
    DeleteAccount: 'Account löschen',
    DeleteAccountDescription: 'Wenn du deinen Account löschst, gehen alle deine Einstellungen verloren.',
    DeleteAccountFail: 'Der Account konnte nicht gelöscht werden',
    DeleteAccountSuccess: 'Der Account wurde gelöscht',
    DeleteAccountSuccessDesc: 'Dein Account wurde zur Löschung vorgemerkt. Du kannst dich innerhalb der nächsten 30 Tage erneut einloggen, um deinen Account wiederherzustellen. Nach 30 Tagen werden all deine Daten gelöscht.',
    DeleteAccountTitle: 'Wirklich deinen Account löschen?',
    DeletePermanently: 'Endgültig löschen',
    DeletePicture: 'Profilbild löschen',
    DeletedUser: 'Gelöschter User',
    DetailFeatures: 'Weitere Merkmale',
    DetailPayment: 'Zahlungsmöglichkeiten',
    Details: 'Details',
    DetailServices: 'Leistungen',
    Dinersclub: 'Dinersclub',
    DiscardInvitation: 'Dein Einladungsentwurf wird gelöscht!',
    Discount: 'Rabatt',
    DiscountAvailableFrom: 'ab %{start} Uhr',
    DiscountAvailableFromTo: 'von %{start} bis %{end} Uhr',
    DiscountAvailableTimeOnlyNamed: '%{name} ist nur %{time} verfügbar. ',
    DiscountAvailableUntil: 'bis %{end} Uhr',
    DiscountChangeGone: {
        one: 'Der folgende Gutschein ist nicht mehr verfügbar:\n\n%{names}.',
        other: 'Die folgenden Gutscheine sind nicht mehr verfügbar:\n\n%{names}.'
    },
    DiscountChangeValue: {
        one: 'Der Wert des folgenden Gutscheins wurde angepasst:\n\n%{names}.',
        other: 'Die Werte der folgenden Gutscheine wurden angepasst:\n\n%{names}.'
    },
    DiscountChangeMessage: 'Die Rabatte für %{restaurant} haben sich geändert. ',
    DiscountDescriptionCompensation: 'Es tut uns Leid, dass du nicht zufrieden warst! Als Entschädigung schenken wir dir diesen %{value}-Gutschein, einlösbar auf eine Bestellung deiner Wahl.',
    DiscountDescriptionDefault: 'Genieß dein Lunch besonders günstig mit diesem %{value}-Gutschein! Einlösbar für eine Bestellung deiner Wahl.',
    DiscountMealChangeAdded: {
        one: 'Es gibt einen neuen Rabatt für dieses Gericht:\n\n%{names}.',
        other: 'Es gibt neue Rabatte für diese Gerichte:\n\n%{names}.'
    },
    DiscountMealChangeGone: {
        one: 'Der Rabatt für dieses Gericht ist nicht mehr verfügbar:\n\n%{names}.',
        other: 'Die Rabatte für diese Gerichte sind nicht mehr verfügbar:\n\n%{names}.'
    },
    DiscountMealChangeValue: {
        one: 'Der Rabatt für dieses Gericht wurde angepasst:\n\n%{names}.',
        other: 'Die Rabatte für diese Gerichte wurden angepasst:\n\n%{names}.'
    },
    DiscountQuantityHint: 'Noch %{count} verfügbar!',
    Discounts: 'Rabatte',
    DiscountType_compensation: 'Gutschrift',
    DiscountType_giftcard: 'Geschenkgutschein',
    DiscountType_goodie: 'Goodie',
    DiscountType_loyalty: 'Stempelkarte',
    DiscountType_promotion: 'Promotion',
    DiscountType_smartprice: 'Smart Price',
    DisplayNameExplanation: 'Nutze deinen vollständigen Namen, damit dich LN-Nutzer besser finden können.',
    DoOrder: 'Bestellen',
    Drinks: 'Getränke',
    EcCard: 'EC-Karte',
    EditDisplayName: 'Nutzernamen bearbeiten',
    EditProfilePicture: 'Profilbild bearbeiten',
    EditTastes: 'Vorlieben bearbeiten',
    EmptyCartLink: 'Füge welche hinzu.',
    EmptyCartMessage: 'Dein Warenkorb enthält keine Gerichte.',
    EmptyContactsMessage: 'Füge LunchNow-Nutzer zu deiner Kontaktliste hinzu, um Freunde, Kollegen und Geschäftspartner einzuladen.',
    EmptyContactsTitle: 'Pflege jetzt deine Kontaktliste',
    EmptyGroupsMessage: 'Erstelle Gruppen aus deinen Kontakten, um mit ihnen zu chatten, dich zu verabreden oder gemeinsam zu bestellen.',
    EmptyGroupsTitle: 'Organisiert euch',
    EmptyInvitationsMessage: 'Deine Einladungen erscheinen hier. Verabrede dich mit Freunden, Kollegen und Geschäftspartnern.',
    EmptyInvitationsTitle: 'Noch keine Einladungen',
    EmptyOrderListFuture: 'Keine laufenden Bestellungen',
    EmptyOrderListPast: 'Noch keine vergangenen Bestellungen',
    ErrorAuthDisabled: 'Wegen Wartungsarbeiten ist die Anmeldung gerade außer Betrieb. Bitte versuche es später erneut',
    ErrorBlockedUserNotSet: 'LunchNow-Nutzer konnte nicht blockiert werden',
    ErrorBlockedUsersNotAdded: {
        one: 'LunchNow-Nutzer konnte nicht blockiert werden',
        other: 'LunchNow-Nutzer konnten nicht blockiert werden'
    },
    ErrorChangeAttendance: 'Deine Teilnahme konnte nicht geändert werden. Bitte versuche es erneut',
    ErrorChangeVisibility: 'Deine Sichtbarkeit konnte nicht geändert werden',
    ErrorContactsNotAdded: {
        one: 'Kontakt konnte nicht hinzugefügt werden',
        other: 'Kontakte konnten nicht hinzugefügt werden'
    },
    ErrorContactsNotRemoved: {
        one: 'Kontakt konnte nicht gelöscht werden',
        other: 'Kontakte konnten nicht gelöscht werden'
    },
    ErrorDateLaterTwoWeeks: 'Der Zeitpunkt darf nicht weiter als zwei Wochen in der Zukunft liegen',
    ErrorDatePast: 'Der Zeitpunkt kann nicht in der Vergangenheit liegen',
    ErrorEmailEmpty: 'Bitte gib deine E-Mail-Adresse ein',
    ErrorEmailIdentical: 'Deine neue E-Mail-Adresse muss sich von deiner aktuellen unterscheiden',
    ErrorEmailInvalid: 'Bitte überprüfe deine E-Mail-Adresse',
    ErrorEmailTaken: 'Diese E-Mail-Adresse wird bereits verwendet',
    ErrorFavoriteNotSet: 'Favorit konnte nicht gesetzt werden',
    ErrorGdpr: 'Bitte akzeptiere unsere Datenschutzerklärung',
    ErrorGroupNameEmpty: 'Bitte gib einen Namen für die Gruppe ein',
    ErrorGroupNameTaken: 'Du hast bereits eine Gruppe mit diesem Namen',
    ErrorGroupNotRemoved: 'Kontakt Gruppe konnte nicht gelöscht werden',
    ErrorGroupNotSent: 'Kontakt Gruppe konnte nicht erstellt werden',
    ErrorGroupNotUpdated: 'Kontakt Gruppe konnte nicht aktualisiert werden',
    ErrorHttp_404: 'Die Ressource wurde nicht gefunden.',
    ErrorHttp_400: 'Die Anfrage kann nicht verarbeiten werden',
    ErrorHttp_401: 'Benutzer nicht authentifiziert.',
    ErrorHttp_403: 'Zugriff verweigert.',
    ErrorInternetConnection: 'Bitte checke deine Internetverbindung',
    ErrorInvitationNotSent: 'Einladung konnte nicht gesendet werden',
    ErrorNameEmpty: 'Bitte gib deinen Namen ein',
    ErrorNameIdentical: 'Dein neuer Name muss sich von deinem aktuellen unterscheiden',
    ErrorNoAccount: 'Für diese E-Mail-Adresse existiert kein Account',
    ErrorNoInvitation: 'Die Einladung scheint nicht mehr zu existieren…',
    ErrorNoOpeninghours: 'Keine Öffnungszeiten verfügbar. Wir können nicht garantieren, dass das Restaurant zum Zeitpunkt der Einladung geöffnet ist',
    ErrorOrderNotToday: 'Du kannst nur für heute bestellen',
    ErrorOrderTooSoon: 'Bitte bestelle mindestens %{minutes} Minuten im Vorraus',
    ErrorOrderWrongDay: 'Du kannst den Tag, für den du bestellst, hier leider nicht ändern',
    ErrorPasswordEmpty: 'Bitte gib ein Passwort ein',
    ErrorPasswordIncorrect: 'Inkorrektes Passwort',
    ErrorPasswordInvalid: 'Dein Passwort sollte mindestens %{minLength} Zeichen lang sein',
    ErrorPasswordWeak: 'Bitte gib ein stärkeres Passwort ein',
    ErrorPayment_AlertCancellationTitle: 'Bei der Stornierung ist ein Problem aufgetreten.',
    ErrorPayment_AlertTitle: 'Bei der Zahlung ist ein Problem aufgetreten.',
    ErrorPayment_BadRequest: 'Die Anfrage konnte nicht verarbeitet werden.',
    ErrorPayment_DiscountLimitExceeded: 'Rabatte und Gutscheine sind leider nicht kombinierbar.',
    ErrorPayment_Forbidden: 'Zugriff verweigert.',
    ErrorPayment_MissingRequiredFields: 'Pflichtfelder fehlen.',
    ErrorPayment_NoCard: 'Die Karte fehlt oder gehört nicht zum Benutzer.',
    ErrorPayment_NoCardId: 'Karten-ID fehlt.',
    ErrorPayment_NoCardType: 'Kartentyp fehlt.',
    ErrorPayment_NoItems: 'Es gibt keine bestellbaren Items.',
    ErrorPayment_NoLegalUser: 'Partner nicht gefunden.',
    ErrorPayment_NoMeetUpDate: 'Die Bestellzeit fehlt.',
    ErrorPayment_NoPaymentUser: 'Payment-User nicht gefunden.',
    ErrorPayment_NoPreauthorizationId: 'Preauthorization ID fehlt.',
    ErrorPayment_NoTransactionId: 'Eine Transaktions-ID muss angegeben werden.',
    ErrorPayment_OrderMealsChanged: 'Die Speisekarte des Restaurants hat sich geändert. Bitte überprüfe deine Bestellung.',
    ErrorPayment_OrderNotCancelable: 'Die Bestellung kann nicht storniert werden.',
    ErrorPayment_OrderNotFound: 'Die Bestellung konnte nicht gefunden werden.',
    ErrorPayment_RestaurantUndefined: 'Das Restaurant kann nicht gefunden werden.',
    ErrorPayment_TotalPrice: 'Gesamtsumme der Bestellung ist 0.',
    ErrorPayment_Unauthorized: 'Benutzer nicht authentifiziert.',
    ErrorPayment_UserNotFound: 'Benutzer nicht gefunden.',
    ErrorPayment_WrongTransactionAuthor: 'Du bist nicht der Ersteller der Bestellung.',
    ErrorPayPal_ACTION_DOES_NOT_MATCH_INTENT: 'Anmeldung mit einem PayPal-Account benötigt.',
    ErrorPayPal_AGREEMENT_ALREADY_CANCELLED: 'Die angefragte Vereinbarung wurde bereits gekündigt.',
    ErrorPayPal_AMOUNT_CANNOT_BE_SPECIFIED: 'Keine Bestellung gefunden.',
    ErrorPayPal_AMOUNT_CHANGE_NOT_ALLOWED: 'Der Betrag der Zahlung weicht von dem Betrag ab, der vom Zahlenden autorisiert wurde.',
    ErrorPayPal_AMOUNT_LESS_THAN_MIN_VALUE: 'Der Betrag der Zahlung liegt unter dem zulässigen Minimalbetrag für deine Zahlungsquelle.',
    ErrorPayPal_AMOUNT_MISMATCH: 'Der Betrag der Zahlung weicht vom berechneten Betrag ab.',
    ErrorPayPal_AMOUNT_NOT_PATCHABLE: 'Der Betrag der Zahlung wurde bestätigt und kann nicht mehr verändert werden.',
    ErrorPayPal_AUTH_CAPTURE_CURRENCY_MISMATCH: 'Falsche Währung. Bitte überprüfe die Währung deiner Zahlung und versuche es erneut.',
    ErrorPayPal_AUTH_CAPTURE_NOT_ENABLED: 'Die PayPal API ist momentan leider deaktiviert.',
    ErrorPayPal_AUTHENTICATION_FAILURE: 'Die Anmeldung bei PayPal ist fehlgeschlagen.',
    ErrorPayPal_AUTHORIZATION_CURRENCY_MISMATCH: 'Falsche Währung. Bitte überprüfe die Währung deiner Zahlung und versuche es erneut.',
    ErrorPayPal_AUTHORIZATION_AMOUNT_EXCEEDED: 'Du hast die maximale Anzahl an Anmeldungen bereits erreicht. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_AUTHORIZATION_ALREADY_CAPTURED: 'Die Anmeldung bei PayPal ist fehlgeschlagen.',
    ErrorPayPal_AUTHORIZATION_DENIED: 'Die Anmeldung bei PayPal wurde abgelehnt.',
    ErrorPayPal_AUTHORIZATION_EXPIRED: 'Deine Anmeldung bei PayPal ist abgelaufen.',
    ErrorPayPal_AUTHORIZATION_VOIDED: 'Deine Anmeldung bei PayPal ist abgelaufen.',
    ErrorPayPal_BILLING_AGREEMENT_ID_MISMATCH: 'Der Zahlungsvertrag weicht vom ursprünglichen Vertrag ab.',
    ErrorPayPal_BILLING_AGREEMENT_NOT_FOUND: 'Kein Abrechnungsvertrag gefunden.',
    ErrorPayPal_CANNOT_BE_ZERO_OR_NEGATIVE: 'Der Betrag deiner Zahlung muss größer als null sein.',
    ErrorPayPal_CANNOT_BE_VOIDED: 'Die Zahlung kann nicht annulliert werden. Bitte annulliere die ursprüngliche Zahlung.',
    ErrorPayPal_REFUND_NOT_PERMITTED_DUE_TO_CHARGEBACK: 'Die Rückzahlung wurde abgelehnt aufgrund einer laufenden Rückbuchung. Bitte kontaktiere den Zahlungsempfänger.',
    ErrorPayPal_CANNOT_BE_NEGATIVE: 'Der Betrag deiner Zahlung darf nicht kleiner als null sein.',
    ErrorPayPal_CAPTURE_DISPUTED_PARTIAL_REFUND_NOT_ALLOWED: 'Die Rückzahlung ist momentan nicht möglich wegen eines offenen Konfliktes. Bitte besuche das PayPal Sicherheitscenter für weitere Details.',
    ErrorPayPal_CAPTURE_FULLY_REFUNDED: 'Die Zahlung wurde bereits vollständig zurückgebucht.',
    ErrorPayPal_CARD_BRAND_NOT_SUPPORTED: 'Dieser Kreditkarten-Anbieter wird leider nicht unterstützt.',
    ErrorPayPal_CARD_TYPE_NOT_SUPPORTED: 'Diese Kreditkarte wird leider nicht unterstützt. Bitte benutze eine andere.',
    ErrorPayPal_CITY_REQUIRED: 'Bitte gib einen Ort ein und versuche es erneut.',
    ErrorPayPal_COMPLIANCE_VIOLATION: 'Es liegt möglicherweise ein Verstoß gegen unsere Richtlinien vor. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_CONSENT_NEEDED: 'Die Anmeldung bei PayPal ist fehlgeschlagen aufgrund von unzureichenden Berechtigungen.',
    ErrorPayPal_COUNTRY_NOT_SUPPORTED_BY_PAYMENT_SOURCE: 'Der angegebene Ländercode wird von deiner Zahlungsquelle leider nicht unterstützt.',
    ErrorPayPal_CURRENCY_NOT_SUPPORTED_BY_PAYMENT_SOURCE: 'Die angegebene Währung wird von deiner Zahlungsquelle leider nicht unterstützt.',
    ErrorPayPal_CURRENCY_NOT_SUPPORTED_FOR_CARD_TYPE: 'Die angegebene Währung wird von deiner Kreditkarte leider nicht unterstützt.',
    ErrorPayPal_CURRENCY_NOT_SUPPORTED_FOR_COUNTRY: 'Die angegebene Währung wird in diesem Land leider nicht unterstützt.',
    ErrorPayPal_DECIMAL_PRECISION: 'Der Betrag deiner Zahlung darf nicht mehr als zwei Nachkommastellen haben.',
    ErrorPayPal_DECIMALS_NOT_SUPPORTED: 'Die Währung deiner Zahlung erlaubt keine Nachkommastellen.',
    ErrorPayPal_DOMESTIC_TRANSACTION_REQUIRED: 'Für diese Transaktion müssen Zahlender und Zahlungsempfänger im selben Land ansässig sein.',
    ErrorPayPal_DUPLICATE_INVOICE_ID: 'Die Rechnungsnummer deiner Zahlung existiert bereits. Sie wurde abgebrochen, um eine mögliche doppelte Buchung zu verhindern.',
    ErrorPayPal_DUPLICATE_REFERENCE_ID: 'Die Referenz-ID wurde bereits verwendet.',
    ErrorPayPal_DUPLICATE_REQUEST_ID: 'Die PayPal Request-ID wurde bereits verwendet.',
    ErrorPayPal_FIELD_NOT_PATCHABLE: 'Der Feldwert kann nicht geändert werden.',
    ErrorPayPal_INCOMPATIBLE_PARAMETER_VALUE: 'Ungültiger Parameter-Wert.',
    ErrorPayPal_INSTRUMENT_DECLINED: 'Das gewählte Finanzierungsinstrument kann für diese Zahlung nicht verwendet werden.',
    ErrorPayPal_INTERNAL_SERVER_ERROR: 'Ein interner Serverfehler ist aufgetreten. Bitte versuche es später erneut.',
    ErrorPayPal_INTERNAL_SERVICE_ERROR: 'Ein interner Serverfehler ist aufgetreten. Bitte versuche es später erneut.',
    ErrorPayPal_INVALID_ACCOUNT_STATUS: 'Der PayPal-Account konnte nicht validiert werden.',
    ErrorPayPal_INVALID_ARRAY_MAX_ITEMS: 'Ein Listen-Parameter hat zu viele Elemente.',
    ErrorPayPal_INVALID_ARRAY_MIN_ITEMS: 'Ein Listen-Parameter hat zu wenige Elemente.',
    ErrorPayPal_INVALID_COUNTRY_CODE: 'Ungültiger Ländercode.',
    ErrorPayPal_INVALID_CURRENCY_CODE: 'Ungültiger Währungs-Code.',
    ErrorPayPal_INVALID_JSON_POINTER_FORMAT: 'Ungültige JSON-Daten.',
    ErrorPayPal_INVALID_PARAMETER: 'Ungültiger Parameter.',
    ErrorPayPal_INVALID_PARAMETER_SYNTAX: 'Ungültige Eingabe.',
    ErrorPayPal_INVALID_PARAMETER_VALUE: 'Ungültiger Eingabewert.',
    ErrorPayPal_INVALID_PATCH_OPERATION: 'Existierende Felder können nicht neu hinzugefügt, fehlende Felder nicht ersetzt oder gelöscht werden.',
    ErrorPayPal_INVALID_PAYEE_ACCOUNT: 'Ungültiger Zahlungsempfänger.',
    ErrorPayPal_INVALID_PAYER_ID: 'Ungültige Zahler-ID.',
    ErrorPayPal_INVALID_PLATFORM_FEES_AMOUNT: 'Der Betrag der Gebühren darf den Betrag der Zahlung nicht übersteigen.',
    ErrorPayPal_INVALID_PREVIOUS_TRANSACTION_REFERENCE: 'Ungültige Referenz zur vorangegangenen Transaktion.',
    ErrorPayPal_INVALID_RESOURCE_ID: 'Ungültige Ressourcen-ID.',
    ErrorPayPal_INVALID_SECURITY_CODE_LENGTH: 'Die Länge des Sicherheitscodes entspricht nicht den Vorgaben für deine Kreditkarte.',
    ErrorPayPal_INVALID_STRING_LENGTH: 'Die Länge des eingegebenen Textes ist entweder zu lang oder zu kurz.',
    ErrorPayPal_INVALID_STRING_MAX_LENGTH: 'Die Länge des eingegebenen Textes ist zu lang.',
    ErrorPayPal_ITEM_TOTAL_MISMATCH: 'Der Betrag der Zahlung weicht vom berechneten Betrag ab. Bitte überprüfe den Betrag und versuche es erneut.',
    ErrorPayPal_ITEM_TOTAL_REQUIRED: 'Gesamtbetrag nicht gefunden.',
    ErrorPayPal_INVALID_PICKUP_ADDRESS: 'Ungültige Lieferadresse. Für die Lieferoption ABHOLUNG sollte die Lieferadresse mit „S2S“ (Ship To Store) beginnen.',
    ErrorPayPal_MAX_AUTHORIZATION_COUNT_EXCEEDED: 'Du hast die maximale Anzahl an Anmeldungen bereits erreicht. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_MAX_CAPTURE_AMOUNT_EXCEEDED: 'Der Betrag deiner Zahlung übersteigt das zulässige Maximum. Bitte kontaktiere den Kundenservice oder deinen Account-Manager.',
    ErrorPayPal_MAX_CAPTURE_COUNT_EXCEEDED: 'Du hast die maximale Anzahl an Zahlungen für deinen Account bereits erreicht. Bitte kontaktiere den Kundenservice oder deinen Account-Manager.',
    ErrorPayPal_MAX_NUMBER_OF_REFUNDS_EXCEEDED: 'Du hast die maximale Anzahl an Rückbuchungen für diese Zahlung bereits erreicht. Bitte kontaktiere den Kundenservice oder deinen Account-Manager.',
    ErrorPayPal_MAX_NUMBER_OF_PAYMENT_ATTEMPTS_EXCEEDED: 'Du hast die maximale Anzahl an Zahlungsversuchen bereits erreicht. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_MAX_VALUE_EXCEEDED: 'Der Betrag darf nicht größer als 9.999.999,99 sein.',
    ErrorPayPal_MERCHANT_INITIATED_WITH_SECURITY_CODE: 'Händler-Konfiguration nicht unterstützt.',
    ErrorPayPal_MERCHANT_INITIATED_WITH_AUTHENTICATION_RESULTS: 'Händler-Konfiguration nicht unterstützt.',
    ErrorPayPal_MERCHANT_INITIATED_WITH_MULTIPLE_PURCHASE_UNITS: 'Händler-Konfiguration nicht unterstützt.',
    ErrorPayPal_MISSING_REQUIRED_PARAMETER: 'Es fehlt der Wert für ein benötigtes Eingabefeld.',
    ErrorPayPal_MISSING_SHIPPING_ADDRESS: 'Deine Lieferadresse wird benötigt.',
    ErrorPayPal_MULTI_CURRENCY_ORDER: 'Falsche Währung. Bitte überprüfe die Währung deiner Zahlung und versuche es erneut.',
    ErrorPayPal_MULTIPLE_SHIPPING_ADDRESS_NOT_SUPPORTED: 'Falsche Lieferadresse. Bitte überprüfe deine Lieferadresse und versuche es erneut.',
    ErrorPayPal_MULTIPLE_SHIPPING_OPTION_SELECTED: 'Es kann nur eine einzelne Lieferoption gewählt werden.',
    ErrorPayPal_NOT_AUTHORIZED: 'Du hast keine ausreichenden Zugriffsrechte.',
    ErrorPayPal_NOT_ENABLED_FOR_CARD_PROCESSING: 'Kreditkartenzahlungen sind momentan leider deaktiviert. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_NOT_PATCHABLE: 'Der Feldwert kann nicht geändert werden.',
    ErrorPayPal_NOT_SUPPORTED: 'Dieses Feld wird momentan nicht unterstützt.',
    ErrorPayPal_ORDER_ALREADY_AUTHORIZED: 'Die Bestellung wurde bereits autorisiert.',
    ErrorPayPal_ORDER_ALREADY_CAPTURED: 'Die Bestellung wurde bereits abgeschickt.',
    ErrorPayPal_ORDER_ALREADY_COMPLETED: 'Die Bestellung wurde bereits abgeschlossen.',
    ErrorPayPal_ORDER_CANNOT_BE_SAVED: 'Die Bestellung konnte nicht gespeichert werden.',
    ErrorPayPal_ORDER_COMPLETED_OR_VOIDED: 'Die Bestellung wurde bereits abgeschlossen oder annulliert.',
    ErrorPayPal_ORDER_EXPIRED: 'Die Bestellung ist abgelaufen.',
    ErrorPayPal_ORDER_NOT_APPROVED: 'Die Bestellung wurde noch nicht vom Zahlenden genehmigt.',
    ErrorPayPal_ORDER_NOT_SAVED: 'Die Bestellung wurde nicht gespeichert.',
    ErrorPayPal_ORDER_PREVIOUSLY_VOIDED: 'Die Bestellung wurde bereits annulliert.',
    ErrorPayPal_PARAMETER_VALUE_NOT_SUPPORTED: 'Der angegebene Feldwert wird momentan nicht unterstützt.',
    ErrorPayPal_PARTIAL_REFUND_NOT_ALLOWED: 'Teil-Rückzahlungen werden nicht unterstützt.',
    ErrorPayPal_PATCH_PATH_REQUIRED: 'Das Feld erfordert eine Pfadangabe.',
    ErrorPayPal_PATCH_VALUE_REQUIRED: 'Das Feld erfordert einen Wert.',
    ErrorPayPal_PAYEE_ACCOUNT_INVALID: 'Der Account des Zahlenden ist ungültig.',
    ErrorPayPal_PAYEE_ACCOUNT_LOCKED_OR_CLOSED: 'Der Account des Zahlungsempfängers ist gesperrt oder geschlossen. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_PAYEE_ACCOUNT_NOT_SUPPORTED: 'Ungültiger Zahlungsempfänger.',
    ErrorPayPal_PAYEE_ACCOUNT_NOT_VERIFIED: 'Der Account des Zahlungsempfängers wurde noch nicht verifiziert.',
    ErrorPayPal_PAYEE_ACCOUNT_RESTRICTED: 'Der Account des Zahlungsempfängers wurde eingeschränkt. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_PAYEE_BLOCKED_TRANSACTION: 'Die Zahlung wurde durch die Einstellungen zum Betrugsschutz blockiert. Bitte überprüfe die Einstellungen und versuche es erneut.',
    ErrorPayPal_PAYEE_NOT_CONSENTED: 'Die Transaktion kann erst nach Zustimmung des Zahlungsempfängers fortgesetzt werden.',
    ErrorPayPal_PAYEE_NOT_ENABLED_FOR_CARD_PROCESSING: 'Kreditkartenzahlungen sind momentan leider deaktiviert. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_PAYER_ACCOUNT_LOCKED_OR_CLOSED: 'Der Account des Zahlenden ist gesperrt oder geschlossen. Bitte kontaktiere den Zahlenden für alternative Zahlungsoptionen.',
    ErrorPayPal_PAYER_ACCOUNT_RESTRICTED: 'Der Account des Zahlenden wurde eingeschränkt. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_PAYER_CANNOT_PAY: 'Der Account des Zahlenden kann nicht zahlen. Bitte kontaktiere den Zahlenden für alternative Zahlungsoptionen.',
    ErrorPayPal_PAYER_CONSENT_REQUIRED: 'Die Transaktion kann erst nach Zustimmung des Zahlenden fortgesetzt werden.',
    ErrorPayPal_PAYER_COUNTRY_NOT_SUPPORTED: 'Das Land des Zahlenden wird leider nicht unterstützt.',
    ErrorPayPal_PAYMENT_ALREADY_APPROVED: 'Die Zahlung wurde bereits genehmigt.',
    ErrorPayPal_PAYMENT_INSTRUCTION_REQUIRED: 'Zahlungsanweisungen benötigt.',
    ErrorPayPal_PAYMENT_SOURCE_MISMATCH: 'Die Zahlungsquelle weicht von der ursprünglichen Zahlungsquelle ab.',
    ErrorPayPal_PAYPAL_REQUEST_ID_REQUIRED: 'Die PayPal Request-ID wird benötigt.',
    ErrorPayPal_PENDING_CAPTURE: 'Die Zahlung läuft noch und kann noch nicht zurückgebucht werden.',
    ErrorPayPal_PERMISSION_DENIED: 'Du hast keine ausreichenden Zugriffsrechte.',
    ErrorPayPal_PERMISSION_NOT_GRANTED: 'Du hast keine ausreichenden Zugriffsrechte.',
    ErrorPayPal_POSTAL_CODE_REQUIRED: 'Deine Postleitzahl wird benötigt.',
    ErrorPayPal_PREFERRED_SHIPPING_OPTION_AMOUNT_MISMATCH: 'Der Betrag der Zahlung weicht von dem Betrag ab, der für die Lieferoption berechnet wurde.',
    ErrorPayPal_PREVIOUS_TRANSACTION_REFERENCE_HAS_CHARGEBACK: 'Die vorangegangene Transaktion hat eine laufende Rückbuchung und kann nicht für diese Bestellung verwendet werden.',
    ErrorPayPal_PREVIOUS_TRANSACTION_REFERENCE_VOIDED: 'Die vorangegangene Transaktion wurde annulliert und kann nicht für diese Bestellung verwendet werden.',
    ErrorPayPal_PREVIOUSLY_CAPTURED: 'Die Zahlung wurde bereits gebucht und kann nicht mehr annulliert werden.',
    ErrorPayPal_PREVIOUSLY_VOIDED: 'Die Zahlung wurde bereits annulliert und kann nicht erneut annulliert werden.',
    ErrorPayPal_REDIRECT_PAYER_FOR_ALTERNATE_FUNDING: 'Transaktion fehlgeschlagen. Bitte wähle eine andere Zahlungsquelle und versuche es erneut.',
    ErrorPayPal_REFERENCE_ID_NOT_FOUND: 'Referenz-ID nicht gefunden.',
    ErrorPayPal_REFERENCE_ID_REQUIRED: 'Referenz-ID benötigt.',
    ErrorPayPal_REFUND_AMOUNT_EXCEEDED: 'Der Betrag der Rückzahlung darf den noch nicht zurückgebuchten Betrag der Zahlung nicht übersteigen. Bitte überprüfe den Rückzahlungsbetrag und versuche es erneut.',
    ErrorPayPal_REFUND_CAPTURE_CURRENCY_MISMATCH: 'Die Währung der Rückzahlung muss mit der Währung der Zahlung übereinstimmen. Bitte überprüfe die Rückzahlungswährung und versuche es erneut.',
    ErrorPayPal_REFUND_FAILED_INSUFFICIENT_FUNDS: 'Die Zahlung konnte nicht zurückgebucht werden aufgrund von unzureichender Deckung.',
    ErrorPayPal_REFUND_NOT_ALLOWED: 'Die Zahlung wurde bereits teilweise zurückgebucht und kann nicht erneut vollständig zurückgebucht werden.',
    ErrorPayPal_REFUND_TIME_LIMIT_EXCEEDED: 'Die Frist für Rückzahlungen wurde für diese Zahlung überschritten.',
    ErrorPayPal_SHIPPING_ADDRESS_INVALID: 'Ungültige Lieferadresse.',
    ErrorPayPal_SHIPPING_OPTION_NOT_SELECTED: 'Es wurde keine Lieferoption gewählt.',
    ErrorPayPal_SHIPPING_OPTIONS_NOT_SUPPORTED: 'Die Lieferoption wird leider nicht unterstützt.',
    ErrorPayPal_TAX_TOTAL_MISMATCH: 'Der Steuerbetrag der Zahlung weicht vom berechneten Betrag ab.',
    ErrorPayPal_TAX_TOTAL_REQUIRED: 'Der Steuerbetrag muss angegeben werden.',
    ErrorPayPal_TRANSACTION_AMOUNT_EXCEEDS_MONTHLY_MAX_LIMIT: 'Der Betrag der Zahlung übersteigt dein verfügbares Monatslimit. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_TRANSACTION_BLOCKED_BY_PAYEE: 'Die Zahlung wurde durch die Einstellungen des Zahlungsempfängers zum Betrugsschutz blockiert.',
    ErrorPayPal_TRANSACTION_LIMIT_EXCEEDED: 'Der Betrag der Zahlung übersteigt dein Transaktionslimit. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_TRANSACTION_RECEIVING_LIMIT_EXCEEDED: 'Der Betrag der Zahlung übersteigt das Transaktionslimit des Zahlungsempfängers. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_TRANSACTION_REFUSED: 'PayPal hat die Transaktion abgelehnt. Bitte kontaktiere den Kundenservice.',
    ErrorPayPal_UNSUPPORTED_INTENT: 'Die Aktion wird leider nicht unterstützt.',
    ErrorPayPal_UNSUPPORTED_INTENT_FOR_PAYMENT_SOURCE: 'Die Aktion wird von deiner Zahlungsquelle leider nicht unterstützt.',
    ErrorPayPal_UNSUPPORTED_PATCH_PARAMETER_VALUE: 'Der angegebene Feldwert wird momentan nicht unterstützt.',
    ErrorPayPal_UNSUPPORTED_PAYMENT_INSTRUCTION: 'Die Zahlungsanweisungen werden nicht unterstützt.',
    ErrorProfilePictureDeleted: 'Dein Profilbild konnte nicht gelöscht werden',
    ErrorProfilePictureUpload: 'Das Bild konnte nicht hochgeladen werden',
    ErrorRestaurantAlreadyClosed: 'Das Restaurant ist für heute bereits geschlossen. Bitte wähle einen anderen Tag aus.',
    ErrorRestaurantClosedDay: 'Das Restaurant ist nur %{days} geöffnet',
    ErrorRestaurantClosedTime: 'Am gewählten Tag ist das Restaurant nur %{times} geöffnet',
    ErrorRestaurantRestingDay: 'Am %{startDate} ist das Restaurant geschlossen',
    ErrorRestaurantRestingDays: 'Das Restaurant ist geschlossen vom %{startDate} bis %{endDate}',
    ErrorTooManyAttempts: 'Zu viele gescheiterte Anmeldeversuche. Bitte versuche es später erneut',
    ErrorUnblockUser: 'Blockierung des LunchNow-Nutzers konnte nicht aufgehoben werden',
    ErrorUnknown: 'Unbekannter Fehler',
    ErrorUserMismatch: 'Dieser Account ist ein anderer als der mit dem du eingeloggt bist.',
    ErrorVerificationCodeIncorrect: 'Der Bestätigungs-Code ist leider nicht korrekt',
    ErrorVerificationCodeInvalid: 'Bitte gib einen Bestätigungs-Code aus 6 Ziffern ein',
    ExpiredTransactions: 'Vergangene (%{count})',
    Fail: 'Fehler',
    FailLogin: 'Anmeldung nicht möglich',
    FailResetPassword: 'Password nicht zurückgesetzt',
    FarAway: 'Weit weg',
    Favorites: 'Favoriten',
    Filter: 'Filter',
    FilterSearch: 'Suche nach Standort, Restaurant oder Küche',
    ForgotPassword: 'Passwort vergessen?',
    ForgotPasswordSuccess: 'Nachricht erfolgreich versandt',
    ForgotPasswordFail: 'Wir konnten dein Passwort nicht zurücksetzen, bitte überprüfe deine Daten.',
    GDPR: 'Datenschutz',
    GDPRLabelAfterLink: '',
    GDPRLabelBeforeLink: 'Ich akzeptiere die ',
    GDPRLink: 'Datenschutzbestimmungen',
    GoBack: 'Zurück?',
    GooglePay: 'Google Pay',
    GoToWebsite: 'Webseite öffnen',
    GeneralSupport: 'Allgemein',
    GroupDescription: 'Du und %{count} weitere Kontakte',
    GroupDuplicatesDescription: {
        one: 'Folgende Gruppe enthält dieselben Nutzer:\n\n%{names}\n\nWillst du trotzdem eine neue Gruppe erstellen?',
        other: 'Folgende Gruppen enthalten dieselben Nutzer:\n\n%{names}\n\nWillst du trotzdem eine neue Gruppe erstellen?'
    },
    GroupDuplicatesSelect: {
        one: 'Du hast bereits eine Gruppe mit denselben Nutzern. Welche soll verwendet werden?',
        other: 'Du hast bereits Gruppen mit denselben Nutzern. Welche soll verwendet werden?'
    },
    GroupDuplicatesTitle: 'Ähnliche Gruppen',
    Groups: 'Gruppen (%{count})',
    GroupName: 'Name der Gruppe',
    Help: 'Benötigst du Hilfe?',
    Hide: 'Ausblenden',
    HideAll: 'Alle ausblenden',
    HintThirdPartyConfirmation: 'Möglicherweise musst du deinen Account noch einmal per Pop-Up bestätigen',
    HintChooseAPlace: 'Bitte wähle, wo du essen möchtest!',
    HintChooseATime: 'Bitte wähle eine Uhrzeit aus!',
    Home: 'Hauptseite',
    Hours: '%{hours}h',
    HowToHelp: 'Wie können wir dir helfen?',
    Import: 'Verknüpfen',
    ImportContacts: 'Kontakte importieren',
    ImportContactsDesc: 'Wir benötigen Zugriff auf deine Kontakte um sie zu importieren',
    ImportDescription: 'Füge private Kontakte hinzu',
    Imprint: 'Impressum',
    ImprintAndGDPR: 'Impressum & Datenschutz',
    Inbox: 'Posteingang',
    InsteadLogin: 'Mit vorhandenem Account anmelden?',
    InsteadRegistration: 'Neuen Account erstellen?',
    InStore: 'Im Restaurant',
    IntroHeader0: 'Lunchen',
    IntroHeader1: 'Entdecken',
    IntroHeader2: 'Einladen',
    IntroHeader3: 'Vorbestellen',
    IntroUSP0: 'Erhalte täglich neue, personalisierte Restaurant-Vorschläge genau auf deinen Geschmack angepasst. Eine Mittagspause ganz nach deinem Gusto!',
    IntroUSP1: 'Entdecke in der Map-Ansicht auf einen Blick die besten Mittagstische in deiner Umgebung, ... und finde so den schnellsten Weg zu deinem Mittagessen!',
    IntroUSP2: 'Stimme Lunch-Termine über das Einladungs-Tool und die Chatfunktion ganz einfach mit deinen Freunden, Kollegen oder Geschäftspartnern ab.',
    IntroUSP3: 'Bestelle und bezahle bequem über die App. Genieße dein Mittagessen direkt vor Ort oder als Take-Away.',
    InvalidDateInfo: 'Öffnungszeiten Info',
    InvalidDateTitle: 'Ungültiges Datum',
    Invisible: 'Unsichtbar',
    InvitationAccepted: 'Bestätigt',
    InvitationCanceled: 'Storniert',
    InvitationCardSubline: '%{date}',
    InvitationDeclined: 'Abgelehnt',
    InvitationDeadlineExceededText: 'Die Verabredung findet bereits in weniger als %{minutes} Minuten statt und kann nur noch telefonisch storniert werden.',
    InvitationDeadlineExceededTitle: 'Verarbredung zu zeitnah',
    InvitationDeletedText: 'Oh, die Einladung wurde offenbar vom Ersteller gelöscht und existiert nicht mehr.',
    InvitationDeletedTitle: 'Verarbredung wurde gelöscht',
    InvitationHasOrdersText: 'Für die Verabredung existieren bereits Bestellungen, daher kann sie nur noch telefonisch storniert werden.',
    InvitationHasOrdersTitle: 'Bereits bestellt',
    InvitationPending: 'Angefragt',
    Invitations: 'Verabredungen',
    Invite: 'Einladen',
    InviteContacts: 'Kontakte einladen',
    IsSearching: 'Suche gerade…',
    Kilometers: '%{kilometers}km',
    lastDay: '[Gestern], H:mm',
    lastWeek: '[Letzter] dddd, H:mm',
    LetsGo: "Los geht's",
    Loading: 'Lädt…',
    LoadRestaurantFailed: 'Restaurant konnte nicht geladen werden',
    LocationPermissionText: 'Diese App möchte deinen Standort wissen',
    LocationPermissionTitle: 'Ortungsdienste',
    LocationScreenSubline: 'Gib eine Adresse ein, um LunchNow Vorschläge in dieser Umgebung zu bekommen.',
    LocationScreenTitle: 'Adresse eingeben',
    LocationServiceActivate: 'Jetzt deinen Standortdienst aktivieren?',
    Login: 'Anmelden',
    LoginAccount: 'Mit deinem Account einloggen',
    LoginApple: 'Mit Apple anmelden',
    LoginFacebook: 'Mit Facebook anmelden',
    LoginGoogle: 'Mit Google anmelden',
    LoginTitle: 'Melde dich mit deinem Account an',
    Logout: 'Ausloggen',
    LogoutMessage: 'Willst du dich abmelden?',
    LunchNotAvailable: 'Die Mittagsangabote sind nur von %{times} verfügbar. Bestelle gerne etwas aus den anderen Kategorien!',
    LunchNow: 'Lunch Now',
    LunchSpecials: 'Mittagstisch',
    LunchTime: 'Lunch-Zeit: %{time}',
    MaintenanceMessage: 'Unsere Küche wird gerade renoviert, bitte komm ein wenig später wieder!',
    MaintenanceTitle: 'Wartungsarbeiten',
    Mastercard: 'Mastercard',
    MeetAndEatAt: 'Meet and Eat im %{restaurant}',
    Menu: 'Speisekarte',
    Meters: '%{meters}m',
    Minutes: '%{minutes}min',
    MoiaAffiliateTerms: 'Aktiviere vor deiner nächsten MOIA Buchung deinen persönlichen Gutscheincode, indem du ihn in der MOIA-App vor deiner nächsten Fahrt hinterlegst. Erhalte so auf die nächsten 2 Fahrten jeweils %{discountAmount}% Rabatt. Dein 2x%{discountAmount}%-Gutschein ist einmalig und nur bis zum %{validUntil} innerhalb des MOIA Geschäftsgebiets in Hamburg zu den MOIA Servicezeiten gültig. Nicht kombinierbar mit anderen Rabattaktionen. Der Gutscheincode kann ausschließlich für 2 Fahrten eingelöst werden.',
    More: 'Mehr',
    MessageUs: 'Kontaktiere uns',
    MutualContactNotificationMessage: 'Ihr seid jetzt gegenseitige Kontakte.',
    MyContacts: 'Kontakt-Einladungen',
    MyDailies: 'Neuigkeiten & Rabattaktionen',
    MyInvitations: 'Lunch-Einladungen',
    NameGroup: 'Gruppe benennen',
    NameGroupDescription: 'Wie soll diese Gruppe heißen?',
    NearYou: 'In deiner Nähe',
    NewCenter: 'Neu zentrieren',
    NewGroup: 'Neue Gruppe: %{name}',
    NewMessage: 'Neue Nachricht',
    nextDay: '[Morgen], H:mm',
    nextWeek: '[Nächsten] dddd, H:mm',
    nextWeekAt: '[am nächsten] dddd, H:mm',
    No: 'Nein',
    NoAddress: 'Keine Adresse gefunden',
    NoAddressLastRestored: 'Keine Adresse gefunden. Letzte bekannte Position wird verwendet',
    NoAddressSetManually: 'Keine Adresse gefunden. Bitte setze sie manuell',
    NoBlockedUsers: 'Wenn du Personen blockierst, findest du diese hier wieder.',
    NoChatMessages: 'In diesem Chat gibt es (noch) keine Nachrichten.',
    NoChats: 'Du hast noch keine Chats.',
    NoFavorites: 'Du hast noch keine Favoriten hinzugefügt',
    NoLunchListed: 'Keine Lunch-Information vorhanden.',
    NoLunchListedDescription: 'Dieses Restaurant in deiner direkten Nähe hat leider keinen Mittagstisch bei uns eingetragen.',
    NoMatchingResults: 'Keine passenden Suchergebnisse',
    NoMoreVouchers: 'Es sind keine Rabattcodes mehr verfügbar.',
    NoNotifications: 'Keine Benachrichtigungen',
    NoRestaurants: 'Grummel grummel',
    NoRestaurantsDescription: 'Hier konnten leider keine Restaurants gefunden werden.',
    NotFound: 'Nicht gefunden? Versuche einen anderen oder genaueren Suchbegriff.',
    Notifications: 'Benachrichtigungen',
    NoUserFound: 'Wir können niemanden mit passendem Namen oder Mail-Adresse finden.',
    NoUserFoundByName: 'Wir können niemanden mit passendem Namen finden.',
    NoValidSearch: 'Bitte gib einen (längeren) Suchbegriff ein.',
    NoVouchers: 'Du hast leider noch keine Gutscheine.',
    Ok: 'OK',
    OnlyTakeAway: 'Momentan nur Abholung!',
    Open: 'Geöffnet',
    Opens: 'Öffnet %{date}',
    OpeningHours: 'Öffnungszeiten',
    OpenTodayUntil: '[Geöffnet bis] HH:mm',
    Or: 'oder',
    Order: 'Bestellung',
    OrderAnimationText: 'Deine Bestellung wird übermittelt.',
    OrderConfirmation: 'Bestellbestätigung',
    OrderConfirmationCancelationHint: 'Wenn du eine Bestellung stornieren möchtest, wende dich bitte rechtzeitig telefonisch an das Restaurant unter %{JSX}.',
    OrderConfirmationHint: 'Vielen Dank für deine Bestellung.',
    OrderConfirmationEmailHint: 'Wir haben dir die Bestätigung per Mail gesendet.',
    OrderConfirmationNumber: 'Bestellnummer: %{JSX}',
    OrderConfirmationNumberHint: 'Bestellnummer: %{JSX} im Restaurant vorzeigen.',
    OrderEmail: 'Bestellanfrage',
    OrderForDate: '%{date} um %{time} Uhr',
    OrderForPrice: 'Bestellen für %{value}',
    OrderMeetUpTitle: 'Wann möchtest du essen?',
    OrdersSupport: 'Bestellungen',
    Orders: 'Bestellungen',
    ParkingSpace: 'Parkplatz',
    Pay: 'Bezahlen',
    PaymentOptionsInhouse: 'Bezahlmethoden vor Ort',
    PaymentOptionsSelect: 'Zahlungsmethode auswählen',
    PayPal: 'PayPal',
    Pending: 'ausstehend',
    PermissionDenied: 'Keine Berechtigung',
    Permissions: 'Berechtigungen',
    PickImage: 'Bild auswählen',
    PlaceOfConsumption: 'Verzehrort',
    PreOrder: 'Vorbestellen',
    PreOrderAndPay: 'Vorbestellen',
    PriceCategoryHigh: '€€€',
    PriceCategoryLow: '€',
    PriceCategoryMedium: '€€',
    Prices: "Preis",
    PushSettings: 'Push-Einstellungen',
    PushSettingsDescription: 'Entscheide, welche Meldungen du von LunchNow bekommen willst.',
    Rating: 'Bewertung',
    RatingConfirmation: 'Vielen Dank, dass du dir Zeit genommen hast dem Restaurant und uns ein Feedback zu geben.',
    Redeem: 'Einlösen',
    Redeemed: 'Eingelöst',
    RedeemVoucher: 'Gutschein einlösen',
    Refresh: 'Aktualisieren',
    Registration: 'Account erstellen',
    RegistrationOptions: 'Registrier’ dich jetzt',
    RegistrationOptionsDescription: 'Für deine ganz persönliche Speisekarte. Deine Vorlieben, Einladungen und Vorschläge in einer App.',
    RegistrationOrLogin: 'Account erstellen oder einloggen',
    ReloadMessage: 'Bitte aktualisiere die Seite, um die neueste Version zu erhalten!',
    ReloadTitle: 'Veraltete Version',
    RemoveAttendee: 'Kontakt ausladen',
    RequestMoia: 'MOIA anfragen',
    RequestVoucherCode: '%{discountAmount}% Rabattcode anfragen',
    ResendCode: 'Code erneut schicken',
    Reservation: 'Reservierung',
    ResetFilter: 'Alle Filter zurücksetzen',
    ResetPassword: 'Passwort zurücksetzen',
    ResetPasswordMessage: 'Bist du sicher, dass du dein Passwort zurücksetzen willst?',
    RestaurantInfoTitle: 'Restaurantinformation',
    RestDay: 'Heute ist Ruhetag',
    RestDayExplanation: 'Am %{day} ist Ruhetag. Bitte besuche uns an einem anderen Tag.',
    Retry: 'Erneut versuchen',
    sameDay: '[Heute], H:mm',
    sameElse: 'DD.MM.YYYY, H:mm',
    sameElseAt: '[am] DD.MM.YYYY, H:mm',
    Save: 'Speichern',
    SaveEmail: 'E-Mail speichern',
    SaveName: 'Name speichern',
    Search: 'Suchen',
    SearchForContact: 'Name oder E-Mail-Adresse eingeben',
    SearchForLocation: "Suche z.B. nach 'Gärtnerstraße'",
    SearchForUsers: 'LunchNow-Nutzer suchen',
    SearchThisArea: 'In diesem Gebiet suchen',
    SeatsOutside: 'Außenplätze',
    SelectAttendeeGroup: 'Gruppe auswählen',
    SelectAttendees: 'Nutzer auswählen',
    SelectAttendeesDescription: 'Mit wem möchtest du dich verabreden?',
    SelectChatMembersDescription: 'Mit wem möchtest du chatten?',
    SelectContactsForSharingDescription: 'Mit wem möchtest du das Restaurant teilen?',
    SelectMembers: 'Nutzer auswählen',
    SelectMembersDescription: 'Wer soll Gruppenmitglied werden?',
    SelectPicture: 'Bild auswählen',
    Send: 'Senden',
    SendInvitation: 'Jetzt einladen',
    SendMessage: 'Nachricht schreiben',
    SendReminder: 'Erinnerung senden',
    SetPriceRange: 'Preis',
    Settings: 'Einstellungen',
    SettingsPermissionsDenied: 'LunchNow fehlt die Berechtigung. Bitte aktiviere diese in den Einstellungen deines Gerätes.',
    Share: 'Teilen',
    ShareContactMessage: 'Hey, %{name} möchte dich zu LunchNow einladen: %{url} oder nutze folgenden Code in deinem Profil: %{code}',
    ShareDescription: 'Teile die App mit Freunden',
    SharedInvitation: 'Eine Einladung wurde geteilt',
    SharedInvitationRemoved: 'Eine Einladung wurde geteilt, die bereits entfernt wurde.',
    SharedRestaurant: 'Ein Restaurant wurde geteilt',
    ShareRestaurant: 'Restaurant teilen',
    ShareRestaurantMessage: 'Hey, schau dir mal dieses Restaurant an: %{url} Wollen wir dort zusammen essen gehen? 😃',
    Show: 'Ansehen',
    ShowChat: 'Chat öffnen',
    ShowLess: 'Weniger lesen',
    ShowMore: 'Mehr lesen',
    ShowMoreItems: 'Mehr anzeigen',
    ShowMoreOptions: 'Mehr Auswahl anzeigen',
    ShowOrder: 'Bestellung anzeigen',
    ShowRestaurantDetails: 'Restaurant-Details anzeigen',
    ShowResults: '%{numberOfResults} Ergebnisse anzeigen',
    Skip: 'Überspringen',
    SmokingArea: 'Raucherbereich',
    SocialScreenTitleLoggedOut: 'Erstelle dir einen Account',
    Sodexo: 'Sodexo',
    StartPictureUpload: 'Bild wird hochgeladen',
    Success: 'Erfolgreich',
    SuccessBecomeInvisible: 'Du wirst jetzt nicht mehr von anderen gefunden',
    SuccessBecomeVisible: 'Du kannst jetzt von anderen gefunden werden',
    SuccessBlockedUsersAdded: {
        one: 'LunchNow-Nutzer wurde blockiert',
        other: 'LunchNow-Nutzer wurden blockiert'
    },
    SuccessBlockedUserSet: 'LunchNow-Nutzer wird blockiert',
    SuccessChangeEmail: 'E-Mail erfolgreich geändert',
    SuccessContactsAdded: {
        one: 'Kontakt wurde hinzugefügt',
        other: 'Kontakte wurden hinzugefügt'
    },
    SuccessContactsRemoved: {
        one: 'Kontakt wurde gelöscht',
        other: 'Kontakte wurden gelöscht'
    },
    SuccessEditDisplayName: 'Display-Name erfolgreich geändert',
    SuccessGroupRemoved: 'Kontakt Gruppe wurde gelöscht',
    SuccessGroupSent: 'Kontakt Gruppe wurde erstellt',
    SuccessGroupUpdated: 'Kontakt Gruppe wurde aktualisiert',
    SuccessImportContacts: {
        zero: 'Wir haben deine Kontakte mit existierenden LunchNow Nutzern abgeglichen. Es wurden keine neuen Kontakte gefunden.',
        one: 'Wir haben deine Kontakte mit existierenden LunchNow Nutzern abgeglichen. Es wurde ein Kontakt hinzugefügt.',
        other: 'Wir haben deine Kontakte mit existierenden LunchNow Nutzern abgeglichen. Es wurden %{count} Kontakte hinzugefügt.'
    },
    SuccessInvitationSent: 'Einladung erfolgreich gesendet',
    SuccessLogout: 'Du wurdest erfolgreich abgemeldet',
    SuccessProfilePictureDeleted: 'Profilbild wurde gelöscht',
    SuccessProfilePictureUpload: 'Profilbild aktualisiert',
    SuccessReminderSent: 'Die Erinnerung wurde verschickt',
    SuccessResetPassword: 'Passwort erfolgreich zurückgesetzt. Bitte schau in dein E-Mail-Postfach.',
    SuccessUnblockUser: 'Blockierung des LunchNow-Nutzers aufgehoben',
    SuggestedContact: 'Vorschläge',
    SuggestedContactDescription: 'Personen, die du kennen könntest',
    SuggestRestaurant: 'Schlag etwas vor!',
    SuggestRestaurantSubject: 'Vorschlag für ein Restaurant',
    Support: 'Support',
    SupportMail: 'Kontaktiere den Support',
    SwitchToMapAppText: 'Möchtest du zu Maps wechseln?',
    SwitchToMapAppTitle: 'Maps öffnen',
    Tag_attribute: 'Weitere Vorlieben',
    Tag_cuisine: 'Küche',
    Tag_product: 'Gericht',
    TakeAway: 'Zum Mitnehmen',
    Tastes: 'Sag uns was dir schmeckt.',
    TastesDescription: 'Wir zeigen dir die besten Angebote ganz nach deinen Vorlieben. Und das jeden Tag auf’s Neue.',
    TermsOfService: 'Nutzungsbedingungen',
    TextfieldLabel: 'Zeichen',
    thousandsSeparator: '.',
    TicketRestaurant: 'Ticket Restaurant®',
    TimeFormat: 'HH:mm',
    TnCLabelAfterLink: '.',
    TnCLabelBeforeLink: 'Es gelten unsere ',
    TnCLink: 'AGB',
    Today: 'Heute',
    TodaysTransactions: 'Heute (%{count})',
    Tomorrow: 'Morgen',
    TotalPrice: 'Summe',
    TryAgain: 'Nochmal versuchen',
    UnavailableUser: '%{name} erlaubt leider keine Chat-Nachrichten.',
    UnblockAndAddUser: 'Nicht mehr blockieren und als Kontakt hinzufügen',
    UnblockAndAddUserPrompt: 'Soll %{name} zu deinen Kontakten hinzugefügt werden?',
    UnblockUser: 'Nicht mehr blockieren',
    UninvitableUser: '%{name} erlaubt leider keine Einladungen.',
    UnverifiedCallToActionTitle: 'Bestätige deine E-Mail-Adresse und es kann losgehen!',
    UpcomingTransactions: 'Aktuelle (%{count})',
    UpdateAttendees: 'Teilnehmer aktualisieren',
    UpdatedPoliyDescription: 'Bitte akzeptiere die neueste Version unser Datenschutzbestimmungen, um fortzufahren.',
    UpdatedPoliyTitle: 'Geänderte Datenschutzbestimmungen!',
    ReadPolicy: 'Datenschutzbestimmungen lesen',
    UpdateMessage: 'Bitte lade die aktuelle Version im Store herunter.',
    UpdateTitle: 'Update benötigt',
    UploadProfilePictureHint: 'Lade ein Profilbild hoch, damit dich deine Freunde leichter finden.',
    UseCamera: 'Kamera…',
    UseGallery: 'Eigene Aufnahmen…',
    ValidUntil: 'Gültig bis %{date}',
    Vat: '%{percentage}% MwSt.',
    VerificationCode: 'Code',
    VerificationCodeAccepted: 'Deine E-Mail-Adresse wurde erfolgreich bestätigt',
    VerificationCodeSent: 'Ein neuer Bestätigungs-Code wurde verschickt',
    VerificationDescription: 'Um deine Sicherheit zu gewährleisten, musst du deine E-Mail-Adresse bestätigen, bevor du Funktionen wie das Einladen und Vorbestellen weiter nutzen kannst. Dieses Update betrifft alle Nutzer.',
    VerifyCallToActionDescription: 'Gib den Code ein, den wir an %{email} geschickt haben.',
    VerifyCallToActionTitle: 'Bitte bestätige deine E-Mail-Adresse',
    VerifyEmail: 'E-Mail-Adresse bestätigen',
    Version: 'Version: %{version}',
    ViewBlockedUsers: 'Blockierte Nutzer anzeigen',
    ViewNonPartner: 'Noch keine Details verfügbar',
    ViewRestaurant: 'Restaurant-Details anzeigen',
    Visa: 'Visa',
    VisibleButUnverifiedHint: 'Bitte beachte, dass du erst von anderen LunchNow-Nutzern gefunden werden kannst, wenn du deine E-Mail-Adresse bestätigt hast.',
    VisibilityHint: 'Ich möchte in der Suche gefunden werden können. ',
    VisibilityDescription: 'Mit deiner Zustimmung können andere registrierte LunchNow-Nutzer dich anhand deines Benutzernamens und deiner E-Mail finden.\nDeine E-Mail wird anderen LunchNow-Nutzern jedoch niemals angezeigt. Nur dein LunchNow-Nutzername und dein optionales Profilbild sind für andere sichtbar.',
    VisibilityLink: 'Mehr Informationen…',
    VisibilityTitle: 'Möchtest du von anderen LunchNow-Nutzern gefunden werden?',
    Vouchers: 'Gutscheine',
    VouchersDescription: 'Aktiviere den Gutschein deiner Wahl im Warenkorb!',
    WhatsAppMessage: 'Du kommst nicht weiter? Unser Team ist für dich via WhatsApp da.',
    WeekDayFormat: 'dd',
    Wifi: 'WLAN',
    WithMoiaToLunch: 'Fahre mit MOIA zum Lunch!',
    WrongReceiverText: 'Sorry, die Benachrichtigung war für einen LunchNow-Nutzer gedacht, der vor dir hier angemeldet war.',
    WrongReceiverTitle: 'Postgeheimnis!',
    Yes: 'Ja',
    You: 'Du',
    YourEmailAddress: 'Deine E-Mail',
    YourMessage: 'Deine Nachricht...',
    YourNewDisplayName: 'Dein neuer LunchNow-Nutzername',
    YourNewEmailAddress: 'Deine neue E-Mail',
    YourPassword: 'Passwort',
};
