import { group } from 'console';
import { bind } from 'decko';
import { computed, observable } from 'mobx';

import HttpRequest from '../helpers/HttpRequest';
import { assert } from '../helpers/Validate';
import ContactEntity, { compareContactEntites, ContactPerson } from '../types/models/ContactEntity';
import Group from '../types/models/Group';
import Invitation from '../types/models/Invitation';
import RestaurantEntry from '../types/models/RestaurantEntry';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

type ApiType = ApiManager<AccountManager<ApiType>>;

export interface InjectedInvitationDraftProps {
    invitationDraft: InvitationDraftManager;
}

export default class InvitationDraftManager {
    @observable
    protected _invitation: Partial<Invitation>;

    private _api: ApiType;
    private _forSharing = false;

    public constructor(api: ApiType) {
        this._invitation = {};
        this._api = api;
    }

    @computed
    public get date() {
        return this._invitation.date;
    }

    public set date(date: Date | undefined) {
        this._invitation.date = date;
    }

    @computed
    public get key() {
        return this._invitation.key;
    }

    @computed
    public get restaurant() {
        return this._invitation.restaurant;
    }

    public set restaurant(restaurant: RestaurantEntry | undefined) {
        this._invitation.restaurant = restaurant;
    }

    @computed
    public get attendees() {
        return this._invitation.attendees;
    }

    @computed
    public get otherAttendees() {
        const userId = this._api.account.user?.uid;

        return this.attendees?.filter(({ key }) => key !== userId);
    }

    @computed
    public get group() {
        return this._invitation.group;
    }

    public set isTakeAway(isTakeAway) {
        this._invitation.isTakeAway = isTakeAway;
    }

    @computed
    public get isTakeAway() {
        return this._invitation.isTakeAway;
    }

    public get forSharing() {
        return this._forSharing;
    }

    @bind
    public create(share = false) {
        this._invitation = {};
        this._forSharing = share;
    }

    @bind
    public setAttendees(attendees: ContactEntity | ContactPerson[]) {
        const { account } = this._api;
        const me = account.currentContactPerson;
        const newAttendees = Array.isArray(attendees) ? attendees.slice() : attendees.toPersons();

        if (me && !newAttendees.some(({ key }) => key === me.key)) {
            newAttendees.push(me);
        }

        this._invitation.group = (attendees instanceof Group) ? attendees.key : undefined;
        this._invitation.attendees = newAttendees.map(person => person.withStatus('pending')).sort(compareContactEntites);
    }

    @bind
    public async complete() {
        const { key, date, restaurant, attendees, isTakeAway, group } = this._invitation;

        assert(!key, 'Invitation draft is aready send');
        assert(date && restaurant && attendees, 'Invitation draft is incomplete');

        const attendeeKeys = attendees!.map(({ key }) => key);
        const response = await HttpRequest.put('/api/invitations', {
            date: date!.toISOString(),
            restaurant: restaurant!.key,
            attendees: attendeeKeys,
            isTakeAway,
            group
        });
        const partialInvitation = JSON.parse(response);

        this._invitation = { ...this._invitation, ...partialInvitation };

        return this._invitation as Invitation;
    }
}
