import { DialogTitle } from '@material-ui/core';
import { bind } from 'decko';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import colors from '../../../../lib/src/styles/colors';
import { Order } from '../../../../lib/src/types/models/Order';
import { ReactComponent as BadgeStarSvg } from '../../assets/svg/badge_star.svg';
import { InjectedPaymentProps } from '../../Payment';
import { PrimaryButton, SecondaryButton } from '../button';
import Modal from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import { SubHeadline } from '../text';
import OrderDetails from './OrderDetails';
import { Hr } from './Styled';

const RatingStars = styled.div`
    display: flex;
    justify-content: center;
`

const RatingStar = styled(BadgeStarSvg)<{ selected: boolean; }>`
    display: inline-block;
    width: 40px;
    height: 40px;

    path {
        fill: ${props => props.selected ? '#FFCC00' : '#C4C4C4'};
    }
`

interface Params {
    order?: Order;
}

@inject('payment')
@observer
export default class OrderRating extends Modal<Params> {
    private get injected() {
        return this.props as unknown as InjectedPaymentProps;
    }

    @observable
    private stars?: number;

    @observable
    private saved = false;

    private selectRating(number: number) {
        this.stars = number;
    }

    protected async hydrateParams(params: string[]) {
        return {
            order: await this.injected.payment.loadOrder('key', params[0])
        };
    }

    protected validateParams() {
        return true;
    }

    @bind
    private async saveRating() {
        const { order } = this.state.params;
        const { stars } = this;

        if (order && stars) {
            this.injected.payment
                .saveRating(order, { stars })
                .finally(() => this.saved = true);
        }
    }

    @bind
    private renderCta() {
        const SubmitButton: typeof PrimaryButton = this.stars ? PrimaryButton : SecondaryButton;

        return (
            <DialogTitle>
                <SubmitButton onClick={this.saved ? this.close : this.saveRating}>
                    {Env.i18n.t(this.saved ? 'Close' : 'Confirm')}
                </SubmitButton>
            </DialogTitle>
        );
    }

    private renderContent() {
        const { order } = this.state.params;

        if (!order) {
            return null;
        }

        return (
            <>
                <OrderDetails order={order} />
                <Hr />
                <RatingStars>
                    {Arrays.range(1, 5).map(key => (
                        <RatingStar key={key} selected={(this.stars || 0) >= key} onClick={() => this.selectRating(key)} />
                    ))}
                </RatingStars>
            </>
        )
    }

    private renderConfirmation() {
        return (
            <div style={{ flex: 1, justifyContent: 'center' }}>
                <SubHeadline style={{ color: colors.grey_01 }}>
                    {Env.i18n.t('RatingConfirmation')}
                </SubHeadline>
            </div>
        );
    }

    public render() {
        const { order } = this.state.params;

        if (!order) {
            return null;
        }

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderCta}>
                <ScreenHeader title={Env.i18n.t('Rating')} onBack={this.close} />
                <Hr />
                {this.saved ? this.renderConfirmation() : this.renderContent()}
            </Screen>
        );
    }
}
