import moment, { Moment } from 'moment';

import Env from '../Env';
import Arrays from '../helpers/Arrays';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import { CooperationType } from '../types/lunchnow';
import DiscountEntry from '../types/models/DiscountEntry';
import { getMinOrderDate, ORDER_ADVANCEMENT, ORDER_PROCESS_ETA, OrderDraft } from '../types/models/Order';
import RestaurantEntry from '../types/models/RestaurantEntry';
import { formatEnumeration, formatShortDay, formatTimeSpan } from './formatting';

const EMAIL_REGEX = /^.+@.+\..{2,}$/;
const PASSWORD_MIN_LENGTH = 8;
const VERIFICATION_CODE_REGEX = /^\d{6}$/;

type AccountType = AccountManager<ApiManager<AccountType>>;
type ValidationStatus = 'SUCCESS' | 'WARNING' | 'ERROR';

export class Validation {
    public static success = new Validation(undefined, 'SUCCESS');

    public readonly message: string | undefined;
    public readonly status: ValidationStatus;

    public static error(message: string) {
        return new Validation(message, 'ERROR');
    }

    public static warning(message: string) {
        return new Validation(message, 'WARNING');
    }

    public get valid() {
        return this.status !== 'ERROR';
    }

    private constructor(message: string | undefined, status: ValidationStatus = 'ERROR') {
        this.message = message;
        this.status = status;
    }
}

// TODO: we should probably rename this, as an "assert" is a testing tool and possible misleading in release builds
export function assert(isTrusy: any, elseError: string) {
    if (!isTrusy) {
        throw elseError;
    }
}

export function logError(message: any, error?: any) {
    if (error !== undefined) {
        console.warn(`${message}:`, error.message || error);
    } else {
        return (reason: any) => logError(message, reason);
    }
}

function catchError(fromFunction: () => void) {
    try {
        fromFunction();

        return Validation.success;
    } catch (e) {
        return Validation.error(String(e));
    }
}

export function getNormalizedOpeningHours(restaurant: RestaurantEntry, date: Moment) {
    const startOfDay = '00:00';
    const endOfDay = '24:00';
    const hoursToday = restaurant
        .getOpeningHours(date)
        .map(hour => hour.start! > hour.end! ? { ...hour, end: endOfDay } : hour);
    const hoursYesterday = restaurant
        .getOpeningHours(date.subtract(1, 'day'))
        .filter(hour => hour.start! > hour.end! && hour.end !== startOfDay)
        .map(hour  => ({ ...hour, start: startOfDay }));

    return [ ...hoursYesterday, ...hoursToday ];
}

const Validate = {
    groupName: (name: string) => catchError(() => {
        assert(name.length > 0, Env.i18n.t('ErrorGroupNameEmpty'));
    }),
    displayName: (name: string) => catchError(() => {
        assert(name.length > 0, Env.i18n.t('ErrorNameEmpty'));
    }),
    email: (email: string) => catchError(() => {
        assert(email.length > 0, Env.i18n.t('ErrorEmailEmpty'));
        assert(email.match(EMAIL_REGEX), Env.i18n.t('ErrorEmailInvalid'));
    }),
    password: (password: string, isNew = false) => catchError(() => {
        assert(password.length > 0, Env.i18n.t('ErrorPasswordEmpty'));
        assert(
            !isNew || password.length >= PASSWORD_MIN_LENGTH,
            Env.i18n.t('ErrorPasswordInvalid', { minLength: PASSWORD_MIN_LENGTH })
        );
    }),
    verificationCode: (code: string) => catchError(() => {
        assert(code.match(VERIFICATION_CODE_REGEX), Env.i18n.t('ErrorVerificationCodeInvalid'));
    }),
    date: (date: Date) => catchError(() => {
        assert(date >= new Date(), Env.i18n.t('ErrorDatePast'));
        assert(date <= moment().add(2, 'weeks').toDate(), Env.i18n.t('ErrorDateLaterTwoWeeks'));
    }),
    restaurantDate: (restaurant: RestaurantEntry, date: Date) => {
        const momentDate = moment(date);

        if (restaurant.isResting()) {
            const dateFormat = Env.i18n.t('DateFormat');
            const startDate = moment(restaurant.data?.restTime?.start?.toDate()).format(dateFormat);
            const endDate = moment(restaurant.data?.restTime?.end?.toDate()).format(dateFormat);
            const textKey = (startDate === endDate) ? 'ErrorRestaurantRestingDay' : 'ErrorRestaurantRestingDays';

            return Validation.error(Env.i18n.t(textKey, { startDate, endDate }));
        }

        const openingHours = getNormalizedOpeningHours(restaurant, momentDate);

        if (openingHours.length < 1) {
            if (restaurant.data?.type === CooperationType.NonPartner) {
                return Validation.warning(Env.i18n.t('ErrorNoOpeninghours'));
            }

            const allOpeningHours = Object.entries(restaurant.data?.openingHours || {});
            const openDays = Arrays.clean(allOpeningHours.map(([ day, hours ]) => hours?.length > 0 ? Number(day) : undefined));
            const days = formatEnumeration(openDays.map(day => formatShortDay(day - 1)));

            return Validation.error(Env.i18n.t('ErrorRestaurantClosedDay', { days }));
        }

        const time = momentDate.format('HH:mm');

        if (!openingHours.some(hours => (hours.start || '') <= time && (hours.end || '9') >= time)) {
            const times = formatEnumeration(openingHours.map(hours => formatTimeSpan(hours)));

            return Validation.error(Env.i18n.t('ErrorRestaurantClosedTime', { times }));
        }

        return Validation.success;
    },
    orderDiscounts: (order: OrderDraft, time: Date, account: AccountType) => {
        const momentTime = moment(time);
        let unavailableDiscount: DiscountEntry | undefined;

        order.items.some(item => {
            const discount = item.discount && account.allDiscounts.find(_discount => _discount.key === item.discount?.id);

            if (discount?.availableForTime(momentTime) === false) {
                unavailableDiscount = discount;

                return true;
            }
        });

        if (unavailableDiscount) {
            const name = unavailableDiscount.name[Env.currentLanguageCode()];
            const time = unavailableDiscount.timeLimitsHint;

            return Validation.warning(Env.i18n.t('DiscountAvailableTimeOnlyNamed', { name, time }));
        }

        return Validation.success;
    },
    orderTime: (order: OrderDraft, time: Date, account: AccountType) => {
        const momentTime = moment(time);
        const { meetUpDate, restaurant } = order;

        if (!momentTime.isSame(moment(), 'day')) {
            return Validation.error(Env.i18n.t('ErrorOrderNotToday'));
        }

        if (meetUpDate && !momentTime.isSame(meetUpDate, 'day')) {
            return Validation.error(Env.i18n.t('ErrorOrderWrongDay'));
        }

        if (momentTime.isBefore(getMinOrderDate())) {
            // Parentheses seem necessary to keep VSCode from removing the constants import...
            const minutes = (ORDER_ADVANCEMENT) + (ORDER_PROCESS_ETA);

            return Validation.error(Env.i18n.t('ErrorOrderTooSoon', { minutes }));
        }

        const restaurantDateValidation = Validate.restaurantDate(restaurant!, time);

        if (!restaurantDateValidation.valid) {
            return restaurantDateValidation;
        }

        return Validate.orderDiscounts(order, time, account);
    }
};

export default Validate;

export const FirebaseError = {
    UNKNOWN: 'auth/unknown',
    AUTH_DISABLED: 'auth/operation-not-allowed',
    EMAIL_TAKEN: 'auth/email-already-in-use',
    EMAIL_INVALID: 'auth/invalid-email',
    NO_NETWORK: 'auth/network-request-failed', // TODO: handle
    PASS_WEAK: 'auth/weak-password',
    PASS_WRONG: 'auth/wrong-password',
    POPUP_CLOSED: 'auth/popup-closed-by-user',
    PROVIDER_ALREADY_LINKED: 'auth/provider-already-linked',
    PROVIDER_ALREADY_USED: 'auth/credential-already-in-use',
    USER_DISABLED: 'auth/user-disabled',
    USER_NOT_FOUND: 'auth/user-not-found',
    TOO_MANY_ATTEMPTS: 'auth/too-many-requests',
    MESSAGE_TOO_MANY_ATTEMPTS: 'Too many unsuccessful login attempts. Please try again later.',
    ACCOUNT_ALREADY_EXISTING: 'auth/account-exists-with-different-credential',
    USER_MISMATCH: 'auth/user-mismatch'
};

export const FirebaseErrorHandler = {
    forEmail: (error: any) => {
        switch (error.code) {
            case FirebaseError.EMAIL_TAKEN:
                return Validation.error(Env.i18n.t('ErrorEmailTaken'));
            case FirebaseError.EMAIL_INVALID:
                return Validation.error(Env.i18n.t('ErrorEmailInvalid'));
            case FirebaseError.USER_DISABLED:
            case FirebaseError.USER_NOT_FOUND:
                return Validation.error(Env.i18n.t('ErrorNoAccount'));
        }

        return Validation.success;
    },
    forPassword: (error: any) => {
        switch (error.code) {
            case FirebaseError.AUTH_DISABLED:
                return Validation.error(Env.i18n.t('ErrorAuthDisabled'));
            case FirebaseError.PASS_WEAK:
                return Validation.error(Env.i18n.t('ErrorPasswordWeak'));
            case FirebaseError.PASS_WRONG:
                return Validation.error(Env.i18n.t('ErrorPasswordIncorrect'));
            case FirebaseError.TOO_MANY_ATTEMPTS:
                return Validation.error(Env.i18n.t('ErrorTooManyAttempts'));
            case FirebaseError.USER_MISMATCH:
                return Validation.error(Env.i18n.t('ErrorUserMismatch'));
            case FirebaseError.UNKNOWN:
                // needed for wrong error code from RNFirebase
                if (String(error.message).includes(FirebaseError.MESSAGE_TOO_MANY_ATTEMPTS)) {
                    return Validation.error(Env.i18n.t('ErrorTooManyAttempts'));
                }
        }

        return Validation.success;
    },
    forModeChange: (error: any) => {
        return [
            FirebaseError.EMAIL_TAKEN,
            FirebaseError.USER_DISABLED,
            FirebaseError.USER_NOT_FOUND
        ].includes(error.code);
    }
};
