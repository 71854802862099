import firebase from 'firebase';
import { computed, IReactionDisposer, observable, reaction } from 'mobx';
import moment from 'moment';

import Env from '../Env';
import ChatsManager from '../managers/ChatsManager';
import { ChatHeader, ChatMessage, ChatMessageType } from '../types/models/Chat';
import ContactEntity, { ContactPerson } from '../types/models/ContactEntity';
import EnhancedChatMessage from '../types/models/EnhancedChatMessage';
import Group from '../types/models/Group';
import Invitation from '../types/models/Invitation';
import RestaurantEntry from '../types/models/RestaurantEntry';
import DataList, { Snapshot } from './DataList';

/**
 * Lists the loaded messages in *ascending* chronological order.
 */
export default class ChatMessageList extends DataList<EnhancedChatMessage> implements ChatHeader {
    public static readonly PAGE_SIZE = 50;

    private _chatManager: ChatsManager;
    private _personsUnsubscriber: IReactionDisposer;
    private _headerUnsubscriber: () => void;
    private _hasOlderMessages = true;
    private _loadingMore = false;

    @observable
    private _lastSnapshot?: firebase.firestore.DocumentSnapshot;

    @observable
    private _header: ChatHeader = {
        key: '',
        participants: {},
        participantKeys: [],
        participantKeysStr: '',
        activeParticipantKeys: [],
        otherParticipants: {},
    };

    public readonly key: string;

    @computed
    public get name() {
        return this._chatManager.groups.getChatName(this._header);
    }

    @computed
    public get participants() {
        return this._header.participants;
    }

    @computed
    public get participantKeys() {
        return this._header.participantKeys;
    }

    @computed
    public get participantKeysStr() {
        return this._header.participantKeysStr;
    }

    @computed
    public get activeParticipantKeys() {
        return this._header.activeParticipantKeys;
    }

    @computed
    public get otherParticipants() {
        return this._header.otherParticipants;
    }

    @computed
    public get isGroup() {
        return !!this.group;
    }

    @computed
    public get group() {
        return this._chatManager.groups.groups.get(this.key);
    }

    public static async createForKey(key: string, chatManager: ChatsManager) {
        const chat = new this(key, chatManager);

        if (!chat.group) {
            const { account } = chatManager;
            const userId = account.user?.uid;
            const otherKey = await new Promise<string | undefined>(resolve => reaction(
                () => chat.participantKeys,
                (keys, handler) => {
                    if (keys.length) {
                        handler.dispose();
                        resolve(keys.find(key => key !== userId));
                    }
                },
                { fireImmediately: true }
            ));

            if (otherKey && account.isBlockedBy(otherKey)) {
                chat.release();
                Env.snackbar.error(Env.i18n.t('UnavailableUser', { name: chat.participants[otherKey]?.name }));

                return;
            }
        }

        return chat;
    }

    public static async createForContact(contact: ContactEntity, chatManager: ChatsManager) {
        const group = (contact instanceof Group) ? contact : undefined;
        const key = group?.key || ChatsManager.collectionRef.doc().id;
        const participants = group?.participants || [ contact as ContactPerson, chatManager.account.currentContactPerson! ]
            .reduce((list, { key, name, photoURL }) => ({ ...list, [key]: { name, photoURL } }), {});
        const participantKeys = Object.keys(participants);
        const participantKeysStr = Group.participantKeysToString(participantKeys);

        if (!group && chatManager.account.isBlockedBy(contact.key)) {
            Env.snackbar.error(Env.i18n.t('UnavailableUser', { name: contact.name }));

            return;
        }

        await ChatsManager.collectionRef.doc(key).set(<ChatHeader>{
            participants,
            participantKeys,
            participantKeysStr,
            activeParticipantKeys: participantKeys,
            isGroup: !!group
        });

        return this.createForKey(key, chatManager);
    }

    private constructor(key: string, chatManager: ChatsManager) {
        super();

        this._chatManager = chatManager;
        this.key = key;

        this._personsUnsubscriber = reaction(
            () => [ this._lastSnapshot, this._chatManager.account.contacts.list, this._chatManager.groups.groups.list ],
            () => this._lastSnapshot && (this._header = this._chatManager.createChatHeader(this._lastSnapshot))
        );

        this._headerUnsubscriber = ChatsManager.collectionRef.doc(this.key).onSnapshot(snapshot => {
            const data = snapshot.data() as ChatHeader;

            if (!this.participantKeys.length) {
                // first snapshot
                this.loadMore();
            } else if (data?.lastMessage && data.lastMessage.key !== this._list.slice(-1)[0]?.key) {
                const message = this.createEnhancedChatMessage(data.lastMessage);

                if (message.contentId && message.content === 'invitation') {
                    // remove matching restaurant messages
                    reaction(
                        () => message.restaurant,
                        (restaurant, handle) => {
                            if (restaurant) {
                                handle.dispose();

                                const today = moment().startOf('day');
                                const toRemove = this._list.filter(({ content, contentId, timestamp }) =>
                                    contentId === restaurant.key && content === 'restaurant' && today.isBefore(timestamp)
                                );

                                // TODO: does this work well concurrently? Otherwise store filters and override list getter
                                this._list = this._list
                                    .filter(msg => !toRemove.includes(msg))
                                    .concat([ message ]);
                                toRemove.forEach(msg => msg.release());
                            }
                        },
                        { fireImmediately: true }
                    )
                } else {
                    this.add(message);
                }
            }

            if (data.participantKeysStr !== this.participantKeysStr) {
                this._lastSnapshot = snapshot;
            }
        });
    }

    public reset() {
        this._list.forEach(message => message.release());
        super.reset();
    }

    public set(...items: EnhancedChatMessage[]) {
        this._list.forEach(message => items.includes(message) || message.release());
        super.set(...items);
    }

    public addQuerySnapshotChildren(snapshot: Snapshot, override = false) {
        const write = override ? this.set : this.add;

        write.bind(this)(...snapshot.docs.map(doc => this.createEnhancedChatMessage({ ...doc.data(), key: doc.id })));
    }

    public remove(item: EnhancedChatMessage | string) {
        this.get(typeof item === 'string' ? item : item.key)?.release();
        super.remove(item);
    }

    public async loadMore() {
        if (!this._loadingMore && this._hasOlderMessages) {
            this._loadingMore = true;

            const nextPageQuery = ChatsManager.collectionRef.doc(this.key).collection('messages')
                .where('timestamp', '<', this._list.slice(0, 1)[0]?.timestamp || new Date())
                .orderBy('timestamp', 'desc')
                .limit(ChatMessageList.PAGE_SIZE + 1);
            const nextPage = (await nextPageQuery.get()).docs;

            this._hasOlderMessages = (nextPage.length > ChatMessageList.PAGE_SIZE);

            if (this._hasOlderMessages) {
                nextPage.pop();
            }

            super.set(
                ...nextPage.reverse().map(doc => this.createEnhancedChatMessage({ ...doc.data(), key: doc.id } as ChatMessage)),
                ...this._list
            );
            this._loadingMore = false;
        }
    }

    public async sendMessage(message: string | RestaurantEntry | Invitation) {
        const chatRef = ChatsManager.collectionRef.doc(this.key);
        const messagesRef = chatRef.collection('messages');
        const data: any = {
            sender: this._chatManager.account.user?.uid,
            timestamp: new Date() // don't use `serverTimestamp` here, as it is error-prone and inefficient due to deferred creation
        };

        if (typeof message === 'string') {
            data.content = message;
        } else {
            const type: ChatMessageType = (message instanceof RestaurantEntry) ? 'restaurant' : 'invitation';

            data.content = type;
            data.contentId = message.key;
        }

        data.key = (await messagesRef.add(data)).id;
        await chatRef.update({ lastMessage: data });

        // remove replaced messages
        if (data.contentId && data.content === 'invitation') {
            const restaurantMessages = await messagesRef
                .where('content', '==', 'restaurant')
                .where('contentId', '==', (message as Invitation).restaurant?.key)
                .where('timestamp', '>=', moment().startOf('day').toDate())
                .get();

            await Promise.all(restaurantMessages.docs.map(({ ref }) => ref.delete()));
        }

        return this;
    }

    public async hide() {
        await this._chatManager.hideChat(this.key);
    }

    public release() {
        this._personsUnsubscriber();
        this._headerUnsubscriber();
        this._list.forEach(message => message.release());
    }

    private createEnhancedChatMessage(message: ChatMessage) {
        return new EnhancedChatMessage(message, this._chatManager);
    }
}
