import { DataListEntry } from '../../store/DataList';
import { ContactUser } from './User';

export enum NotificationType {
    contact = 'contact',
    group = 'group',
    invitation = 'invitation',
    beingAdded = 'beingAdded',
    orderCanceledByRestaurant = 'orderCanceledByRestaurant',
    discount = 'discount',
    chatMessage = 'chatMessage'
}

export interface NotificationSubject {
    type: NotificationType;
    id: string;
}

export interface Notification extends DataListEntry {
    title: string;
    body: string;
    sender?: ContactUser;
    subject?: NotificationSubject;
    sent: firebase.firestore.Timestamp;
    closed: boolean;
    receiver?: string;
    actions?: {
        open?: {
            label: string
        },
        hide?: {
            label: string
        }
    };
}

