import firebase from 'firebase';
import moment from 'moment';

import Constants from './Constants';
import Snackbar, { SnackbarTrigger } from './helpers/Snackbar';
import { logError } from './helpers/Validate';
import Action from './types/Action';
import List from './types/List';

export type Stage = 'dev' | 'beta' | 'prod';

type Firebase = typeof firebase;

export interface Info {
    version: string;
    readableVersion?: string;
    bundleId: string;
}

export interface I18n {
    defaultLocale: string;
    t: (key: string, params?: List<any>) => string;
    currentLocale: () => string;
    hourFormat: number;
}

export interface Assets {
    fallbackImage: string;
    icons: {
        AmEx: any;
        ApplePay: any;
        BadgeCheck: any;
        BadgeDeclined: any;
        BadgeRequest: any;
        CashPayment: any;
        DinersClub: any;
        EcCard: any;
        GooglePay: any;
        Mastercard: any;
        AccessTime: any;
        Offer: any;
        PayPal: any;
        PreorderRestaurant: any;
        Visa: any;
        Wifi: any;
    };
}

type Sharer = (message: string) => Promise<void>;
type Logger = (eventName: string, eventParams?: List<any>) => void;
type AlertTrigger = (title: string, message?: any, actions?: Action[], cancelable?: boolean) => void;

interface Config {
    info: Info;
    firebase: Firebase;
    i18n: I18n;
    assets: Assets;
    sharer: Sharer;
    logger?: Logger;
    snackbarTrigger: SnackbarTrigger;
    alertTrigger: AlertTrigger;
}

export default abstract class Env {
    private static _info: Info = {} as Info;
    private static _stage: Stage = '' as Stage;
    private static _firebase = {} as Firebase;
    private static _partnerFirebase = {} as firebase.app.App;
    private static _i18n = {} as I18n;
    private static _assets = { fallbackImage: '', icons: {} } as Assets;
    private static _snackbar = new Snackbar(() => null);

    private static _alertTrigger: AlertTrigger = () => null;

    private static _sharer: Sharer = () => Promise.resolve();

    private static _logger: Logger = (eventName: string, eventParams?: List<any>) => {
        Env.firebase.analytics().logEvent(eventName, eventParams);
    };

    public static get info() {
        return this._info;
    }

    public static get stage() {
        return this._stage;
    }

    public static get firebase() {
        return this._firebase;
    }

    public static get partnerFirebase() {
        return this._partnerFirebase;
    }

    public static get i18n() {
        return this._i18n;
    }

    public static get assets() {
        return this._assets;
    }

    public static get snackbar() {
        return this._snackbar;
    }

    public static alert(title: string, message?: any, actions?: Action[], cancelable?: boolean) {
        this._alertTrigger(title, message, actions, cancelable);
    }

    public static share(message: string) {
        return this._sharer(message);
    }

    public static logEvent(eventName: string, eventParams?: List<any>) {
        this._logger(eventName, eventParams);
    }

    public static init(config: Config) {
        this._info = config.info;
        this._stage = (() => {
            if (this.info.bundleId.includes('.dev')) {
                return 'dev';
            } else if (this.info.bundleId.includes('.beta')) {
                return 'beta';
            } else {
                return 'prod';
            }
        })();
        this._firebase = config.firebase;
        this._partnerFirebase = this.getFirebaseApp('partnerProject', Constants.PARTNER_FIREBASE_CONFIG);
        this._i18n = config.i18n;
        this._assets = config.assets;
        this._sharer = config.sharer;
        this._snackbar = new Snackbar(config.snackbarTrigger);
        this._alertTrigger = config.alertTrigger;

        moment.locale(this.currentLanguageCode());

        if (config.logger) {
            this._logger = config.logger;
        }

        return new Promise(resolve => {
            const auth = this.partnerFirebase.auth();
            const unsubscriber = auth.onAuthStateChanged(partnerUser => {
                unsubscriber();

                if (partnerUser?.isAnonymous === false) {
                    resolve(undefined);
                } else {
                    auth.signInWithEmailAndPassword(Constants.PARTNER_PROJECT_USER, Constants.PARTNER_PROJECT_PASSWORD)
                        .catch(error => {
                            logError('Env.partnerFirebase.signIn', error);
                            this.alert(Env.i18n.t('ErrorInternetConnection'));
                        })
                        .finally(() => resolve(undefined))
                }

            });
        })
    }

    public static initAnalytics() {
        if (Constants.FIREBASE_CONFIG.measurementId) {
            Env.firebase.analytics().setAnalyticsCollectionEnabled(true);
        }
    }

    public static currentLanguageCode() {
        return this._i18n.currentLocale().substring(0, 2);
    }

    private static getFirebaseApp(name: string, configIfUnitialized: object) {
        const app = firebase.apps.find(_app => _app.name === name);

        return app || firebase.initializeApp(configIfUnitialized, name);
    }
}
