import MoreVert from '@material-ui/icons/MoreVert';
import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { InjectedChatsProps } from '../../../../lib/src/managers/ChatsManager';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { GRID_SIZE } from '../../styles/base';
import { PrimarySmallButton, SecondarySmallButton } from '../button';
import ActionSheet, { Option } from '../common/ActionSheet';
import FilteredContactList, { FilteredContactListState } from './FilteredContactList';

const TOGGLE_BUTTON_SIZE = GRID_SIZE * 2.5;

const ToggleButton = styled(MoreVert)`
    left: ${(GRID_SIZE * 4 - TOGGLE_BUTTON_SIZE) / 2}px;
    position: absolute;
    width: ${TOGGLE_BUTTON_SIZE}px;
`;

interface Props<T extends ContactEntity> {
    contacts: T[];
    onNavigate: (route: string, params?: string[]) => void;
}

interface State<T extends ContactEntity> {
    selected?: T;
}

/** Implementations need to have `Api`, `InvitationDraftManager` and `ChatsManager` injected! */
export default abstract class AbstractContactsTab<T extends ContactEntity> extends React.Component<Props<T>, State<T>> {
    public readonly state: State<T> = {};

    private actionSheetRef = React.createRef<ActionSheet>();
    private contactListRef = React.createRef<FilteredContactList<T>>();

    protected get injected() {
        return this.props as Props<T> & InjectedApiProps & InjectedInvitationDraftProps & InjectedChatsProps;
    }

    public abstract addNew(): void;

    public abstract renderModals(): React.ReactElement<any> | undefined;

    @bind
    protected async handlePromise(
        promiseMaker: (contact: T) => Promise<any>,
        successMessage: string,
        errorMessage: string
    ) {
        const { selected } = this.state;

        if (selected) {
            this.contactListRef.current?.waitFor(selected, promiseMaker, successMessage, errorMessage);
        }
    }

    protected renderList(showSections: boolean, options: Option[]) {
        const { contacts } = this.props;
        const { selected } = this.state;

        return (
            <>
                <FilteredContactList
                    ref={this.contactListRef}
                    contacts={contacts}
                    renderItemContent={this.renderContact}
                    onItemPress={this.selectContact}
                    ListEmptyComponent={this.renderEmptyList}
                    disableSections={!showSections}
                    hideFilterIfEmpty={true}
                    dependsOn={this.injected.api.account.blockingUsersCount}
                />
                <ActionSheet
                    ref={this.actionSheetRef}
                    title={selected?.name}
                    onClose={this.cancelEditContact}
                    options={[
                        {
                            label: Env.i18n.t('SendMessage'),
                            action: this.sendMessage
                        },
                        ...options
                    ]}
                />
            </>
        );
    }

    protected abstract renderEmptyList(state: FilteredContactListState<T>): React.ReactElement<any> | undefined;

    @bind
    private renderContact(contact: T) {
        const isBlocked = this.injected.api.account.isBlockedBy(contact);
        const Button: typeof PrimarySmallButton = isBlocked ? SecondarySmallButton : PrimarySmallButton;

        return (
            <>
                <ToggleButton />
                <Button onClick={event => this.invite(contact, event)}>
                    {Env.i18n.t('Invite')}
                </Button>
            </>
        );
    }

    @bind
    private cancelEditContact() {
        this.setState({ selected: undefined });
    }

    @bind
    private selectContact(selected: T, target?: HTMLElement) {
        if (!this.state.selected) {
            this.setState({ selected }, () => this.actionSheetRef.current?.show(target));
        }
    }

    private invite(contact: T, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();

        if (this.injected.api.account.isBlockedBy(contact)) {
            Alert.notify('', Env.i18n.t('UninvitableUser', contact));
        } else {
            const { invitationDraft } = this.injected;

            invitationDraft.create();
            invitationDraft.setAttendees(contact);
            Env.logEvent('meetup_from_contactlist');
            this.props.onNavigate('invitationrestaurant');
        }
    }

    @bind
    private async sendMessage() {
        const { selected } = this.state;

        if (selected) {
            const { api, chats } = this.injected;

            if (await api.waitFor(chats.getChatForContact(selected))) {
                this.props.onNavigate('chatmessages');
            }
        }
    }
}
