import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Cart from '../../../../lib/src/store/Cart';
import { PaymentOption } from '../../../../lib/src/types/models/Order';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import SafariFix from '../common/SafariFix';
import { SectionHeadline, TitleText } from '../text';

const AliasText = styled(TitleText)`
    font-size: 0.9em;
    white-space: nowrap;
`;

const CardItemWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
`;

const PaymentIcon = styled.img`
    transform: scale(0.7);
    margin-left: ${GRID_SIZE * 2}px;
    max-width: 100px;
`;

const StyledRadioLabel = styled(FormControlLabel)`
    &&& {
        margin-right: 0;
        display: flex;

        & > span:last-child {
            overflow: hidden;
        }
    }
`;

interface PaymentSelectOption {
    type: PaymentOption;
    label: string;
    logo: string;
}

interface PaymentOptionProps {
    paymentOption: PaymentSelectOption;
}

@inject('payment')
class PaymentOptionItem extends React.PureComponent<PaymentOptionProps> {
    private get injected() {
        return this.props as unknown as PaymentOptionProps & InjectedPaymentProps;
    }

    public render() {
        const { label, logo } = this.props.paymentOption;

        return (
            <CardItemWrapper>
                <AliasText>
                    {label}
                </AliasText>
                <PaymentIcon src={logo} />
            </CardItemWrapper>
        );
    }
}

interface Props {
    cart: Cart;
}

@observer
export default class PaymentOptions extends React.PureComponent<Props> {
    private get paymentOptions() {
        return [
            {
                type: 'paypal',
                label: Env.i18n.t('PayPal'),
                logo: require('../../assets/svg/logo_paypal.svg')
            }
        ] as Array<PaymentSelectOption>;
    }

    public render() {
        const { paymentOption } = this.props.cart;
        const { paymentOptions } = this;
        const selectedPaymentOption = paymentOptions.findIndex(({ type }) => type === paymentOption);

        return (
            <>
                <SafariFix.OverflowWrapper>
                    <SectionHeadline>
                        {Env.i18n.t('PaymentOptionsSelect')}
                    </SectionHeadline>
                    <RadioGroup onChange={this.handleChange} value={selectedPaymentOption}>
                        <Grid container spacing={2}>
                            {paymentOptions.map((paymentOption, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <StyledRadioLabel
                                        label={(
                                            <PaymentOptionItem paymentOption={paymentOption} />
                                        )}
                                        control={(
                                            <Radio color="default" value={index} />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </SafariFix.OverflowWrapper>
            </>
        );
    }

    @bind
    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.cart.paymentOption = this.paymentOptions[event.target.value]?.type;
    }
}
