import Check from '@material-ui/icons/Check';
import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import IconButton from '../common/IconButton';

interface Checkable {
    checked: boolean;
}

const Container = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

export const CheckBoxMarker = styled(IconButton).attrs({
    icon: Check,
})<Checkable>`
    background-color: ${({ checked }) => checked ? colors.teal_50 : 'transparent'};
    border: ${GRID_SIZE / 8}px solid ${({ checked }) => checked ? colors.teal_50 : colors.grey_03};
    border-radius: ${GRID_SIZE / 2}px;
    color: ${({ checked }) => checked ? colors.teal_900 : 'transparent'};
    margin: 0 ${GRID_SIZE * 2}px;
`;

const Label = styled.div`
    flex: 1;
`;

interface Props {
    checked?: boolean;
    onToggle?: (checked: boolean) => void;
    style?: React.CSSProperties;
    className?: string;
}

type State = Checkable;

export default class Checkbox extends React.PureComponent<Props, State> {
    public readonly state = {
        checked: this.props.checked || false
    };

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.checked !== undefined && this.props.checked !== prevProps.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    public render() {
        return (
            <Container onClick={this.onPress} className={this.props.className} style={this.props.style}>
                <CheckBoxMarker checked={this.state.checked} />
                <Label>
                    {this.props.children}
                </Label>
            </Container>
        );
    }

    @bind
    private onPress() {
        this.setState(
            state => ({ checked: !state.checked }),
            () => {
                if (this.props.onToggle) {
                    this.props.onToggle(this.state.checked);
                }
            }
        );
    }
}
