import { bind } from 'decko';
import { observable, reaction } from 'mobx';

import Timestamps from '../../../lib/src/types/Timestamps';
import Env from '../Env';
import { getPaymentUserRef } from '../helpers/Firestore';
import HttpRequest from '../helpers/HttpRequest';
import { getCartId, paymentError } from '../helpers/Payment';
import { assert } from '../helpers/Validate';
import Cart from '../store/Cart';
import { logOrder, Order, OrderData, OrderRating, PaymentOption } from '../types/models/Order';
import { PayPalTransaction } from '../types/models/Payment';
import RestaurantEntry from '../types/models/RestaurantEntry';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

export type ExternalTransactionParams = Partial<PayPalTransaction>;

export interface PayPalOrderResponse {
    links?: Array<{
        rel: string;
        href: string;
    }>;
    [key: string]: any;
}

export type RefundReasonType =
    'no_time' |
    'too_much' |
    'mistake' |
    'restaurant_declined' |
    'restaurant_declined_sold_out' |
    'restaurant_declined_no_capacity' |
    'restaurant_declined_no_reaction' |
    null;

type ApiType = ApiManager<AccountManager<ApiType>>;

export type OrderTime = 'past' | 'future';

 export default abstract class PaymentManager<Api extends ApiType> {
    public static readonly ORDERS_PER_PAGE = 5;

    protected _api: Api;
    protected awaitPaymentUser: Promise<void>;

    private unsubscribeOrders: Partial<Record<OrderTime, () => void>> = {};

    @observable
    private _carts = new Map<string, Cart>();

    constructor(api: Api, waitFor: Promise<any> = Promise.resolve()) {
        this._api = api;
        this.awaitPaymentUser = new Promise(resolve => {
            waitFor.then(() => {
                // this reaction is never disposed
                reaction(
                    () => this._api.account.user,
                    async () => {
                        if (this._api.account.loggedIn) {
                            // keeping the carts, since user is always anonymous before
                            resolve();
                        } else {
                            this.reset();
                        }
                    },
                    { fireImmediately: true }
                );
            });
        });
    }

    private serializeOrder(order: Order): OrderData {
        const { restaurant, key, meetUpDate, meetUp, ...orderData } = order;

        return {
            ...orderData,
            restaurantId: restaurant?.key || '',
            meetUp: meetUp || '',
            meetUpDate: meetUpDate.getTime()
        };
    }

    @bind
    private async deserializeOrder(data: OrderData, key: string): Promise<Order | undefined> {
        const { restaurantId, meetUpDate, ...orderData } = data;
        const restaurant = await this._api.getRestaurant(restaurantId, this._api.location.userLocationCoordinate);

        return {
            ...orderData,
            key,
            restaurant,
            meetUpDate: Timestamps.toDate(meetUpDate)
        };
    }

    private async reset() {
        this._carts = new Map<string, Cart>();

        Object.values(this.unsubscribeOrders).forEach(unsubscribeOrder => unsubscribeOrder && unsubscribeOrder());
    }

    private finishPayPalPaymentTransaction(payInId: string) {
        return HttpRequest.post(`/api/payment/orders/${payInId}/capture`);
    }

    public async loadOrder(by: 'payInId' | 'key', id: string) {
        const userId = this._api.account.user?.uid;

        if (userId) {
            let query = getPaymentUserRef(userId).collection('orders');
            let orderSnap: firebase.firestore.DocumentSnapshot;

            if (by === 'payInId') {
                const result = await query.where('payInId', '==', id).get();
                orderSnap = result.docs[0];
            } else {
                orderSnap = await query.doc(id).get();
            }

            if (orderSnap) {
                return this.deserializeOrder(orderSnap.data() as OrderData, orderSnap.id);
            }
        }
    }

    /**
     * Creates and returns a new `Cart` for `restaurant` if none exists.
     * Otherwise, the existing `Cart` is returned.
     *
     * @param restaurant
     */
    public getCart(restaurant: RestaurantEntry) {
        if (restaurant?.hasPayment) {
            const cartId = getCartId(restaurant);
            let cart = this._carts.get(cartId);

            if (!cart) {
                let paymentOption: PaymentOption = 'paypal';

                cart = new Cart(paymentOption, restaurant, this._api);
                this._carts.set(cartId, cart);
            }

            return cart;
        }
    }

    public setCart(cart: Cart) {
        const cartId = getCartId(cart.restaurant);

        this._carts.get(cartId)?.release();
        this._carts.set(cartId, cart);
    }

    public get carts() {
        return this._carts;
    }

    public resetCart(restaurant: RestaurantEntry) {
        const cartId = getCartId(restaurant);

        this._carts.get(cartId)?.release();
        this._carts.delete(cartId); // triggers update more reliably than just re-setting the map entry
        this.getCart(restaurant); // needed to set a new empty cart
    }

    public async order(restaurant: RestaurantEntry) {
        const cart = this.getCart(restaurant);

        try {
            logOrder('purchase', cart, undefined, {
                PAYMENT_TYPE: cart?.paymentOption?.toUpperCase(),
                isTakeAway: !!cart?.isTakeAway,
                withMeetUp: !!cart?.meetUp
            });

            assert(restaurant?.key, 'Restaurant undefined');
            assert(cart?.isValid, 'Cart invalid');

            const order = cart!.createOrder();
            let redirectUrl: string | undefined;

            cart!.stopSynchronization();

            const response = await HttpRequest.post('/api/payment/orders', this.serializeOrder(order as Order));
            const paymentResponse = JSON.parse(response) as PayPalOrderResponse;

            if (order.paymentOption === 'paypal') {
                redirectUrl = (paymentResponse as PayPalOrderResponse).links?.find(({ rel }) => rel === 'approve')?.href;
            }

            if (redirectUrl) {
                return redirectUrl;
            } else {
                const newOrder = await this.loadOrder('payInId', paymentResponse.Id);

                assert(newOrder, Env.i18n.t('ErrorPayment_OrderNotFound'));

                if (newOrder!.restaurant) {
                    this.resetCart(newOrder!.restaurant);
                }

                return newOrder!;
            }
        } catch (error) {
            cart?.startSynchronization();
            Env.alert(Env.i18n.t('ErrorPayment_AlertTitle'), paymentError(error));
        }
    }

    public async saveRating(order: Order, rating: OrderRating) {
        try {
            await HttpRequest.post(`/api/payment/orders/${order.key}`, { rating })
        } catch (error) {
            return Promise.reject(paymentError(error));
        }
    }

    public async finishExternalTransaction({ token, PayerID }: ExternalTransactionParams) {
        const payInId = token;

        if (payInId) {
            try {
                const order = await this.loadOrder('payInId', payInId);
                let success = false;

                assert(order, Env.i18n.t('ErrorPayment_OrderNotFound'));
                assert(order!.restaurant, Env.i18n.t('ErrorPayment_RestaurantUndefined'));

                if (order!.paymentOption === 'paypal' && token && PayerID) {
                    await this.finishPayPalPaymentTransaction(token);
                    success = true;
                }

                if (success) {
                    if (order!.restaurant) {
                        this.resetCart(order!.restaurant);
                    }

                    return order;
                }
            } catch (error) {
                Env.alert(Env.i18n.t('ErrorPayment_AlertTitle'), paymentError(error));
            }
        }
    }
}
