import { Button as MaterialButton } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { bind } from 'decko';
import { computed, observable } from 'mobx';
import { inject, Observer, observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import { InjectedChatsProps } from '../../../../lib/src/managers/ChatsManager';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import colors from '../../../../lib/src/styles/colors';
import statusConfig from '../../../../lib/src/styles/statusConfig';
import { ContactPerson, ContactStatus } from '../../../../lib/src/types/models/ContactEntity';
import EnhancedChatMessage from '../../../../lib/src/types/models/EnhancedChatMessage';
import { CANCELATION_DEADLINE_MINUTES } from '../../../../lib/src/types/models/Invitation';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import UserInvitation, { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import Timestamps from '../../../../lib/src/types/Timestamps';
import { InjectedApiProps } from '../../Api';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import ToEndButton from '../button/ToEndButton';
import EmptyListIndicator from '../common/EmptyListIndicator';
import { DiskButton } from '../common/IconButton';
import LoadingIndicator from '../common/LoadingIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import NewScreenHeader from '../common/NewScreenHeader';
import Screen from '../common/Screen';
import Input from '../forms/Input';
import InviteDatePicker from '../invitations/InviteDatePicker';
import { Badge, RegularText } from '../text';

const ChatMessageWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow: auto;
    padding-bottom: ${GRID_SIZE}px;
`;

const ChatMessageRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChatMessageBox = styled.div<{ isOwn?: boolean }>`
    align-items: ${props => props.isOwn ? 'flex-end' : 'flex-start'};
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: ${GRID_SIZE}px;
`;

const ChatMessageBackground = styled.div`
    background-color: ${colors.white};
    border-radius: ${GRID_SIZE}px;
    max-width: 80%;
    position: relative;
`;

// @ts-ignore ts(2615)
const Timestamp = styled(RegularText)`
    color: ${colors.description_grey};
    font-size: 10px;
    margin-top: ${GRID_SIZE / 2}px;
`;

const NewMessageIndicator = styled.div`
    background-color: ${colors.red_500};
    border-radius: ${GRID_SIZE}px;
    height: ${GRID_SIZE * 2}px;
    position: absolute;
    right: 0;
    top: 0;
    width: ${GRID_SIZE * 2}px;
`;

const InputContainer = styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE}px;
`;

const SubmitButton = styled(DiskButton).attrs({
    icon: require('../../assets/svg/send.svg'),
    diskSize: 32,
    size: 18,
    color: colors.white
})<{ active?: boolean }>`
    background-color: ${props => props.active ? colors.teal_300 : colors.teal_50};
    margin-left: ${GRID_SIZE}px;
    margin-top: ${GRID_SIZE * 1.5}px;
`;

const LogoContainer = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid ${colors.light_border};
    border-radius: 25px;
    flex-shrink: 0;
    height: 50px;
    margin: ${GRID_SIZE * 2}px;
    margin-right: 10px;
    width: 50px;
`;

const RestaurantNameText = styled(RegularText)`
    color: ${colors.navy_blue};
    font-size: 16px;
    font-weight: 600;
`;

const CuisineText = styled(RegularText)`
    font-weight: 300;
`;

const InvitationStatus = styled.div`
    background: rgba(255, 255, 255, 0.85);
    bottom: 0;
    font-size: 14px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: ${colors.light_border};
    margin: -0.5px;
    overflow: hidden;
    padding-bottom: 1px;
`;

const Button = styled(MaterialButton)`
    &&& {
        background: ${colors.white};
        border-bottom-width: 1px;
        border-color: ${colors.light_border};
        border-left-width: 1px;
        border-radius: 0;
        border-style: solid;
        flex: 1;
        margin-top: 0;
        min-width: 50%;

        &:first-child {
            border-left-width: 0;
        }
    }
`;

interface Params {
    chatKey?: string;
}

interface State extends ModalState<Params> {
    newMessages: boolean;
    newMessagesFromOthers: boolean;
    showScrollButton: boolean;
    lastSeenMessageKey?: string;
}

@inject('api', 'chats', 'invitationDraft', 'payment')
@observer
export default class ChatMessagesScreen extends Modal<Params, State> {
    public readonly state: State = {
        newMessages: false,
        newMessagesFromOthers: false,
        showScrollButton: false,
        params: {}
    };

    private static CHAT_ID = 'chat-messages';

    @observable
    private message = '';

    private chatInputRef = React.createRef<Input>();
    private datePickerRef = React.createRef<InviteDatePicker>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedChatsProps & InjectedInvitationDraftProps & InjectedPaymentProps;
    }

    public componentWillUnmount() {
        this.injected.chats.closeChat();
    }

    public render() {
        const chat = this.injected.chats.currentChat;
        const messages = chat?.list.slice().reverse() || [];
        const backgroundColor = messages.length ? colors.light_background : colors.white;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                fullHeight={true}
                HeaderComponent={this.renderHeader}
                FooterComponent={this.renderFooter}
                contentStyle={{ backgroundColor, padding: 0 }}
            >
                {/* TODO: use DynamicList */}
                <ChatMessageWrapper onScroll={this.checkScrollPosition} id={ChatMessagesScreen.CHAT_ID}>
                    {messages.length ? (
                        <>
                            {/* not using FlatList because of variable item height */}
                            {messages.slice().reverse().map(this.renderChatMessage)}
                        </>
                    ) : (
                        <EmptyListIndicator
                            waitFor={Promise.resolve()}
                            icon={require('../../assets/svg/empty_state_user.svg')}
                            hint={Env.i18n.t('NoChatMessages')}
                        />
                    )}
                </ChatMessageWrapper>
                {(messages.length > 0) && this.state.showScrollButton && (
                    <ToEndButton style={{ bottom: 10, right: 10 }} onPress={this.scrollToBottom} direction="down">
                        {(messages[0].key !== this.state.lastSeenMessageKey) && (
                            <NewMessageIndicator />
                        )}
                    </ToEndButton>
                )}
                <InviteDatePicker ref={this.datePickerRef} onConfirm={this.applySelectionAndContinue} />
            </Screen>
        );
    }

    protected async hydrateParams(params: string[]) {
        const { chats } = this.injected;
        const chatKey = params[0];

        if (chatKey && chatKey !== chats.currentChat?.key) {
            await chats.getChat(chatKey);
        }

        return { chatKey };
    }

    protected validateParams(params: Params) {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    @computed
    private get renderHeader() {
        return (
            <NewScreenHeader title={this.injected.chats.currentChat?.name || ''} onBack={this.back} />
        );
    }

    @computed
    private get renderFooter() {
        return (
            <InputContainer>
                <Input
                    ref={this.chatInputRef}
                    placeholder={Env.i18n.t('YourMessage')}
                    sanitizeValue={false}
                    onChange={this.storeMessage}
                    onSubmitEditing={this.sendMessage}
                    maxLength={100}
                    style={{ flex: 1 }}
                />
                <SubmitButton onClick={this.sendMessage} active={this.message.length > 0} />
            </InputContainer>
        );
    }

    @bind
    private renderRestaurantCard(item: EnhancedChatMessage) {
        const { restaurant, invitation, order } = item;

        if (!restaurant) {
            return null;
        }

        const { api } = this.injected;
        const cuisine = restaurant.getCuisine(api.tags);
        const firstGalleryImageUrl = restaurant.media.find(({ type }) => type === 'image')?.url;
        const statusConfigData = invitation && statusConfig(invitation.status || 'pending');
        const buttons: Array<{
            contentColor?: string;
            onPress: () => void;
            label: string;
            icon?: React.ReactNode;
        }> = [];

        if (!invitation) {
            buttons.push({
                label: Env.i18n.t('ArrangeMeetup'),
                onPress: () => this.arrangeMeetup(restaurant)
            });
        } else if (!isInvitationExpired(invitation)) {
            const { status, attendees = [], date, isOwn, reminderSent } = invitation;
            const userStatus = api.account.getMyStatus(attendees);

            if ((userStatus === 'accepted') && (status === 'accepted') && restaurant.hasPayment && restaurant.isStillOpenToday) {
                buttons.push({
                    label: Env.i18n.t(order ? 'ShowOrder' : 'DoOrder'),
                    contentColor: colors.accepted,
                    onPress: () => order ? this.showOrder(item) : this.addOrder(invitation, restaurant)
                });
            }

            if ((userStatus !== 'declined') && (!status || (status === 'accepted'))) {
                if (userStatus === 'pending') {
                    buttons.push({
                        label: Env.i18n.t('Accept'),
                        contentColor: colors.accepted,
                        onPress: () => this.updateStatus(item, 'accepted')
                    });
                }

                if (!order && !moment().add(CANCELATION_DEADLINE_MINUTES, 'minutes').isAfter(Timestamps.toDate(date))) {
                    buttons.push({
                        label: Env.i18n.t('Decline'),
                        contentColor: colors.declined,
                        onPress: () => this.updateStatus(item, 'declined')
                    });
                }
            }

            if (!reminderSent && isOwn && status === 'accepted') {
                const userId = api.account.user?.uid;
                const hasOtherAttendeesWithoutOrder = attendees.some(({ uid, hasOrdered }) => uid !== userId && !hasOrdered);

                if (hasOtherAttendeesWithoutOrder) {
                    buttons.push({
                        label: Env.i18n.t('SendReminder'),
                        icon: <NotificationsActive style={{ fontSize: 16, marginRight: GRID_SIZE / 2 }} />,
                        contentColor: colors.gold,
                        onPress: () => this.remindOfInvitation(item)
                    });
                }
            }
        } else if (order) {
            buttons.push({
                label: Env.i18n.t('ShowOrder'),
                contentColor: colors.accepted,
                onPress: () => this.showOrder(item)
            });
        }

        return (
            <div>
                <div style={{ cursor: 'pointer', width: '100%', lineHeight: 0 }} onClick={() => { this.onRestaurantPress(restaurant) }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {!!restaurant?.logo?.url && (
                            <LogoContainer style={{ backgroundImage: `url(${restaurant.logo.url})` }} />
                        )}
                        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}>
                            <RestaurantNameText>
                                {restaurant.name}
                            </RestaurantNameText>
                            {!!cuisine && (
                                <CuisineText>
                                    {cuisine?.translations[Env.currentLanguageCode()] || cuisine?.name}
                                </CuisineText>
                            )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: GRID_SIZE, marginRight: GRID_SIZE }}>
                            <KeyboardArrowRight style={{ fontSize: '32px', color: colors.indigo }} />
                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        {firstGalleryImageUrl && (
                            <img src={firstGalleryImageUrl} style={{ maxWidth: '100%' }} />
                        )}

                        {!!item.invitation?.date && (
                            <InvitationStatus>
                                {!!statusConfigData && (
                                    <Badge style={{ position: 'absolute', backgroundColor: statusConfigData.diskColor || colors.grey_01 }}>
                                        {statusConfigData.text}
                                    </Badge>
                                )}
                                {formatDayAndTime(Timestamps.toDate(item.invitation.date))}
                            </InvitationStatus>
                        )}
                    </div>
                </div>
                <Buttons>
                    {buttons.map((button, key) => (
                        <Button key={key} onClick={button.onPress}>
                            <span style={{ color: button.contentColor || 'inherit' }}>
                                {button.icon}
                                {button.label}
                            </span>
                        </Button>
                    ))}
                </Buttons>
            </div>
        )
    }

    @bind
    private renderChatMessage(item: EnhancedChatMessage) {
        const { key, timestamp, isOwn, content } = item;
        const messageTimestampMoment = moment(timestamp);
        const isToday = messageTimestampMoment.isSame(moment(), 'day');
        const timeFormat = isToday ? Env.i18n.t('TimeFormat') : `${Env.i18n.t('DayFormat')}, ${Env.i18n.t('TimeFormat')}`;

        return (
            <ChatMessageRow key={key}>
                <Observer>
                    {() => (
                        // Note: Don't dereference observables outside this function!
                        <ChatMessageBox isOwn={isOwn}>
                            <ChatMessageBackground>
                                {item.restaurant ? (
                                    this.renderRestaurantCard(item)
                                ) : (
                                    <RegularText style={{ padding: GRID_SIZE }}>
                                        {item.loading ? ' ' : content === 'invitation' ? Env.i18n.t('SharedInvitationRemoved') : content}
                                    </RegularText>
                                )}
                                {item.loading && (
                                    <LoadingIndicator position="absolute" />
                                )}
                            </ChatMessageBackground>
                            <Timestamp>
                                {messageTimestampMoment.format(timeFormat)}
                            </Timestamp>
                        </ChatMessageBox>
                    )}
                </Observer>
            </ChatMessageRow>
        );
    }

    @bind
    private onRestaurantPress(restaurant: RestaurantEntry) {
        this.redirectTo('details', [restaurant.routingName || '']);
    }

    @bind
    private arrangeMeetup(restaurant: RestaurantEntry) {
        const { chats, invitationDraft } = this.injected;
        const chat = chats.currentChat;

        if (chat) {
            invitationDraft.create(true);
            invitationDraft.restaurant = restaurant;
            // invite all members of the current chat to the invitation
            invitationDraft.setAttendees(Object.entries(chat.participants).map(([ key, { name, photoURL } ]) =>
                new ContactPerson(key, name, photoURL)
            ));

            this.datePickerRef.current?.open();
        }
    }

    @bind
    private async applySelectionAndContinue() {
        this.redirectTo('invitationdraft');
    }

    @bind
    private async updateStatus(message: EnhancedChatMessage, status: ContactStatus) {
        if (!message.invitation) {
            return;
        }

        if (status === 'declined') {
            const confirmed = await new Promise((resolve) => {
                Env.alert(
                    Env.i18n.t('Decline'),
                    Env.i18n.t('ConfirmInvitationDenial'),
                    [
                        {
                            label: Env.i18n.t('Ok'),
                            action: () => resolve(true)
                        },
                        {
                            label: Env.i18n.t('Cancel'),
                            action: () => resolve(false)
                        }
                    ]
                );
            });

            if (!confirmed) {
                return;
            }
        }

        message.waitFor(Backend.updateInvitationStatus(message.invitation.key, status))
            .catch(() => Env.snackbar.error(Env.i18n.t('ErrorChangeAttendance')));
    }

    @bind
    private async remindOfInvitation(message: EnhancedChatMessage) {
        if (message.invitation) {
            await message.waitFor(Backend.remindOfInvitation(message.invitation.key));
        }
    }

    @bind
    private clearChatInput() {
        this.chatInputRef.current?.setValue('');
        this.message = '';
    }

    @bind
    private storeMessage(event: ChangeEvent<HTMLInputElement>) {
        this.message = event.target.value;
    }

    @bind
    private async sendMessage() {
        const chat = this.injected.chats.currentChat;

        if (chat && this.message) {
            await chat.sendMessage(this.message);
            this.clearChatInput();
            this.scrollToBottom();
        }
    }

    @bind
    private scrollToBottom() {
        animateScroll.scrollToBottom({
            containerId: ChatMessagesScreen.CHAT_ID,
            duration: 300
        });
    }

    @bind
    private checkScrollPosition(event: React.UIEvent<HTMLDivElement>) {
        const chat = this.injected.chats.currentChat;
        const element = event.currentTarget;
        const bottomReached = (element.scrollTop >= 0);
        const topReached = (element.scrollHeight - (element.scrollTop * -1) - element.clientHeight <= 0);
        const showScrollButton = !bottomReached;

        if (topReached) {
            chat?.loadMore();
        }

        this.setState(({ newMessages, newMessagesFromOthers, lastSeenMessageKey}) => ({
            showScrollButton,
            newMessages: showScrollButton && newMessages,
            newMessagesFromOthers: showScrollButton && newMessagesFromOthers,
            lastSeenMessageKey: (bottomReached || !lastSeenMessageKey) ? chat?.list.slice(-1)[0]?.key : lastSeenMessageKey
        }));
    }

    @bind
    private async addOrder(invitation: UserInvitation, restaurant: RestaurantEntry) {
        if (restaurant) {
            const cart = this.injected.payment.getCart(restaurant);

            cart?.setMeetUp(invitation);
            this.redirectTo('details', [ restaurant?.routingName || '' ]);
        }
    }

    @bind
    private async showOrder(chatMessage: EnhancedChatMessage) {
        if (chatMessage.order) {
            this.redirectTo('order', [ chatMessage.order.key ]);
        }
    }
}
