import { SvgIconProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const BUTTON_ICON_SIZE = 24;

export type IconType = string | React.ComponentType<SvgIconProps>;

interface IconBaseProps {
    color?: string
}

interface ImageIconProps extends IconBaseProps {
    src: string;
}

const ImageIcon = styled.span<ImageIconProps>`
    background-color: ${props => props.color || 'currentColor'};
    display: inline-block;
    mask-image: url(${props => props.src});
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    vertical-align: middle;
`;

interface IconProps extends IconBaseProps {
    src: IconType;
    size?: number;
    height?: number;
    width?: number;
    onClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
    className?: string;
    style?: React.CSSProperties;
}

const Icon = (props: IconProps) => {
    const { src: IconComponent, height, width, size, color, style, ...commonProps } = props;
    const finalStyle = {
        height: height || size || BUTTON_ICON_SIZE,
        verticalAlign: 'middle',
        width: width || size || BUTTON_ICON_SIZE,
        ...style
    };

    return typeof IconComponent === 'string' ? (
        <ImageIcon src={IconComponent} color={color} style={finalStyle} {...commonProps} />
    ) : (
        <IconComponent htmlColor={color} style={finalStyle} {...commonProps} />
    );
};

export default Icon;
