import { Button, Fab } from '@material-ui/core';
import { ButtonTypeMap } from '@material-ui/core/Button';
import styled, {css} from 'styled-components';
import { GRID_SIZE } from '../../styles/base';

// TODO: rather use theme where possible?
/**
 * The `&&& { ... }` notation increases CSS specificy,
 * so the material styles are overridden
 */

export const PrimaryButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary'
})`
    &&& {
        border-radius: ${GRID_SIZE / 2}px;
        padding: 2%;
        width: 100%;
    }
`;

const smallButtonAttrs: ButtonTypeMap['props'] = {
    size: 'small'
};

const smallButtonStyle = css`
    &&& {
        padding: 1% 2%;
        text-transform: none;
        width: auto;
    }
`;

// @ts-ignore ts(2615)
export const PrimarySmallButton = styled(PrimaryButton).attrs(smallButtonAttrs)`
    ${smallButtonStyle};
`;

// @ts-ignore ts(2615)
export const SecondaryButton = styled(PrimaryButton).attrs({ color: 'secondary' })``;

export const SecondarySmallButton = styled(SecondaryButton).attrs(smallButtonAttrs)`
    ${smallButtonStyle};
`;

export const PrimaryFab = styled(Fab).attrs({
    color: 'primary',
    size: 'large'
})`
    &&& {
        bottom: 0;
        position: absolute;
        right: 0;
    }
`;