import HttpRequest from '../../helpers/HttpRequest';
import List from '../List';
import ContactEntity, { ContactPerson } from './ContactEntity';

export interface GroupMember {
    name: string;
    photoURL?: string;
}

// TODO: also refactor invitations to implement this (see LU-1048)
export interface Shared {
    participants: List<GroupMember>;
    participantKeys: string[];
    participantKeysStr: string;
}

export interface GroupData extends Shared {
    name?: string;
}

export default class Group extends ContactEntity implements GroupData {
    private user: ContactPerson;
    private data: Partial<GroupData>;

    public readonly key: string;

    public get otherParticipants() {
        const { [this.user.key]: me, ...others } = this.participants;

        return others;
    }

    public get name() {
        return this.data.name || Group.defaultName(this.otherParticipants);
    }

    public get participants() {
        return this.data.participants || {};
    }

    public get participantKeysStr() {
        return Group.participantKeysToString(this.participantKeys);
    }

    public get participantKeys() {
        return Object.keys(this.participants);
    }

    constructor(user: ContactPerson, key: string, data: Partial<GroupData>) {
        super();
        this.user = user;
        this.data = data;
        this.key = key;
    }

    public static defaultName(participants: GroupMember[] | List<GroupMember>) {
        return Object.values(participants).map(({ name }) => name.split(' ')[0]).join(', ');
    }

    public static participantKeysToString(participantKeys: string[]) {
        return participantKeys.slice().sort().join('-');
    }

    public async edit(name: string): Promise<Group>;
    public async edit(name: string, ...addMembers: ContactPerson[]): Promise<Group>;
    public async edit(...addMembers: ContactPerson[]): Promise<Group>;
    public async edit(nameOrMember: string | ContactPerson, ...addMembers: ContactPerson[]) {
        const { participantKeys } = this;
        const name = (typeof nameOrMember === 'string' && nameOrMember !== this.name) ? nameOrMember : undefined;
        const addParticipantKeys = addMembers
            .concat(nameOrMember instanceof ContactPerson ? [ nameOrMember ] : [])
            .filter(({ key }) => !participantKeys.includes(key));

        if (name || addParticipantKeys.length) {
            await HttpRequest.patch(`/api/group/${this.key}`, { addParticipantKeys, name });
        }

        return this;
    }

    public async leave() {
        await HttpRequest.delete(`/api/group/${this.key}`);
    }

    /** Does not include the current user, analogous to `otherParticipants` */
    public toPersons() {
        return Object.entries(this.otherParticipants).map(([ key, { name, photoURL } ]) => new ContactPerson(key, name, photoURL));
    }
}
