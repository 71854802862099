import './App.css';

import MomentUtils from '@date-io/moment';
import { AccessTime, Apple, Check, FilterNone, NewReleasesOutlined, Restaurant, Wifi } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import copy from 'clipboard-copy';
import { bind } from 'decko';
import firebase from 'firebase';
import { observer, Provider } from 'mobx-react';
import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Constants from '../../lib/src/Constants';
import Env, { Assets, Info } from '../../lib/src/Env';
import { deeplinkRoute } from '../../lib/src/helpers/url';
import ChatsManager from '../../lib/src/managers/ChatsManager';
import GroupsManager from '../../lib/src/managers/GroupsManager';
import InvitationDraftManager from '../../lib/src/managers/InvitationDraftManager';
import Action from '../../lib/src/types/Action';
import List from '../../lib/src/types/List';
import Account from './Account';
import Api from './Api';
import Auth from './Auth';
import GlobalAlert, { AlertProps } from './components/common/GlobalAlert';
import GlobalIndicator from './components/common/GlobalIndicator';
import GlobalSnackbar from './components/common/GlobalSnackbar';
import LoadingIndicator from './components/common/LoadingIndicator';
import GlobalNotification from './components/notifications/GlobalNotification';
import Restaurants from './components/restaurants/Restaurants';
import { RegularText } from './components/text';
import i18n from './helpers/I18n';
import Payment from './Payment';
import { GRID_SIZE, InteractiveArea } from './styles/base';
import { theme } from './theme';

const snackbarRef = React.createRef<GlobalSnackbar>();
const alertRef = React.createRef<GlobalAlert>();
const cachedAlerts: AlertProps[] = [];

function alertTrigger(title: string, message?: any, actions: Action[] = [], cancelable = true) {
    const children = message as React.ReactNode;
    const config = { title, children, actions, cancelable };

    if (alertRef.current) {
        alertRef.current.alert(config);
    } else {
        cachedAlerts.push(config);
    }
}

const info: Info = {
    version: Constants.WEB_VERSION,
    bundleId: (() => {
        // TODO: let's find a more reliable method!
        if (window.location.hostname.match(/\b(beta)\b/i)) {
            return 'com.lunchnow.LunchNowCustomer.beta';
        } else if (window.location.hostname.match(/\b(dev|development|localhost)\b|^192\.168\.178\./i)) {
            return 'com.lunchnow.customer.dev';
        } else {
            return 'com.lunchnow.LunchNowCustomer';
        }
    })()
};

const assets: Assets = {
    fallbackImage: require('./assets/png/placeholder_image.png'),
    icons: {
        AmEx: require('./assets/svg/ic_pay_amex.svg'),
        ApplePay: Apple,
        BadgeCheck: Check,  // TODO use Material Icon `CheckCircle` instead?
        BadgeDeclined: require('./assets/svg/badge_declined.svg'), // TODO use Material Icon `Cancel` instead?
        BadgeRequest: require('./assets/svg/badge_request.svg'), // TODO use Material Icon `Help` instead?
        CashPayment: require('./assets/svg/cashPayment.svg'),
        DinersClub: require('./assets/svg/ic_pay_dinersclub.svg'),
        GooglePay: require('./assets/svg/ic_pay_googlepay.svg'),
        EcCard: require('./assets/svg/ic_leading_eccard.svg'),
        Mastercard: require('./assets/svg/ic_pay_mastercard.svg'),
        AccessTime,
        Offer: NewReleasesOutlined,
        PayPal: require('./assets/svg/ic_pay_paypal.svg'),
        PreorderRestaurant: Restaurant,
        Visa: require('./assets/svg/ic_pay_visa.svg'),
        Wifi,
    }
};

function logger(eventName: string, eventParams?: List<any>) {
    if (Constants.FIREBASE_CONFIG.measurementId) {
        Env.firebase.analytics().logEvent(eventName, eventParams);
    }
}

function sharer(message: string) {
    return new Promise<void>(resolve =>
        // timeout to avoid showing alert just before redirect, see LU-204 (cleaner solution would diverge from mobile app)
        window.setTimeout(
            () => {
                Env.alert(
                    'Schicke folgenden Text an alle, die du einladen möchtest:', // TODO: localize
                    (
                        <InteractiveArea style={{ marginTop: GRID_SIZE * 1.5 }}>
                            <RegularText>
                                {message}
                            </RegularText>
                        </InteractiveArea>
                    ),
                    [{
                        icon: FilterNone,
                        label: 'In die Zwischenablage kopieren',
                        action: () => void copy(message).then(() => Env.snackbar.success('Text wurde in die Zwischenablage kopiert'))
                    }]
                );
                resolve();
            },
            1000
        )
    );
}

function snackbarTrigger(title: string, color: string, action?: Action) {
    snackbarRef.current?.show(title, color);
}

const envReady = Env.init({ info, firebase, i18n, assets, sharer, logger, snackbarTrigger, alertTrigger });

// must be done after `Env.init()` and before `new App()`
const defaultFirebase = firebase.apps.find((app) => app.name === '[DEFAULT]');

if (!defaultFirebase) {
    Env.firebase.initializeApp(Constants.FIREBASE_CONFIG);
}

Env.initAnalytics();

@observer
export default class App extends React.Component {
    private account = new Account(envReady);
    private auth = new Auth(this.account);
    private api = new Api(this.account, Promise.all([ this.auth.awaitSignIn, envReady ]));
    private invitationDraft = new InvitationDraftManager(this.api);
    private payment = new Payment(this.api);
    private groups = new GroupsManager(this.api);
    private chats = new ChatsManager(this.api, this.groups);

    public componentDidMount() {
        // Doing this as a redirect with <Redirect /> doesn't work when it contains characters like "()" in the fromUser parameter
        // TODO: we should refactor the url handling, create the correct links from the app and this redirect should not exist anymore.
        if (window.location.pathname.indexOf('/search/') === 0) {
            window.location.href = `/restaurants#${deeplinkRoute()}`;
        }

        window.addEventListener('beforeunload', this.confirmPageReload);
    }

    @bind
    private confirmPageReload(e: BeforeUnloadEvent) {
        const event = e || window.event;

        if (Array.from(this.payment.carts.values()).some(cart => cart.items.length > 0) && !this.payment.externalRedirect) {
            event.returnValue = '';
        }
    }

    public render() {
        return this.api.isInitialized
            ? (
                <ThemeProvider theme={theme}>
                    <Provider
                        api={this.api}
                        account={this.account}
                        auth={this.auth}
                        invitationDraft={this.invitationDraft}
                        payment={this.payment}
                        groups={this.groups}
                        chats={this.chats}
                    >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            {this.api.isAccessible && (
                                <Router>
                                    <Switch>
                                        <Route exact path="/empty" component={() => null} />
                                        <Redirect from="/restaurants/:routingName" to="/restaurants#details/:routingName" />
                                        <Route exact path="/restaurants" component={Restaurants} />
                                        <Route path="/:placeId" component={Restaurants} />
                                        <Redirect from="/" to="/restaurants" />
                                    </Switch>
                                </Router>
                            )}
                            <GlobalSnackbar ref={snackbarRef} />
                            <GlobalAlert ref={alertRef} alerts={cachedAlerts} />
                            <GlobalIndicator />
                            <GlobalNotification />
                        </MuiPickersUtilsProvider>
                    </Provider>
                </ThemeProvider>
            )
            : (
                <LoadingIndicator />
            );
    }
}
