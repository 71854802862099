import Env from '../../Env';
import { DataListEntry } from '../../store/DataList';
import colors, { avatarColors } from '../../styles/colors';
import { InvitationStatus } from './Invitation';
import { ContactUser } from './User';

export type ContactStatus = 'pending' | InvitationStatus;

export function compareContactEntites(entity1: ContactEntity, entity2: ContactEntity) {
    const myUid = Env.firebase.auth().currentUser?.uid;
    const byIdentity = Number(entity2.key === myUid) - Number(entity1.key === myUid);

    return byIdentity || entity1.name.localeCompare(entity2.name);
}

export function flattenContacts(contacts: ContactEntity[]) {
    return new Array<ContactPerson>().concat(...contacts.map(contact => contact.toPersons()));
}

export default abstract class ContactEntity implements DataListEntry {
    public abstract get key(): string;
    public abstract get name(): string;
    public abstract toPersons(): ContactPerson[];
}

export class ContactPerson extends ContactEntity implements Omit<ContactUser, 'displayName'> {
    public readonly key: string;
    public readonly name: string;
    public readonly shortName: string;
    public readonly photoURL?: string;
    public readonly status?: ContactStatus;

    constructor(key: string, displayName: string, photoURL?: string, status?: ContactStatus) {
        super();

        const myUid = Env.firebase.auth().currentUser?.uid;

        this.key = key;
        this.name = displayName;
        this.shortName = (key === myUid) ? Env.i18n.t('You') : displayName.split(' ')[0];
        this.photoURL = photoURL;
        this.status = status;
    }

    public static createDeleted() {
        const key = Math.random().toString();
        const name = Env.i18n.t('DeletedUser');

        return new this(key, name, undefined, 'deleted');
    }

    public static getInitialsForName(name: string) {
        return name
            .split(/\s+/u)
            .map(name => name.charAt(0))
            // remove non-letter characters (only regarding code-points up to \u00FF)
            .filter(letter => /[^\u0000-\u0040\u005B-\u0060\u007B-\u00BF]/u.test(letter))
            .slice(0, 2)
            .join('')
            .toLocaleUpperCase();
    }

    public static getColorForName(name?: string) {
        if (name?.length) {
            // see https://stackoverflow.com/a/8831937/4483389
            const hashCode = Array.from(name!).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);

            return avatarColors[Math.abs(hashCode) % avatarColors.length];
        } else {
            return colors.grey_05;
        }
    }

    /** Creates a new instance */
    public withStatus(status: ContactStatus) {
        return new ContactPerson(this.key, this.name, this.photoURL, status);
    }

    public toPersons() {
        return [ this ];
    }
}
