import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import Arrays from '../../../../lib/src/helpers/Arrays';
import colors from '../../../../lib/src/styles/colors';
import ContactEntity, { flattenContacts } from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { CheckBoxMarker } from '../forms/Checkbox';
import { RegularText } from '../text';
import { ContactChips } from './ContactEntry';
import { PromiseHandler } from './ContactList';
import FilteredContactList, { FilteredContactListProps } from './FilteredContactList';

export class ContactSelectionCounter extends React.PureComponent<State<any> & { max: number }> {
    public render() {
        const { selection, max, invalid } = this.props;

        return (
            <RegularText style={invalid ? { color: colors.warn } : undefined}>
                {selection.length}/{max}
            </RegularText>
        );
    }
}

// @ts-ignore ts(2615)
const SelectionMarker = styled(CheckBoxMarker).attrs({
    checked: true
})`
    margin: 0 ${GRID_SIZE}px;
`;

interface Props<T extends ContactEntity> extends Omit<FilteredContactListProps<T>, 'renderItemContent' | 'onItemPress'> {
    selectionPlaceholder?: string;
    selected?: T[];
    minSelection?: number;
    maxSelection?: number;
    ListCounterComponent?: (state: State<T>) => (React.ReactElement<any> | undefined);
}

interface State<T> {
    selection: T[];
    maximumSelected?: boolean;
    invalid?: boolean;
}

export default class ContactSelectionList<T extends ContactEntity> extends React.PureComponent<Props<T>, State<T>> {
    public readonly state: State<T> = {
        selection: []
    };

    private contactListRef = React.createRef<FilteredContactList<T>>();

    public readonly waitFor: PromiseHandler<T> = async (...args) => {
        this.contactListRef.current?.waitFor(...args);
    };

    @bind
    public getSelection() {
        return this.state.selection.slice();
    }

    public componentDidMount() {
        this.initializeSelection();
    }

    public componentDidUpdate(prevProps: Props<T>) {
        const { selected } = this.props;

        if (selected && (!prevProps.selected || !Arrays.isEqual(prevProps.selected, selected))) {
            this.initializeSelection();
        }
    }

    public render() {
        const { selectionPlaceholder, selected, disableItems, minSelection, maxSelection, ListCounterComponent, ...listProps } = this.props;
        const { selection, maximumSelected } = this.state;
        let allDisabledItems = maximumSelected
            ? listProps.contacts?.filter(item => !this.isSelected(item)).map(({ key }) => key)
            : undefined;

        if (disableItems) {
            allDisabledItems = disableItems.concat(allDisabledItems!);
        }

        return (
            <>
                {ListCounterComponent && ListCounterComponent(this.state)}
                <div style={{ padding: `${GRID_SIZE * 2}px ${SCREEN_PADDING}px 0` }}>
                    <ContactChips contacts={flattenContacts(selection)} emptyText={selectionPlaceholder} />
                </div>
                <FilteredContactList
                    {...listProps}
                    ref={this.contactListRef}
                    renderItemContent={this.renderItemContent}
                    onItemPress={this.toggleContactSelection}
                    disableItems={allDisabledItems}
                    dependsOn={selection}
                />
            </>
        )
    }

    @bind
    private renderItemContent(item: T) {
        return this.isSelected(item) && (
            <SelectionMarker />
        );
    }

    private isSelected(item: T, inSelection = this.state.selection) {
        return inSelection.some(({ key }) => key === item.key);
    }

    private initializeSelection() {
        const { selected, maxSelection } = this.props;

        if (selected) {
            const selection = selected.slice(0, maxSelection);

            this.setState({ selection, ...this.validateSelection(selected) });
        }
    }

    @bind
    private toggleContactSelection(contact: T) {
        this.setState(({ selection }) => ({ selection, ...this.validateSelection(Arrays.toggle(selection.slice(), contact)) }));
    }

    private validateSelection(selection: T[]) {
        const { minSelection, maxSelection } = this.props;
        const newState: Partial<State<T>> = {
            invalid: false,
            maximumSelected: false
        };

        if (maxSelection && selection.length >= maxSelection) {
            newState.invalid = true;
            newState.maximumSelected = true;
        }

        if (!maxSelection || selection.length <= maxSelection) {
            newState.invalid = false;
            newState.selection = selection;
        }

        if (minSelection && selection.length < minSelection) {
            newState.invalid = true;
        }

        return newState;
    }
}
