import Phone from '@material-ui/icons/Phone';

import Env from '../../../lib/src/Env';
import InvitationDraftManager from '../../../lib/src/managers/InvitationDraftManager';
import RestaurantEntry from '../../../lib/src/types/models/RestaurantEntry';
import LinkTo from './LinkTo';

// TODO: move to lib (except `confirmPhoneCall`)?
export default {
    notify: (title: string, content: React.ReactNode, cancelable?: boolean, onConfirm?: () => void, buttonLabel?: string) =>
        Env.alert(
            title,
            content,
            [{
                label: buttonLabel || Env.i18n.t('Ok'),
                action: onConfirm
            }],
            cancelable
        ),
    confirm: (title: string, content: React.ReactNode, onDecision: (confirmed: boolean) => void, cancelable?: boolean) =>
        Env.alert(
            title,
            content,
            [
                {
                    label: Env.i18n.t('Yes'),
                    action: () => onDecision(true)
                },
                {
                    label: Env.i18n.t('No'),
                    action: () => onDecision(false)
                }
            ],
            cancelable
        ),
    confirmPhoneCall: (restaurant: RestaurantEntry) => {
        const phoneNumber = restaurant.data?.contact?.phoneNumber || '';

        Env.alert(
            `${restaurant.name} jetzt anrufen`,
            undefined,
            [{
                icon: Phone,
                label: phoneNumber,
                action: () => LinkTo.phoneApp(phoneNumber)
            }]
        );
    },
    confirmInvitationDiscard: (draft: InvitationDraftManager, onDecision: (confirmed: boolean) => void) => {
        if (draft.key) {
            onDecision(true);
        } else {
            Env.alert(
                Env.i18n.t('DiscardInvitation'),
                undefined,
                [
                    {
                        label: Env.i18n.t('Ok'),
                        action: () => onDecision(true)
                    },
                    {
                        label: Env.i18n.t('Cancel'),
                        action: () => onDecision(false)
                    }
                ]
            );
        }
    },
    confirmInvitationTakeAway: (onDecision: (confirmed: boolean) => void) => {
        Env.alert(
            Env.i18n.t('PlaceOfConsumption'),
            Env.i18n.t('ChooseBetween'),
            [
                {
                    label: Env.i18n.t('InStore'),
                    action: () => onDecision(false)
                },
                {
                    label: Env.i18n.t('TakeAway'),
                    action: () => onDecision(true)
                }
            ]
        );
    }
};
