import { action } from 'mobx';

import { ContactPerson } from '../types/models/ContactEntity';
import DataList, { Snapshot } from './DataList';

export default class ContactPersonList extends DataList<ContactPerson> {
    @action
    public addQuerySnapshotChildren(snapshot: Snapshot, override = false) {
        const write = override ? this.set : this.add;

        write.bind(this)(...snapshot.docs.map(doc => {
            const { displayName, photoURL } = doc.data() as any;

            return new ContactPerson(doc.id, displayName, photoURL);
        }));
    }
}
