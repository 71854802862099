import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { InjectedGroupsProps } from '../../../../lib/src/managers/GroupsManager';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import ContactEntity, { flattenContacts } from '../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../lib/src/types/models/Group';
import { InjectedAccountProps } from '../../Account';
import { PrimaryButton } from '../button';
import ScreenModal, { ScreenModalWrapper } from '../common/ScreenModal';
import AddContactsModal from '../contacts/AddContactsModal';
import ContactSelection from '../contacts/ContactSelection';

interface Props {
    onNavigate: (route: string, params?: string[]) => void;
}

@inject('account', 'groups', 'invitationDraft')
@observer
export default class InvitationContactsModal extends ScreenModalWrapper<Props> {
    private contactSelectionRef = React.createRef<ContactSelection>();
    private addContactsModal = React.createRef<AddContactsModal>();

    private get injected() {
        return this.props as Props & InjectedAccountProps & InjectedGroupsProps & InjectedInvitationDraftProps;
    }

    @computed
    private get updating() {
        return !!this.injected.invitationDraft.attendees?.length;
    }

    public render() {
        const { groups, invitationDraft } = this.injected;
        const selectedGroup = invitationDraft.group && groups.groups.list.find(({ key }) => key === invitationDraft.group);

        return (
            <ScreenModal
                ref={this.modalRef}
                title={Env.i18n.t(this.updating ? 'UpdateAttendees' : 'CreateInvitation')}
                FooterComponent={this.renderCta()}
            >
                <ContactSelection
                    ref={this.contactSelectionRef}
                    selected={selectedGroup ? [ selectedGroup ] : invitationDraft.otherAttendees}
                    filterBy={this.isInvitable}
                    counterDescription={Env.i18n.t('SelectAttendeesDescription')}
                />
                <AddContactsModal ref={this.addContactsModal} />
            </ScreenModal>
        );
    }

    @bind
    private renderCta() {
        return (
            <PrimaryButton onClick={this.submit}>
                {Env.i18n.t(this.updating ? 'Save' : 'ContinueToRestaurants')}
            </PrimaryButton>
        );
    }

    @bind
    private submit() {
        const selection: ContactEntity[] = this.contactSelectionRef.current?.getSelection() || [];
        const attendees = (selection.length === 1 && selection[0] instanceof Group) ? selection[0] : flattenContacts(selection);

        if (!this.updating) {
            this.props.onNavigate('invitationrestaurant');
        }

        this.injected.invitationDraft.setAttendees(attendees); // Note: must be called after `this.updating`!
        this.close();
    }

    @bind
    private isInvitable(contact: ContactEntity): boolean {
        const { account, invitationDraft } = this.injected;
        const group = (contact instanceof Group) ? contact : undefined;
        const isInvitableGroup = group?.toPersons().some(this.isInvitable);

        return isInvitableGroup || (
            !group &&
            // not blocking
            !account.isBlockedBy(contact) &&
            // draft or not yet attendee
            !(invitationDraft.key && invitationDraft.attendees?.some(({ key }) => key === contact.key))
        );
    }
}
