import Person from '@material-ui/icons/Person';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import { TitleText } from '../text';

const PROFILE_PICTURE_SIZE = GRID_SIZE * 8;
const PLACEHOLDER_FACTOR = 6 / 8;
const INITIALS_FACTOR = 3 / 8;

interface SizeProp {
    size: number;
}

const ProfileBackground = styled.div<SizeProp>`
    align-items: center;
    background-position: center;
    background-size: cover;
    border-radius: ${({ size }) => size / 2}px;
    display: flex;
    height: ${({ size }) => size}px;
    justify-content: center;
    overflow: hidden;
    width: ${({ size }) => size}px;
`;

const ContactPictureLabel = styled(TitleText)<SizeProp>`
    color: ${colors.white};
    font-size: ${({ size }) => size * INITIALS_FACTOR}px;
    line-height: ${({ size }) => size}px;
    text-align: center;
`;

interface Props extends Partial<SizeProp> {
    uri?: string | null;
    name?: string;
    onPress?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const ProfilePicture: React.FunctionComponent<Props> = ({ uri, name, size = PROFILE_PICTURE_SIZE, onPress, className, style, children }) => {
    const backgroundColor = uri ? 'transparent' : ContactPerson.getColorForName(name);
    const backgroundImage = uri ? `url(${uri})` : 'none';

    return (
        <ProfileBackground size={size} onClick={onPress} className={className} style={{ backgroundColor, backgroundImage, ...style }}>
            {!uri && !!name && (
                <ContactPictureLabel size={size}>
                    {ContactPerson.getInitialsForName(name)}
                </ContactPictureLabel>
            )}
            {children || (!uri && !name && (
                <Icon src={Person} size={size * PLACEHOLDER_FACTOR} color={colors.grey_03} />
            ))}
        </ProfileBackground>
    );
};

export default ProfilePicture;
