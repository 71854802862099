import { bind } from 'decko';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatMeetUpDate } from '../../../../lib/src/helpers/Payment';
import colors from '../../../../lib/src/styles/colors';
import { computeRefundedPrice, Order } from '../../../../lib/src/types/models/Order';
import Alert from '../../helpers/Alert';
import { GRID_SIZE } from '../../styles/base';
import SafariFix from '../common/SafariFix';
import { RegularText, TitleText } from '../text';
import OrderDetails from './OrderDetails';
import { H2, Hr, MeetUpDate, PaymentCard, ScreenTitle, textWithJsx } from './Styled';

const CenteredContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -${GRID_SIZE * 2}px;
    margin-top: ${GRID_SIZE * 3}px;
`;

const OrderConfirmationSuccess = styled.div`
    background-color: #9DDDB1;
    color: #11713E;
    text-align: center;
    padding: ${GRID_SIZE * 3}px ${GRID_SIZE * 2}px;
    margin-top: ${GRID_SIZE * 2}px;
    margin-bottom: ${GRID_SIZE * 2}px;
`;

// @ts-ignore ts(2615)
const OrderConfirmationSuccessText = styled(RegularText)`
    color: #11713E;
    font-weight: 500;
`;

const DetailsLink = styled(RegularText)`
    color: ${colors.teal_900};
    cursor: pointer;
    margin: ${GRID_SIZE * -1}px 0 ${GRID_SIZE}px;
    padding: 0;
`;

interface Props {
    order: Order;
    onViewRestaurant?: () => void;
}

// TODO: inline in `Order` modal
export default class OrderConfirmation extends React.PureComponent<Props> {
    private formattedMeetUpDate(override?: boolean) {
        return formatMeetUpDate(this.props.order?.meetUpDate, override);
    }

    @bind
    private showPhoneNumber() {
        Alert.confirmPhoneCall(this.props.order?.restaurant!);
    }

    public render() {
        const { order, onViewRestaurant } = this.props;
        const cancelled = order.totalPrice! <= computeRefundedPrice(order);
        const expired = moment(order.meetUpDate).add(30, 'minutes').isBefore();
        const lastRefundReason = order.refunded?.slice(-1)[0]?.reason;

        const successMessage = [
            order.accepted && Env.i18n.t('OrderConfirmationEmailHint'),
            !cancelled && Env.i18n.t('OrderConfirmationCancelationHint')
        ].filter(Boolean).join(' ');

        return (
            <SafariFix.OverflowWrapper>
                <PaymentCard>
                    <ScreenTitle>
                        {Env.i18n.t('OrderConfirmation')}
                    </ScreenTitle>
                    <Hr />
                    <H2>
                        {order.restaurant?.name}
                        <MeetUpDate>
                            {this.formattedMeetUpDate()}
                        </MeetUpDate>
                    </H2>
                    {onViewRestaurant && (
                        <DetailsLink onClick={onViewRestaurant}>
                            {Env.i18n.t('ViewRestaurant')}
                        </DetailsLink>
                    )}
                    <OrderDetails order={order} />
                    <CenteredContent>
                        {!!lastRefundReason && (
                            <TitleText>
                                {Env.i18n.t('CancelOrderReasonTitle')}
                                {': '}
                                {Env.i18n.t(`CancelOrderReason_${lastRefundReason}`)}
                            </TitleText>
                        )}
                        {!(cancelled || expired) && (
                            <TitleText>
                                {Env.i18n.t('OrderConfirmationHint')}
                            </TitleText>
                        )}
                        <TitleText style={{ margin: `${GRID_SIZE * 2}px 0` }}>
                            {textWithJsx(Env.i18n.t('OrderConfirmationNumberHint'), (
                                <span style={{ fontSize: '1.7em', verticalAlign: 'sub', margin: '0 16px' }}>
                                    {order.orderId}
                                </span>
                            ))}
                        </TitleText>
                    </CenteredContent>
                </PaymentCard>

                {!!successMessage && (
                    <OrderConfirmationSuccess>
                        <OrderConfirmationSuccessText>
                            {textWithJsx(successMessage, (
                                <span style={{ textDecorationLine: 'underline', fontSize: '1em', cursor: 'pointer' }} onClick={this.showPhoneNumber} >
                                    {` ${order.restaurant?.data?.contact?.phoneNumber} `}
                                </span>
                            ))}
                        </OrderConfirmationSuccessText>
                    </OrderConfirmationSuccess>
                )}
            </SafariFix.OverflowWrapper>
        );
    }
}
