import { action, IReactionDisposer, observable, reaction } from 'mobx';

import ChatsManager from '../managers/ChatsManager';
import { ChatHeader } from '../types/models/Chat';
import DataList from './DataList';

export default class ChatList extends DataList<ChatHeader> {
    private _chatManager: ChatsManager;
    private _personsUnsubscriber: IReactionDisposer;
    private _accountUnsubscriber: IReactionDisposer;
    private _headerUnsubscriber = () => undefined as void;

    @observable
    private _lastSnapshot?: firebase.firestore.QuerySnapshot;

    constructor(chatManager: ChatsManager) {
        super();
        this._chatManager = chatManager;

        this._personsUnsubscriber = reaction(
            () => [ this._lastSnapshot, this._chatManager.account.contacts.list, this._chatManager.groups.groups.list ],
            () => this._lastSnapshot && this.addQuerySnapshotChildren(this._lastSnapshot, true)
        );

        this._accountUnsubscriber = reaction(
            () => this._chatManager.account.user?.uid,
            userId => {
                this._headerUnsubscriber();

                if (userId) {
                    this._headerUnsubscriber = ChatsManager.collectionRef
                        .where('activeParticipantKeys', 'array-contains', userId)
                        .orderBy('lastMessage.timestamp', 'desc') // also removes chats with no messages
                        .onSnapshot(snapshot => this._lastSnapshot = snapshot);
                } else {
                    this.reset();
                }
            },
            { fireImmediately: true }
        );
    }

    @action
    public addQuerySnapshotChildren(snapshot: firebase.firestore.QuerySnapshot, override = false) {
        const write = override ? this.set : this.add;

        write.bind(this)(...snapshot.docs.map(this._chatManager.createChatHeader));
    }

    public release() {
        this._personsUnsubscriber();
        this._accountUnsubscriber();
        this._headerUnsubscriber();
    }
}
