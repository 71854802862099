import { getPaymentUserRef } from '../helpers/Firestore';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import { Order, OrderData } from '../types/models/Order';
import TransactionList, { TransactionDocument, TransactionQuery } from './TransactionList';

type ApiType = ApiManager<AccountManager<ApiType>>;

export default class OrderList extends TransactionList<OrderData, Order> {
    private userId: string;
    private api: ApiType;

    constructor(userId: string, api: ApiType) {
        super('meetUpDate');

        this.userId = userId;
        this.api = api;
        this.startWatching();
    }

    protected get collectionRef() {
        return getPaymentUserRef(this.userId)
            .collection('orders') as firebase.firestore.CollectionReference<OrderData>
    }

    protected buildQuery() {
        return this.collectionRef.where('status', 'in', [ 'SUCCEEDED', 'REFUNDED' ]) as TransactionQuery<OrderData>;
    }

    protected async createItem(doc: TransactionDocument<OrderData>): Promise<Order> {
        const { api } = this;
        const item = doc.data();

        return {
            ...item,
            key: doc.id,
            restaurant: await api.getRestaurant(item.restaurantId),
            meetUpDate: this.getDate(doc)
        };
    }
}
