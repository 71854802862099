import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { PrimarySmallButton, SecondarySmallButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import ScreenModal, { ScreenModalWrapper } from '../common/ScreenModal';
import { ListFooterHint } from '../text';
import FilteredContactList, { FilteredContactListState } from './FilteredContactList';

interface State {
    inputValid?: boolean;
}

@inject('api')
@observer
export default class BlockUsersModal extends ScreenModalWrapper<{}, State> {
    public readonly state: State = {};

    private contactListRef = React.createRef<FilteredContactList<ContactPerson>>();

    private get injected() {
        return this.props as InjectedApiProps;
    }

    public render() {
        const { account } = this.injected.api;

        return (
            <ScreenModal ref={this.modalRef} title={Env.i18n.t('AddBlockedUsers')}>
                <FilteredContactList
                    ref={this.contactListRef}
                    onFilterInput={this.searchForUsers}
                    filterPlaceholder={Env.i18n.t('SearchForContact')}
                    renderItemContent={this.renderAddButton}
                    ListEmptyComponent={this.renderEmptyState}
                    ListFooterComponent={this.renderFooter}
                    dependsOn={account.blockedUsers.list}
                />
            </ScreenModal>
        );
    }

    @bind
    private renderAddButton(contact: ContactPerson) {
        const selected = !!this.injected.api.account.blockedUsers.get(contact.key);
        const Button: typeof PrimarySmallButton = selected ? SecondarySmallButton : PrimarySmallButton;
        const label = Env.i18n.t(selected ? 'Blocked' : 'Block');

        return (
            <Button onClick={() => this.toggleContact(contact)}>
                {label}
            </Button>
        );
    }

    @bind
    private renderFooter({ filteredContacts }: FilteredContactListState<ContactPerson>) {
        return !filteredContacts?.length ? undefined : (
            <ListFooterHint>
                {Env.i18n.t('NotFound')}
            </ListFooterHint>
        );
    }

    @bind
    private renderEmptyState({ filtering, filteredContacts }: FilteredContactListState<ContactPerson>) {
        const emptyHint = Env.i18n.t(filtering ? 'IsSearching' : this.state.inputValid ? 'NoUserFound' : 'NoValidSearch');

        return (
            <EmptyListIndicator waitFor={true} icon={require('../../assets/svg/empty_state_user.svg')} hint={emptyHint} />
        );
    }

    @bind
    private toggleContact(contact: ContactPerson) {
        const selected = !!this.injected.api.account.blockedUsers.get(contact.key);
        const successMessage = Env.i18n.t(selected ? 'SuccessUnblockUser' : 'SuccessBlockedUsersAdded', { count: 1 });
        const errorMessage = Env.i18n.t(selected ? 'ErrorUnblockUser' : 'ErrorBlockedUsersNotAdded', { count: 1 });

        this.contactListRef.current?.waitFor(contact, user => this.changeBlocking(user, selected), successMessage, errorMessage);
    }

    // no bulk request, since it would be very complicated to run it when, instead of just the screen, the app is closed
    private changeBlocking({ key, name }: ContactPerson, isBlocked: boolean) {
        return isBlocked
            ? new Promise<void>(resolve => Alert.confirm(
                Env.i18n.t('UnblockAndAddUserPrompt', { name }),
                undefined,
                confirmed => Backend.unblockUsers([ key ], confirmed).then(resolve)
            ))
            : Backend.blockUsers([ key ]);
    }

    @bind
    private async searchForUsers(term: string) {
        const inputValid = (term.length > 2);
        let users: ContactPerson[] = [];

        this.setState({ inputValid });

        if (inputValid) {
            users = await Backend.searchForBlockableUsers(term);
        }

        return users;
    }
}
