import PeopleOutline from '@material-ui/icons/PeopleOutline';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimarySmallButton } from '../button';
import { DiskButton } from '../common/IconButton';
import { RegularText, TitleText } from '../text';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: ${SCREEN_PADDING}px;
`;

const InfoBox = styled.div`
    margin-left: ${SCREEN_PADDING}px;
`;

const Buttonx = styled(PrimarySmallButton)`
    &&& {
        margin-top: ${GRID_SIZE * 2}px;
    }
`;

interface Props {
    title: string;
    message: string;
    cta?: string;
    onPress: () => void;
}

export default class EmptyList extends React.PureComponent<Props> {
    public render() {
        return (
            <Container>
                <DiskButton diskSize={40} icon={PeopleOutline} diskColor={colors.grey_05} color={colors.matte_black} />
                <InfoBox>
                    <TitleText>
                        {this.props.title}
                    </TitleText>
                    <RegularText>
                        {this.props.message}
                    </RegularText>
                    <Buttonx onClick={this.props.onPress}>
                        {this.props.cta || Env.i18n.t('LetsGo')}
                    </Buttonx>
                </InfoBox>
            </Container>
        );
    }
}

export const EmptyContactsList = styled(EmptyList).attrs(() => ({
    title: Env.i18n.t('EmptyContactsTitle'),
    message: Env.i18n.t('EmptyContactsMessage'),
    cta: undefined
}))``;

export const EmptyGroupsList = styled(EmptyList).attrs(() => ({
    title: Env.i18n.t('EmptyGroupsTitle'),
    message: Env.i18n.t('EmptyGroupsMessage'),
    cta: undefined
}))``;

export const EmptyInvitationsList = styled(EmptyList).attrs(() => ({
    title: Env.i18n.t('EmptyInvitationsTitle'),
    message: Env.i18n.t('EmptyInvitationsMessage'),
    cta: Env.i18n.t('LunchNow')
}))``;
