import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { DescriptionText, TitleText } from '../text';

const Container = styled.div`
    padding: ${GRID_SIZE * 2}px ${GRID_SIZE * 3}px 0;
`;

interface Props {
    title: string;
    description: string;
    style?: React.CSSProperties;
    className?: string;
}

const Section: React.FunctionComponent<Props> = ({ title, description, style, className, children }) => (
    <Container style={style} className={className}>
        <div>
            <TitleText>
                {title}
            </TitleText>
            <DescriptionText style={{ color: colors.grey_01 }}>
                {description}
            </DescriptionText>
        </div>
        {children}
    </Container>
);

export default Section;
