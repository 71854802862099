import Mail from '@material-ui/icons/Mail';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { FirebaseError, logError } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import Alert from '../../helpers/Alert';
import Legal from '../../helpers/Legal';
import { GRID_SIZE } from '../../styles/base';
import { SecondaryButton } from '../button';
import Modal, { ModalProps } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import { TealLink } from '../text';

const OptionButton = styled(SecondaryButton)`
    &&& {
        justify-content: left;
        margin: ${GRID_SIZE}px 0;
    }
`;

const OptionIcon = styled.img`
    margin-right: ${GRID_SIZE * 3}px;
`;

interface Params {
    isPrompt: boolean;
}

@inject('api', 'auth')
export default class RegistrationOptions extends Modal<Params> {
    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedAuthProps;
    }

    protected async hydrateParams(params: string[]) {
        return {
            isPrompt: params.includes('prompt')
        };
    }

    protected validateParams() {
        return computed(() => !this.injected.api.account.loggedIn).get();
    }

    public render() {
        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close}>
                {this.state.params.isPrompt ? (
                    <ScreenHeader title={Env.i18n.t('RegistrationOptions')} onBack={this.back} />
                ) : (
                    <ScreenHeader title={Env.i18n.t('RegistrationOptions')} onClose={this.close} />
                )}
                {/* TODO: 2 buttons per row on wide screens? */}
                <OptionButton onClick={this.loginGoogle}>
                    <OptionIcon src={require('../../assets/svg/social_google.svg')} />
                    {Env.i18n.t('LoginGoogle')}
                </OptionButton>
                <OptionButton onClick={this.loginFacebook}>
                    <OptionIcon src={require('../../assets/svg/social_facebook.svg')} />
                    {Env.i18n.t('LoginFacebook')}
                </OptionButton>
                <OptionButton onClick={this.createAccount} style={{ margin: `${GRID_SIZE * 4}px 0` }}>
                    <Mail style={{ color: colors.matte_black, marginRight: GRID_SIZE * 3 }} />
                    {Env.i18n.t('CreateAccount')}
                </OptionButton>
                <TealLink onClick={this.loginAccount} style={{ margin: `${GRID_SIZE}px 0` }}>
                    {Env.i18n.t('LoginAccount')}
                </TealLink>
            </Screen>
        );
    }

    @bind
    private setVisibility(visible: boolean) {
        this.injected.api.account.setUserFlag('visible', visible);
        this.back();
    }

    @bind
    private askForVisibility(newAccount: boolean) {
        if (newAccount) {
            this.injected.api.schedule(() => Legal.confirmVisibility().then(this.setVisibility));
        } else {
            this.back();
        }
    }

    @bind
    private handleThirdPartyLoginError(error: any) {
        // reset provider log-in state
        this.injected.auth.logout();

        if (error.code === FirebaseError.EMAIL_TAKEN) {
            // currently not happening because we don't link
            Alert.notify(Env.i18n.t('FailLogin'), Env.i18n.t('ErrorEmailTaken'));
        } else {
            logError('RegistrationOptionsScreen.handleThirdPartyLoginError', error);
        }
    }

    @bind
    private async loginGoogle() {
        this.injected.api.waitFor(this.injected.auth.loginWithGoogle())
            .then(this.askForVisibility)
            .catch(this.handleThirdPartyLoginError);
    }

    @bind
    private async loginFacebook() {
        Legal.acceptInitialPolicy().then(confirmed => {
            if (confirmed) {
                this.injected.api.waitFor(this.injected.auth.loginWithFacebook())
                    .then(this.askForVisibility)
                    .catch(this.handleThirdPartyLoginError);
            }
        });
    }

    @bind
    private handleLoginWithEmail(createNewAccount: boolean) {
        this.redirectTo('login', { new: createNewAccount });
    }

    @bind
    private loginAccount() {
        this.handleLoginWithEmail(false);
    }

    @bind
    private createAccount() {
        this.handleLoginWithEmail(true);
    }
}
