/**
 * Adds an array element if the array doesn't contain it yet.
 *
 * @param array The array (by reference, so it will be changed)
 * @param item The item to be added
 * @returns The changed array
 */
function add<T>(array: T[], item: T) {
    if (!array.includes(item)) {
        array.push(item);
    }

    return array;
}

/**
 * Removes an array element if the array contains it.
 *
 * @param array The array (by reference, so it will be changed)
 * @param item The item to be removed
 * @returns The changed array
 */
function remove<T>(array: T[], item: T) {
    const index = array.findIndex(entry => entry === item);

    if (index >= 0) {
        array.splice(index, 1);
    }

    return array;
}


/**
 * Toggles an array element. This means, the element is added,
 * if the array is not yet containig it, or removed otherwise.
 *
 * @param array The array (by reference, so it will be changed)
 * @param item The item to be toggled
 * @returns The changed array
 */
function toggle<T>(array: T[], item: T) {
    const method = array.includes(item) ? remove : add;

    return method(array, item);
}

/**
 * Generates an array of consecutive integers.
 * Note: `range(x,y) == range(y,x).reverse()`.
 *
 * @param from Integer to start with (included)
 * @param to Integer to end with (included)
 * @returns The generated array
 */
function range(from: number, to: number) {
    const numbers: number[] = [];
    const step = Math.sign(to - from);

    for (let i = from; i !== to; i += step) {
        numbers.push(i);
    }

    numbers.push(to);

    return numbers;
}

function findExtremIndices<T>(array: T[], quantifier: (item: T) => any) {
    const quantities = array.map(quantifier);
    let minIndex = -1;
    let maxIndex = -1;

    quantities.forEach((quantity, index) => {
        if (minIndex < 0 || quantity < quantities[minIndex]) {
            minIndex = index;
        }

        if (maxIndex < 0 || quantity > quantities[maxIndex]) {
            maxIndex = index;
        }
    });

    return { minIndex, maxIndex };
}

function findMinIndex<T>(array: T[], quantifier: (item: T) => any) {
    return findExtremIndices(array, quantifier).minIndex;
}

function findMaxIndex<T>(array: T[], quantifier: (item: T) => any) {
    return findExtremIndices(array, quantifier).maxIndex;
}

function findMin<T>(array: T[], quantifier: (item: T) => any) {
    return array.length
        ? array[findMinIndex(array, quantifier)]
        : undefined;
}

function findMax<T>(array: T[], quantifier: (item: T) => any) {
    return array.length
        ? array[findMaxIndex(array, quantifier)]
        : undefined;
}

function shuffle<T>(array: T[]) {
    for (let index = array.length - 1; index >= 0; index--) {
        const newIndex = Math.floor(Math.random() * (index + 1));

        [array[index], array[newIndex]] = [array[newIndex], array[index]];
    }

    return array;
}

function clean<T>(array: (T | undefined)[]) {
    return array.filter(item => item !== undefined) as T[];
}

function unique<T>(array: T[]) {
    return Array.from(new Set(array));
}

/**
 * Doesn't consider order.
 */
function isEqual<T>(array1: T[], array2: T[]) {
    return (array1.length === array2.length) && array1.every(elem => array2.includes(elem));
}

function concat<T>(...arrays: T[][]) {
    return new Array<T>().concat(...arrays);
}

export default {
    add,
    remove,
    toggle,
    range,
    findMinIndex,
    findMin,
    findMaxIndex,
    findMax,
    shuffle,
    clean,
    unique,
    isEqual,
    concat
};
