import moment, { Moment } from 'moment';

import Env from '../../Env';
import { formatNumber } from '../../helpers/formatting';
import { Discount, TimesTimeSpan } from '../lunchnow';
import RestaurantEntry, { MealEntry } from './RestaurantEntry';

export default class DiscountEntry {
    public readonly key: string;

    private discount: Discount;
    private companyId?: string;
    private restaurantId?: string;
    private mealId?: string;
    private startTime?: Moment;
    private endTime?: Moment;
    private available: boolean;

    public static fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
        const entry = new this(snapshot.id, snapshot.data() as Discount);

        return entry.available ? entry : undefined;
    }

    constructor(key: string, discount: Discount) {
        const { companyRef, restaurantRef, mealRef, start, end } = discount;

        this.key = key;
        this.discount = discount;
        this.companyId = companyRef?.id;
        this.restaurantId = restaurantRef?.id || mealRef?.parent?.parent?.id;
        this.mealId = mealRef?.id;
        this.startTime = start && moment(start.toDate());
        this.endTime = end && moment(end.toDate());
        this.available = this.quantityLeft > 0
            && moment().isSameOrBefore(this.endTime) // didn't end yet
            && moment().add(14, 'days').startOf('day').isAfter(this.startTime); // starts in next 14 days
    }

    public get name() {
        return this.discount.name;
    }

    public get quantity() {
        return this.discount.quantity;
    }

    public get type() {
        return this.discount.type;
    }

    public get restaurantRef() {
        return this.discount.restaurantRef;
    }

    public get quantityLeft() {
        return Math.max(0, (this.quantity || Number.POSITIVE_INFINITY) - this.discount.usedBy.length);
    }

    public get forAllMeals() {
        return this.available && this.discount.platform;
    }

    public get timeLimitsHint() {
        const { start, end } = this.timeLimits;

        if (start) {
            return Env.i18n.t(end ? 'DiscountAvailableFromTo' : 'DiscountAvailableFrom', { start, end });
        } else if (end) {
            return Env.i18n.t('DiscountAvailableUntil', { end });
        }

        return '';
    }

    public get expiryHint() {
        const date = this.endTime?.format(Env.i18n.t('DateFormat'));

        return date ? Env.i18n.t('ValidUntil', { date }) : undefined;
    }

    /**
     * `undefined` if not available today or no `endTime` is defined
     */
    public get millisecondsLeftToday() {
        const { startTime, endTime } = this;
        const now = moment();

        if (!startTime?.isAfter(now, 'day') && endTime) {
            const { end } = this.timeLimits;

            if (end) {
                const [ hours, minutes ] = end.split(':');

                return moment(now).hours(Number(hours)).minutes(Number(minutes)).diff(now);
            }
        }
    }

    public get valueText() {
        const { discount, discountType } = this.discount;

        switch (discountType) {
            case 'percent':
                return `${Math.floor(discount * 100)}%`;
            case 'absolute':
            case 'override':
            default:
                return `${formatNumber(discount, (Math.floor(discount) === discount) ? 0 : 2)}€`;
        }
    }

    public get descriptionText() {
        const value = this.valueText;
        let descriptionKey = 'DiscountDescriptionDefault';

        switch (this.discount.type) {
            case 'compensation':
                descriptionKey = 'DiscountDescriptionCompensation';
            break;
        }

        return Env.i18n.t(descriptionKey, { value });
    }

    public get typeText() {
        return Env.i18n.t(`DiscountType_${this.discount.type.replace('-', '')}`);
    }

    public availableForUser(uid: string) {
        const { owners, usedBy } = this.discount;

        return this.available && (!owners || owners.includes(uid)) && !usedBy.includes(uid);
    }

    /**
     * Note: The time is only considered for `discount.endTime`.
     * Discounts becoming available later today are always considered available here.
     */
    public availableForToday() {
        const { available, startTime, endTime } = this;

        if (!available || startTime?.isAfter(moment(), 'day')) {
            return false;
        }

        if (!endTime) {
            return true;
        }

        return endTime.isAfter() && (this.millisecondsLeftToday ?? 1) > 0;
    }

    public availableForTime(time: Moment) {
        if (!this.available) {
            return false;
        }

        const { start, end } = this.timeLimits;
        const formattedTime = time.format('HH:mm');

        return (!start || start <= formattedTime)
            && (!end || end > formattedTime);
    }

    /**
     * Note: `false` for unspecific platform discounts. Use `forAllMeals` getter for those.
     */
    public availableForRestaurant(restaurant: RestaurantEntry) {
        return this.available
            && (this.restaurantId === restaurant.key || (this.companyId && this.companyId === restaurant.data?.company?.id));
    }

    public availableForMeal(meal?: string | MealEntry) {
        const mealId = (typeof meal === 'string') ? meal : meal?.fromMeal?.id;

        return this.available && this.mealId && this.mealId === mealId;
    }

    /**
     * Absolute value of the discount (always negative). Input and output in cents.
     */
    public getValue(originalPrice: number) {
        const { discount, discountType } = this.discount;

        switch (discountType) {
            case 'absolute':
                return discount * -100;
            case 'percent':
                return Math.round(discount * -originalPrice);
            case 'override':
            default:
                return discount * 100 - originalPrice;
        }
    }

    private get timeLimits(): TimesTimeSpan {
        const { startTime, endTime } = this;
        const limits: TimesTimeSpan = {
            start: startTime?.format('HH:mm'),
            end: endTime?.format('HH:mm'),
        };

        if (startTime || endTime) {
            const start = startTime || moment(endTime).startOf('day');
            const end = endTime || moment(startTime).endOf('day');
            const minutesDiff = end.diff(start, 'minutes');
            const minutesDiffOfDay = (minutesDiff + 1) % (24 * 60); // `+ 1` for if end time is 23:59

            // discount is available for whole day and we don't show limits
            if (minutesDiffOfDay < 2) {
                limits.start = undefined;
                limits.end = undefined;
            }
        }

        return limits;
    }
}
