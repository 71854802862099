import InvitationDraftManager from '../../managers/InvitationDraftManager';
import { DataListEntry } from '../../store/DataList';
import Timestamps, { Timestamp } from '../Timestamps';
import { ContactStatus } from './ContactEntity';
import Invitation, { InvitationStatus } from './Invitation';

/**
 * `ContactStatus | null` is needed, because in firebase a status of `null` means the same as pending.
 * Why can't we work with `null`?
 * 1. because all our lookups wouldn't work anymore,
 * 2. because in contact lists a contact can have the status `undefined`.
 * We could build around all that, but that's quite some work.
 */
export interface Attendee {
    displayName: string;
    photoURL: string;
    status: ContactStatus | null;
    uid: string;
    user: firebase.firestore.DocumentReference;
    hasOrdered?: boolean;
}

export default interface UserInvitation extends DataListEntry {
    restaurantName: string;
    restaurantLogo: string;
    restaurant: string;
    attendees: Attendee[];
    date: Timestamp;
    isOwn?: boolean;
    isTakeAway?: boolean;
    status?: InvitationStatus;
    group?: string;
    order?: string;
    reminderSent?: boolean;
}

export function getInvitationDate(invitation: UserInvitation | Invitation) {
    return Timestamps.toDate(invitation.date);
}

export function compareUserInvitation(a: UserInvitation | Invitation, b: UserInvitation | Invitation) {
    return getInvitationDate(a) < getInvitationDate(b) ? -1 : 1;
}

export function isInvitationExpired(invitation: UserInvitation | Invitation | InvitationDraftManager) {
    return !!invitation.date && getInvitationDate(invitation as Invitation) < new Date();
}
