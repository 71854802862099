import { Share } from '@material-ui/icons';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import colors from '../../../../lib/src/styles/colors';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimarySmallButton, SecondarySmallButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Icon, { IconType } from '../common/Icon';
import { ModalState } from '../common/Modal';
import ModalSection from '../common/ModalSection';
import ScreenModal, { ScreenModalWrapper } from '../common/ScreenModal';
import { ListFooterHint, RegularText } from '../text';
import FilteredContactList, { FilteredContactListState } from './FilteredContactList';

const OptionContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE * 2}px ${GRID_SIZE}px;
    padding-right: 0;
`;

const OptionText = styled(ModalSection)`
    flex: 1;
    padding-top: 0;
`;

const Separator = styled.div`
    border-bottom: 1px solid ${colors.grey_02};
    display: flex;
    justify-content: center;
    margin-bottom: ${GRID_SIZE * 2}px;
`;

// @ts-ignore ts(2615)
const SeparatorLabel = styled(RegularText)`
    background-color: ${colors.white};
    color: ${colors.grey_02};
    flex: 0;
    padding: 0 ${GRID_SIZE}px;
    position: relative;
    top: ${1.5 * 0.9 / 2}rem;
`;

interface OptionProps {
    icon: IconType;
    title: string;
    description: string;
    buttonLabel?: string;
    onPress: () => void;
}

const Option: React.FunctionComponent<OptionProps> = ({ icon, title, description, buttonLabel, onPress }) => (
    <OptionContainer>
        <Icon src={icon} />
        <OptionText title={title} description={description} />
        <PrimarySmallButton onClick={onPress}>
            {buttonLabel || title}
        </PrimarySmallButton>
    </OptionContainer>
);

interface State extends ModalState<{}> {
    contacts: ContactPerson[];
    inputValid?: boolean;
}

@inject('api')
@observer
export default class AddContactsModal extends ScreenModalWrapper<{}, State> {
    public readonly state: State = {
        contacts: [],
        params: {}
    };

    private contactListRef = React.createRef<FilteredContactList<ContactPerson>>();

    private get injected() {
        return this.props as InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public componentDidMount() {
        this.injected.api.account.contactSuggestions.then(contacts => this.setState({ contacts }));
    }

    public render() {
        const { account } = this.injected.api;

        return (
            <ScreenModal ref={this.modalRef} title={Env.i18n.t('AddContactHeader')}>
                <div style={{ padding: `0 ${SCREEN_PADDING}px` }}>
                    {/* <Option
                        icon={Icons.ImportContacts}
                        title={Env.i18n.t('Import')}
                        description={Env.i18n.t('ImportDescription')}
                        onPress={account.handleContactImport}
                    /> */}
                    <Option
                        icon={Share}
                        title={Env.i18n.t('ConnectUser')}
                        description={Env.i18n.t('ShareDescription')}
                        buttonLabel={Env.i18n.t('Share')}
                        onPress={account.shareMeAsContact}
                    />
                    <Separator>
                        <SeparatorLabel>
                            {Env.i18n.t('Or')}
                        </SeparatorLabel>
                    </Separator>
                </div>
                <FilteredContactList
                    ref={this.contactListRef}
                    contacts={this.state.contacts}
                    onFilterInput={this.searchForUsers}
                    filterPlaceholder={Env.i18n.t('SearchForContact')}
                    renderItemContent={this.renderAddButton}
                    ListEmptyComponent={this.renderEmptyState}
                    ListHeaderComponent={this.renderHeader}
                    ListHeaderLayout={this.getHeaderHeight}
                    ListFooterComponent={this.renderFooter}
                    dependsOn={account.contacts.list}
                />
            </ScreenModal>
        );
    }

    @bind
    private renderAddButton(contact: ContactPerson) {
        const selected = !!this.injected.api.account.contacts.get(contact.key);
        const Button = (selected ? SecondarySmallButton : PrimarySmallButton) as typeof PrimarySmallButton;
        const label = Env.i18n.t(selected ? 'Added' : 'Add');

        return (
            <Button onClick={() => this.toggleContact(contact)}>
                {label}
            </Button>
        );
    }

    @bind
    private renderHeader({ filteredContacts }: FilteredContactListState<ContactPerson>) {
        return filteredContacts ? undefined : (
            <ModalSection
                title={Env.i18n.t('SuggestedContact')}
                description={Env.i18n.t('SuggestedContactDescription')}
                style={{ paddingTop: 0 }}
            />
        );
    }

    @bind
    private getHeaderHeight({ filteredContacts }: FilteredContactListState<ContactPerson>) {
            return filteredContacts ? undefined : 24 + 14;
    }

    @bind
    private renderFooter({ filteredContacts }: FilteredContactListState<ContactPerson>) {
        return !filteredContacts?.length ? undefined : (
            <ListFooterHint>
                {Env.i18n.t('NotFound')}
            </ListFooterHint>
        );
    }

    @bind
    private renderEmptyState({ filtering, filteredContacts }: FilteredContactListState<ContactPerson>) {
        const { contacts, inputValid } = this.state;

        return (!filteredContacts?.length && !contacts) ? undefined : (
            <EmptyListIndicator
                waitFor={true}
                icon={require('../../assets/svg/empty_state_user.svg')}
                hint={Env.i18n.t(filtering ? 'IsSearching' : inputValid ? 'NoUserFound' : 'NoValidSearch')}
            />
        );
    }

    @bind
    private toggleContact(contact: ContactPerson) {
        const selected = !!this.injected.api.account.contacts.get(contact.key);
        // no bulk request, since it would be very complicated to run it when, instead of just the screen, the app is closed
        const request = selected ? Backend.removeContacts : Backend.addContacts;
        const successMessage = Env.i18n.t(selected ? 'SuccessContactsRemoved' : 'SuccessContactsAdded', { count: 1 });
        const errorMessage = Env.i18n.t(selected ? 'ErrorContactsNotRemoved' : 'ErrorContactsNotAdded', { count: 1 });

        this.contactListRef.current?.waitFor(contact, ({ key }) => request([ key ]), successMessage, errorMessage);
    }

    @bind
    private async searchForUsers(term: string) {
        const inputValid = (term.length > 1);
        let contacts: ContactPerson[] | undefined;

        this.setState({ inputValid });

        if (inputValid) {
            contacts = await Backend.searchForUsers(term);
        }

        return contacts;
    }
}
