import { CircularProgress } from '@material-ui/core';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import Lists from '../../../../lib/src/helpers/Lists';
import colors from '../../../../lib/src/styles/colors';
import ContactEntity, { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../lib/src/types/models/Group';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE } from '../../styles/base';
import MediaGalleryModal from '../details/MediaGalleryModal';
import ProfilePicture from '../social/ProfilePicture';
import { DescriptionText, ErrorText, SublineText, TitleText } from '../text';

export const clickableEntry = css`
    cursor: pointer;
    transition: background-color 300ms;

    &:hover {
        background-color: ${colors.grey_05};
    }
`;

const MEMBER_PICTURE_SIZE = GRID_SIZE * 5;

const GroupContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

// @ts-ignore ts(2615)
const ChipsContainer = styled(GroupContainer)`
    flex-wrap: wrap;
`;

const ChipsError = styled(ErrorText)`
    width: 100%; /* forcing line-wrap */
`;

const GroupMembersContainer = styled(GroupContainer)`
    box-sizing: border-box;
    padding-left: ${MEMBER_PICTURE_SIZE / 2}px;
    padding-right: ${GRID_SIZE}px;
    position: relative;
    width: ${MEMBER_PICTURE_SIZE * 1.5 + GRID_SIZE}px; /* width should be independent of children */
`;

interface ContactProp {
    contact: ContactPerson;
}

const ContactPicture = styled(ProfilePicture).attrs<ContactProp>(({ contact }) => ({
    name: (contact.status === 'deleted') ? undefined : contact.name,
    uri: contact.photoURL
}))<ContactProp>``;

const GroupPicture = styled(ContactPicture).attrs({
    size: MEMBER_PICTURE_SIZE
})`
    margin-right: ${GRID_SIZE / 2}px;
`;

const GroupMemberPicture = styled(GroupPicture)`
    margin-left: ${MEMBER_PICTURE_SIZE / -2}px;
    margin-right: 0;
`;

const GroupMembersIndicator = styled(DescriptionText)`
    background-color: ${colors.grey_04};
    border-radius: ${MEMBER_PICTURE_SIZE / 4}px;
    bottom: 0;
    font-weight: 500;
    height: ${MEMBER_PICTURE_SIZE / 2}px;
    line-height: ${MEMBER_PICTURE_SIZE / 2}px;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    width: ${MEMBER_PICTURE_SIZE / 2}px;
`;

const ContactContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: ${GRID_SIZE * 9}px;
    padding: ${GRID_SIZE}px ${GRID_SIZE * 3}px;
    padding-left: ${GRID_SIZE * 4}px;
    ${props => props.onClick && clickableEntry};
`;

const ContactName = styled.div`
    flex: 1;
    margin: 0 ${GRID_SIZE * 2}px;
`;

const GroupDescription = styled(DescriptionText).attrs({
    numberOfLines: 1
})`
    color: ${colors.grey_01};
`;

const LoadingIndicator = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

interface ContactBaseProps<T extends ContactEntity> {
    contact: T;
    className?: string;
    style?: React.CSSProperties;
}

@inject('account')
@observer
export class ContactAvatar extends React.PureComponent<ContactBaseProps<ContactEntity>> {
    private get injected() {
        return this.props as ContactBaseProps<ContactEntity> & InjectedAccountProps;
    }

    public render() {
        const { contact } = this.props;

        return (contact instanceof Group) ? this.renderGroupAvatar(contact) : this.renderPersonAvatar(contact as ContactPerson);
    }

    private renderPersonAvatar(person: ContactPerson) {
        return (
            <ContactPicture contact={person} className={this.props.className} style={this.props.style} />
        );
    }

    private renderGroupAvatar(group: Group) {
        const { className, style } = this.props;
        const { otherParticipants } = group;
        const visibleMembers = Object.entries(otherParticipants).slice(0, 2)
            .map(([ key, { name, photoURL } ]) => new ContactPerson(key, name, photoURL));
        const invisibleMembers = Lists.cardinalityOf(otherParticipants) - visibleMembers.length;

        return (
            <GroupMembersContainer className={className} style={style}>
                {visibleMembers.map(member => (
                    <GroupMemberPicture key={member.key} contact={member} />
                ))}
                {(invisibleMembers > 0) && (
                    <GroupMembersIndicator>
                        +{invisibleMembers}
                    </GroupMembersIndicator>
                )}
            </GroupMembersContainer>
        );
    }
}

interface ContactChipsProps {
    contacts: ContactPerson[];
    onPress?: (person: ContactPerson) => void;
    emptyText?: string;
    errorText?: string;
    className?: string;
    style?: React.CSSProperties;
}

export class ContactChips extends React.PureComponent<ContactChipsProps> {
    public render() {
        const { contacts, onPress, emptyText, errorText, className, style } = this.props;

        return (
            <ChipsContainer className={className} style={style}>
                {contacts.map(contact => (
                    <GroupPicture key={contact.key} contact={contact} onPress={onPress ? () => onPress(contact) : undefined} />
                ))}
                {!!emptyText && (contacts.length <= 0) && (
                    <SublineText>
                        {emptyText}
                    </SublineText>
                )}
                {!!errorText && (
                    <ChipsError>
                        {errorText}
                    </ChipsError>
                )}
            </ChipsContainer>
        );
    }
}

interface ContactEntryProps<T extends ContactEntity> extends ContactBaseProps<T> {
    onPress?: (contact: T, target?: HTMLElement) => void;
    loading?: boolean;
    disabled?: boolean;
}

export default class ContactEntry<T extends ContactEntity> extends React.PureComponent<ContactEntryProps<T>> {
    public render() {
        const { contact, onPress, loading, disabled = loading, className, style, children } = this.props;
        const isDeleted = (contact instanceof ContactPerson) && (contact.status === 'deleted');
        const group = (contact instanceof Group) && contact;
        const opacity = disabled ? 0.5 : 1;
        const isCurrentUser = !group && (Env.firebase.auth().currentUser?.uid === contact.key);
        const title = contact.name + (isCurrentUser ? ` (${Env.i18n.t('You')})` : '');

        return (
            <div className={className} style={style}>
                <ContactContainer onClick={(isDeleted || disabled || !onPress) ? undefined : this.handlePress} style={{ opacity }}>
                    <div onClick={this.handleAvatarPress}>
                        <ContactAvatar contact={contact} />
                    </div>
                    <ContactName>
                        <TitleText as="div">
                            <ClampLines id="" lines={group ? 1 : 2} text={title} buttons={false} />
                        </TitleText>
                        {group && (
                            <GroupDescription>
                                {Env.i18n.t('GroupDescription', { count: group.participantKeys.length - 1 })}
                            </GroupDescription>
                        )}
                    </ContactName>
                    {children}
                </ContactContainer>
                {loading && (
                    <LoadingIndicator>
                        <CircularProgress style={{ height: GRID_SIZE * 6, width: GRID_SIZE * 6 }} />
                    </LoadingIndicator>
                )}
            </div>
        );
    }

    @bind
    protected handlePress(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.props.onPress) {
            this.props.onPress(this.props.contact, event.currentTarget);
        }
    }

    @bind
    protected handleAvatarPress(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const { contact } = this.props;

        if (contact instanceof ContactPerson && contact.photoURL) {
            event.stopPropagation();
            MediaGalleryModal.open([{ url: contact.photoURL }]);
        }
    }
}
