import { IconButton, withMobileDialog } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import de from 'hyphenated-de';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Hyphenated from 'react-hyphen';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatNumber } from '../../../../lib/src/helpers/formatting';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import { logOrder } from '../../../../lib/src/types/models/Order';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryButton } from '../button';
import { DiskButton } from '../common/IconButton';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen from '../common/Screen';
import CartModal from '../payment/CartModal';
import RestaurantMeta from '../restaurants/RestaurantMeta';
import { ScreenHeadline } from '../text';
import Contact from './Contact';
import EmptyIndicator from './EmptyIndicator';
import Header, { COLLAPSED_HEADER_HEIGHT } from './Header';
import InfoModal from './InfoModal';
import Menus from './Menus';
import OptionsComponent from './Options';
import ShareRestaurantModal from './ShareRestaurantModal';

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: ${GRID_SIZE * 10}px; /* space for OptionsComponent */
    padding: ${SCREEN_PADDING}px 0;
`;

const LogoContainer = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    height: 80px;
    margin-right: ${GRID_SIZE * 2.5}px;
    width: 80px;
`;

const MetaContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
`;

const CloseButton = styled(IconButton).attrs({
    size: 'small'
})`
    &&& {
        color: ${colors.white};
        left: ${GRID_SIZE}px;
        position: absolute;
        top: ${GRID_SIZE}px;
        z-index: 2001;
    }
`;

const StickyHeader = styled.div`
    && {
        align-items: center;
        background-color: ${colors.navy_blue};
        box-sizing: border-box;
        color: ${colors.white};
        display: flex;
        flex-direction: row;
        height: ${COLLAPSED_HEADER_HEIGHT};
        overflow: hidden;
        padding: 0 ${GRID_SIZE}px 0 ${GRID_SIZE * 5.5}px;
        position: absolute;
        white-space: nowrap;
        width: 100%;
        z-index: 2000;
    }
`;

const DetailsHeadline = styled(ScreenHeadline)`
    && {
        color: inherit;
        flex: 1;
        font-size: 1.5rem;
        line-height: ${COLLAPSED_HEADER_HEIGHT};
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const OptionButton = styled(DiskButton).attrs({
    diskColor: 'transparent',
    diskSize: GRID_SIZE * 5,
    color: colors.white
})`
    opacity: ${({ onClick }) => onClick ? 1 : 0.25};
`;

const RestaurantNameText = styled.div`
    font-size: 17px;
    font-weight: 500;
`;

interface Params {
    restaurant?: RestaurantEntry;
    fromInvitation?: boolean;
    showCart?: boolean;
}

type State = ModalState<Params>;

@inject('api', 'invitationDraft', 'payment')
@observer
class Details extends Modal<Params, State> {
    public readonly state: State = {
        params: {}
    };

    private scrollViewRef = React.createRef<HTMLDivElement>();
    private menuRef = React.createRef<Menus>();
    private infoModalRef = React.createRef<InfoModal>();
    private cartModalRef = React.createRef<CartModal>();
    private restaurantSharingModalRef = React.createRef<ShareRestaurantModal>();
    private impressionTracked = false;

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps & InjectedPaymentProps;
    }

    private trackImpression() {
        if (!this.impressionTracked) {
            Env.logEvent('show_detail_restaurant', { resKey: this.state.params.restaurant!.key });
            this.impressionTracked = true;
        }
    }

    private requireVerifiedAccount(message: string) {
        return this.injected.api.account.requireVerification(
            message,
            loggedIn => this.redirectTo(loggedIn ? 'profile' : 'registration', [ 'prompt' ])
        );
    }

    @bind
    private createInvitation() {
        if (this.requireVerifiedAccount(Env.i18n.t('AnonCallToActionInvite'))) {
            this.restaurantSharingModalRef.current?.open();
        }
    }

    @bind
    private createOrder() {
        if (this.requireVerifiedAccount(Env.i18n.t('AnonCallToActionOrder'))) {
            logOrder('begin_checkout', this.injected.payment.getCart(this.state.params.restaurant!));
            this.showCart();
        }
    }

    @bind
    private showInfo() {
        this.infoModalRef.current?.open();
    }

    private showCart() {
        this.cartModalRef.current?.open();
    }

    @bind
    private handleBackdropClose() {
        if (this.state.params.fromInvitation) {
            Alert.confirmInvitationDiscard(this.injected.invitationDraft, confirmed => {
                if (confirmed) {
                    this.close();
                }
            });
        } else {
            this.close();
        }
    }

    @bind
    private linkToMoia() {
        this.redirectTo('download');
    }

    @bind
    private showMealType(menuTypeBaseName: string) {
        this.menuRef.current?.showMealType(menuTypeBaseName);
    }

    @bind
    private renderStickyHeader() {
        const { restaurant, fromInvitation } = this.state.params;

        return (
            <>
                <CloseButton onClick={this.back}>
                    <Close />
                </CloseButton>
                <StickyHeader>
                    <DetailsHeadline>
                        {restaurant?.name}
                    </DetailsHeadline>
                    <OptionButton icon={require('../../assets/svg/info.svg')} onClick={this.showInfo} />
                    <OptionButton icon={require('../../assets/svg/send.svg')} onClick={fromInvitation ? undefined : this.createInvitation} />
                </StickyHeader>
            </>
        );
    }

    @bind
    private renderFooter(cart?: Cart) {
        const totalPrice = cart?.totalPrice || 0;

        if (totalPrice <= 0) {
            return null
        }

        return (
            <PrimaryButton onClick={this.createOrder}>
                {Env.i18n.t('CartButtonLabel', { value: `${formatNumber(totalPrice / 100, 2)} €` })}
            </PrimaryButton>
        );
    }

    protected handleParams() {
        return super.handleParams().then(() => {
            if (this.state.params.showCart) {
                this.showCart();
            }
        });
    }

    protected async hydrateParams(params: string[]) {
        return {
            restaurant: await this.injected.api.getRestaurant(params[0]),
            fromInvitation: (params[1] === 'invitation'),
            showCart: (params[1] === 'cart')
        };
    }

    protected validateParams(params: Partial<Params>) {
        return !!params.restaurant;
    }

    public render() {
        const { restaurant, fromInvitation } = this.state.params;

        if (!restaurant?.data) {
            return null;
        }

        const isPartner = (restaurant.type !== CooperationType.NonPartner);
        const cart = isPartner ? this.injected.payment.getCart(restaurant) : undefined;

        this.trackImpression();

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.handleBackdropClose}
                HeaderComponent={this.renderStickyHeader}
                FooterComponent={this.renderFooter(cart)}
                contentRef={this.scrollViewRef}
            >
                <SafariFix.OverflowWrapper>
                    {/* Header – needs to be here in order to scroll */}
                    <Header restaurant={restaurant} />
                    <HeaderContainer>
                        {restaurant.logo?.url && (
                            <LogoContainer style={{ backgroundImage: `url(${restaurant.logo.url})` }} />
                        )}
                        <MetaContainer>
                            <Hyphenated language={de} >
                                <RestaurantNameText>
                                    {restaurant.name}
                                </RestaurantNameText>
                            </Hyphenated>
                            <RestaurantMeta restaurant={restaurant} displayOpeningTime={true} style={{ marginTop: GRID_SIZE }} />
                        </MetaContainer>
                    </HeaderContainer>
                    <OptionsComponent onInfoPress={this.showInfo} onSendPress={fromInvitation ? undefined : this.createInvitation} />
                    {isPartner ? (
                        <Menus ref={this.menuRef} restaurant={restaurant} parentScrollView={this.scrollViewRef} />
                    ) : (
                        <>
                            <EmptyIndicator
                                title="Dieses Restaurant ist leider noch kein Partner von LunchNow." // TODO: localize
                                message="Du kannst dich aber trotzdem dort mit deinen Kontakten verabreden!" // TODO: localize
                            />
                            <Contact restaurant={restaurant} onOpenMoia={this.linkToMoia} />
                        </>
                    )}
                </SafariFix.OverflowWrapper>
                {isPartner && (
                    <InfoModal ref={this.infoModalRef} restaurant={restaurant} onOpenMoia={this.linkToMoia} />
                )}
                {cart && (
                    <CartModal ref={this.cartModalRef} cart={cart} onReturn={this.showMealType} onNavigate={this.redirectTo} />
                )}
                <ShareRestaurantModal ref={this.restaurantSharingModalRef} restaurant={restaurant} onNavigate={this.redirectTo} />
            </Screen>
        );
    }
}

export default withMobileDialog({ breakpoint: 'xs' })(Details);
