import { DialogTitle } from '@material-ui/core';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Validate, { Validation } from '../../../../lib/src/helpers/Validate';
import { InjectedAccountProps } from '../../Account';
import { PrimaryButton, SecondaryButton } from '../button';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Input, { InputValue } from '../forms/Input';

interface State extends ModalState<{}> {
    displayNameValid: boolean;
}

@inject('account')
@observer
export default class EditName extends Modal<{}, State> {
    public readonly state: State = {
        displayNameValid: false,
        params: {}
    };

    private displayNameInputRef = React.createRef<Input>();

    private get injected() {
        return this.props as ModalProps & InjectedAccountProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.loggedIn).get();
    }

    public render() {
        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderCta}>
                <ScreenHeader title={Env.i18n.t('EditDisplayName')} onBack={this.back} />
                <Input
                    ref={this.displayNameInputRef}
                    placeholder={Env.i18n.t('YourNewDisplayName')}
                    defaultValue={this.injected.account.data.displayName || undefined}
                    validate={this.validateDisplayName}
                    onSubmitEditing={this.submitDisplayName}
                    autoCapitalize="words"
                />
            </Screen>
        );
    }

    @bind
    private validateDisplayName(displayName: InputValue) {
        let validation = Validate.displayName(displayName.toString());

        if (validation.valid && displayName === this.injected.account.data.displayName) {
            validation = Validation.error(Env.i18n.t('ErrorNameIdentical'));
        }

        this.setState({ displayNameValid: validation.valid });

        return validation;
    }

    @bind
    private handleAuthError() {
        this.displayNameInputRef.current?.setError(Validation.error(Env.i18n.t('ErrorUnknown')));
    }

    @bind
    private submitDisplayName() {
        let displayName = this.displayNameInputRef.current?.validateValue();

        if (displayName !== undefined) {
            if (this.injected.account.loggedIn) {
                displayName = displayName.toString().replace(/[^ -~\u0080-þĀ-žƀ-ɎḀ-ỾⱠ-\u2c7e꜠-ꟾ]+/ug, '');
                this.injected.account
                    .updateProfile({ displayName })
                    .then(() => {
                        Env.snackbar.success(Env.i18n.t('SuccessEditDisplayName'));
                        this.back();
                    })
                    .catch(this.handleAuthError);
            } else {
                this.handleAuthError();
            }
        }
    }

    @bind
    private renderCta() {
        const SubmitButton: typeof PrimaryButton = this.state.displayNameValid ? PrimaryButton : SecondaryButton;

        return (
            <DialogTitle>
                <SubmitButton onClick={this.submitDisplayName}>
                    {Env.i18n.t('SaveName')}
                </SubmitButton>
            </DialogTitle>
        );
    }
}
