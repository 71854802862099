import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Constants from '../../../../../lib/src/Constants';
import Env from '../../../../../lib/src/Env';
import { InjectedChatsProps } from '../../../../../lib/src/managers/ChatsManager';
import ContactEntity from '../../../../../lib/src/types/models/ContactEntity';
import Group from '../../../../../lib/src/types/models/Group';
import { PrimaryButton } from '../../button';
import ScreenModal, { ScreenModalWrapper } from '../../common/ScreenModal';
import AddContactsModal from '../../contacts/AddContactsModal';
import ContactSelectionList from '../../contacts/ContactSelectionList';
import { EmptyContactsList } from '../../social/EmptyList';

interface Props {
    onNavigate: (route: string, params?: string[]) => void;
}

// TODO: extract common super-class with `InvitationContactsModal`?
@inject('chats')
@observer
export default class ChatParticipantsModal extends ScreenModalWrapper<Props> {
    private contactSelectionRef = React.createRef<ContactSelectionList<ContactEntity>>();
    private addContactsModal = React.createRef<AddContactsModal>();

    private get injected() {
        return this.props as Props & InjectedChatsProps;
    }

    public render() {
        const { account, groups } = this.injected.chats;
        const contacts = [ ...account.contacts.list, ...groups.groups.list ].filter(this.isInvitable);

        return (
            <ScreenModal ref={this.modalRef} title={Env.i18n.t('NewMessage')} FooterComponent={this.renderCta()}>
                <ContactSelectionList
                    ref={this.contactSelectionRef}
                    contacts={contacts}
                    selected={[]}
                    selectionPlaceholder={Env.i18n.t('AddContactCTA')}
                    ListEmptyComponent={this.renderEmptyState}
                    minSelection={1}
                    maxSelection={Constants.MAX_ATTENDEES - 1}
                />
                <AddContactsModal ref={this.addContactsModal} />
            </ScreenModal>
        );
    }

    @bind
    private renderCta() {
        return (
            <PrimaryButton onClick={this.submit}>
                {Env.i18n.t('NewMessage')}
            </PrimaryButton>
        );
    }

    @bind
    private renderEmptyState() {
        return (
            <EmptyContactsList onPress={() => this.addContactsModal.current?.open()} />
        );
    }

    @bind
    private async submit() {
        const selection = this.contactSelectionRef.current?.getSelection();

        if (selection?.length && await this.waitFor('', this.injected.chats.getChatForContacts(selection))) {
            this.close();
            this.props.onNavigate('chatmessages');
        }
    }

    @bind
    private isInvitable(contact: ContactEntity): boolean {
        const group = (contact instanceof Group) ? contact : undefined;
        const isInvitableGroup = group?.toPersons().some(this.isInvitable);

        return isInvitableGroup || (!group && !this.injected.chats.account.isBlockedBy(contact));
    }
}
