import { computed, reaction } from 'mobx';

import Env from '../Env';
import Arrays from '../helpers/Arrays';
import HttpRequest from '../helpers/HttpRequest';
import DataList from '../store/DataList';
import { ChatHeader } from '../types/models/Chat';
import { ContactPerson } from '../types/models/ContactEntity';
import Group, { GroupData } from '../types/models/Group';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

type ApiType = ApiManager<AccountManager<ApiType>>;

export interface InjectedGroupsProps {
    groups: GroupsManager;
}

// TODO: extend DataList and only use in AccountManager?
export default class GroupsManager {
    private _api: ApiType;
    private _groups = new DataList<Group>();

    public get groups() {
        return this._groups;
    }

    constructor(api: ApiType) {
        this._api = api;

        reaction(
            () => this._api.account.currentContactPerson,
            user => {
                if (user) {
                    Env.firebase.firestore().collection('groups')
                        .where('participantKeys', 'array-contains', user.key)
                        .onSnapshot(snapshot => this._groups.set(
                            ...(snapshot?.docs || []).map(doc => new Group(user!, doc.id, doc.data() as GroupData))
                        ));
                } else {
                    this._groups.reset();
                }
            },
            { fireImmediately: true }
        );
    }

    public async create(participants: ContactPerson[], name?: string, bewareOfDuplicates = false) {
        const user = this._api.account.currentContactPerson;

        if (user) {
            if (bewareOfDuplicates) {
                const existingGroups = this.filter(participants);
                const confirmed = !existingGroups.length || await new Promise<boolean>(resolve => Env.alert(
                    Env.i18n.t('GroupDuplicatesTitle'),
                    Env.i18n.t('GroupDuplicatesDescription', {
                        count: existingGroups.length,
                        names: existingGroups.map(({ name }) => name)
                    }),
                    [
                        {
                            label: Env.i18n.t('No'),
                            action: () => resolve(false)
                        },
                        {
                            label: Env.i18n.t('Yes'),
                            action: () => resolve(true)
                        }
                    ],
                    false
                ));

                if (!confirmed) {
                    return;
                }
            }

            const participantKeys = Arrays.add(participants.map(({ key }) => key), user.key);
            const { key, data } = JSON.parse(await HttpRequest.put('/api/group', { participantKeys, name }));

            return new Group(user, key, data);
        }
    }

    public async getOrCreate(participants: ContactPerson[]) {
        const existingGroups = this.filter(participants);
        let group: Group | undefined;

        if (existingGroups.length) {
            group = await new Promise(resolve => Env.alert(
                Env.i18n.t('GroupDuplicatesTitle'),
                Env.i18n.t('GroupDuplicatesSelect', { count: existingGroups.length }),
                [
                    ...existingGroups.map(group => ({
                        label: group.name,
                        action: () => resolve(group)
                    })),
                    {
                        label: Env.i18n.t('NewGroup', { name: Group.defaultName(participants) }),
                        action: () => resolve(undefined)
                    }
                ],
                false
            ));
        }

        return group || this.create(participants);
    }

    public filter(participants: ContactPerson[]) {
        return computed(() => {
            const userId = this._api.account.user?.uid || '';
            const participantKeysStr = Group.participantKeysToString(Arrays.add(participants.map(({ key }) => key), userId));

            return this._groups.list.filter(group => group.participantKeysStr === participantKeysStr);
        }).get();
    }

    public getChatName(chat: ChatHeader) {
        return computed(() => this.groups.get(chat.key)?.name || Group.defaultName(chat.otherParticipants)).get();
    }
}
