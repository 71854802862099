import { Fab } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import GoogleMapsApi from '../../../../lib/src/helpers/GoogleMapsApi';
import Locations from '../../../../lib/src/helpers/Locations';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import LinkTo from '../../helpers/LinkTo';
import BackgroundImage from '../common/BackgroundImage';
import { BUTTON_ICON_SIZE } from '../common/Icon';
import Marker from '../map/Marker';

const MAP_WIDTH = 600;
const MAP_HEIGHT = 300;
const MAP_ZOOM = 15;

// @ts-ignore ts(2615)
const MapContainer = styled(BackgroundImage)`
    align-items: center;
    display: flex;
    height: ${MAP_HEIGHT}px;
    justify-content: center;
    position: relative;
    width: 100%;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    restaurant: RestaurantEntry;
}

export default class StaticMap extends React.PureComponent<Props> {
    @bind
    private linkToMap() {
        const { restaurant } = this.props;
        const coords = Locations.normalize(restaurant.location);
        const name = encodeURI(restaurant.name || '');
        const mapUrl = `https://maps.google.com/?q=${name}&center=${coords.latitude},${coords.longitude}`;

        LinkTo.url(mapUrl, '_blank');
    }

    public render() {
        const { restaurant, ...divProps } = this.props;

        if (!restaurant.location) {
            return null;
        }

        const mapUri = GoogleMapsApi.getImageUrl(MAP_WIDTH, MAP_HEIGHT, restaurant.location, MAP_ZOOM);
        const locationCoords = GoogleMapsApi.latlngToCoords(restaurant.location);

        return (
            <MapContainer src={mapUri} {...divProps}>
                <Marker {...locationCoords} secondary={restaurant.type === CooperationType.NonPartner} />
                <Fab size="medium" style={{ position: 'absolute', right: 20, top: 20 }} onClick={this.linkToMap}>
                    <img
                        alt="Öffne Google Maps"
                        src={require('../../assets/svg/ic_primary_openinnewwindow.svg')}
                        width={BUTTON_ICON_SIZE}
                        height={BUTTON_ICON_SIZE}
                    />
                </Fab>
            </MapContainer>
        );
    }
}
