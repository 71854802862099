import { DialogTitle } from '@material-ui/core';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Validate, { FirebaseErrorHandler, Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import { PrimaryButton, SecondaryButton } from '../button';
import Icon from '../common/Icon';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Input, { InputValue } from '../forms/Input';

interface State extends ModalState<{}> {
    emailValid: boolean;
    passwordValid: boolean;
    showPassword: boolean;
}

@inject('api', 'auth')
export default class ChangeEmail extends Modal<{}, State> {
    public readonly state: State = {
        emailValid: false,
        passwordValid: false,
        showPassword: false,
        params: {}
    };

    private emailInputRef = React.createRef<Input>();
    private passwordInputRef = React.createRef<Input>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedAuthProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.auth.user?.isAnonymous === false).get();
    }

    public render() {
        let showPasswordIcon = VisibilityOffOutlined;
        let showPasswordIconColor = colors.grey_02;

        if (this.state.showPassword) {
            showPasswordIcon = VisibilityOutlined;
            showPasswordIconColor = colors.matte_black;
        }

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderCta}>
                <ScreenHeader title={Env.i18n.t('ChangeEmail')} onBack={this.back} />
                <Input
                    ref={this.emailInputRef}
                    placeholder={Env.i18n.t('YourNewEmailAddress')}
                    validate={this.validateEmail}
                    onSubmitEditing={this.focusPasswordInput}
                    autoCapitalize="none"
                />
                <Input
                    ref={this.passwordInputRef}
                    placeholder={Env.i18n.t('YourPassword')}
                    validate={this.validatePassword}
                    sanitizeValue={false}
                    onSubmitEditing={this.submitEmail}
                    type={this.state.showPassword ? 'text' : 'password'}
                >
                    <Icon src={showPasswordIcon} color={showPasswordIconColor} onClick={this.togglePassword}/>
                </Input>
            </Screen>
        );
    }

    @bind
    private validateEmail(email: InputValue) {
        const emailString = email.toString();
        let validation = Validate.email(emailString);

        if (validation.valid && emailString.toLowerCase() === this.injected.auth.user!.email) {
            validation = Validation.error(Env.i18n.t('ErrorEmailIdentical'));
        }

        this.setState({ emailValid: validation.valid });

        return validation;
    }

    @bind
    private validatePassword(password: InputValue) {
        const validation = Validate.password(password.toString(), false);

        this.setState({ passwordValid: validation.valid });

        return validation;
    }

    @bind
    private handleAuthError(error: any) {
        const emailInputError = FirebaseErrorHandler.forEmail(error);
        let passwordInputError = FirebaseErrorHandler.forPassword(error);

        if (emailInputError.valid && passwordInputError.valid) {
            passwordInputError = Validation.error(Env.i18n.t('ErrorUnknown'));
        }

        this.emailInputRef.current?.setError(emailInputError);
        this.passwordInputRef.current?.setError(passwordInputError);
    }

    @bind
    private submitEmail() {
        const email = this.emailInputRef.current?.validateValue();
        const password = this.passwordInputRef.current?.validateValue();

        if (email !== undefined && password !== undefined) {
            this.injected.api.waitFor(this.injected.auth.changeEmail(email.toString(), password.toString()))
                .then(() => this.close())
                .catch(this.handleAuthError);
        }
    }

    @bind
    private togglePassword() {
        this.setState(state => ({ showPassword: !state.showPassword }));
    }

    @bind
    private focusPasswordInput() {
        this.passwordInputRef.current?.focus();
    }

    @bind
    private renderCta() {
        const SubmitButton = this.state.emailValid && this.state.passwordValid ? PrimaryButton : SecondaryButton;

        return (
            <DialogTitle>
                <SubmitButton onClick={this.submitEmail}>
                    {Env.i18n.t('SaveEmail')}
                </SubmitButton>
            </DialogTitle>
        );
    }
}
