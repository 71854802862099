import { Button } from '@material-ui/core';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import List from '../../../../lib/src/types/List';
import { Notification } from '../../../../lib/src/types/models/Notification';
import { InjectedApiProps } from '../../Api';
import { generateShadow, GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import NotificationComponent from './NotificationComponent';

interface State {
    sidebarVisible: boolean
}

const WRAP_THRESHOLD = 850;

const hideCss = css`
    opacity: 0;
    transform: translateY(-120%);

    @media (min-width: ${WRAP_THRESHOLD}px) {
        transform: translateX(120%);
    }
`;

const showCss = css`
    opacity: 1;
    transform: translateY(0);

    @media (min-width: ${WRAP_THRESHOLD}px) {
        transform: translateX(0);
    }
`;

const Overlay = styled.div<{ toggle: boolean }>`
    bottom: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transition: transform 1s, opacity 1.3s;
    background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.5018382352941176) 38%);

    ${props => props.toggle ? showCss : hideCss};

    @media (min-width: ${WRAP_THRESHOLD}px) {
        margin-left: auto;
        width: 450px;
        background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.5018382352941176) 38%);
        mask-image: linear-gradient(0deg, transparent 0, black ${GRID_SIZE * 4}px);
    }
`;

const NotificationContainer = styled(NotificationComponent)`
    ${generateShadow()};
    margin: ${GRID_SIZE * 2}px ${GRID_SIZE}px;
    transition: transform 1s, opacity 1.3s;

    @media (min-width: ${WRAP_THRESHOLD}px) {
        margin: ${SCREEN_PADDING}px;
        margin-left: auto;
        width: 350px;
    }
`;

const HideButton = styled(Button)`
    &&& {
        ${generateShadow()};
        background-color: white;
    }
`;

const HideButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: ${SCREEN_PADDING}px;
`;

@inject('api')
@observer
export default class GlobalNotification extends Component {
    private notifications: Notification[] = [];
    private closed: List<true> = {};

    public readonly state: State = {
        sidebarVisible: true
    };

    constructor(props: any) {
        super(props);

        this.reset();
    }

    componentDidMount() {
        this.setState({ sidebarVisible: !!this.notifications.length });
    }

    componentDidUpdate(prevProps: Readonly<InjectedApiProps>, prevState: Readonly<{}>) {
        // Showing sidebar if first notification arrived
        if(this.notifications.length === 1 && !this.state.sidebarVisible) {
            this.setState({ sidebarVisible: true });
        }
    }

    private get injected() {
        return this.props as InjectedApiProps;
    }

    @bind
    private hide(...notifications: Notification[]) {
        notifications.forEach(notification => this.closed[notification.key] = true);
        notifications[0].closed = true; // needed to trigger re-render
        this.injected.api.account.closeNotifications(notifications);
    }

    @bind
    private open(notification: Notification) {
        this.injected.api.account.openNotification(notification);
        this.hide(notification);
    }

    @bind
    private hideAll() {
        this.setState({ sidebarVisible: false });
        setTimeout(() => {
            this.hide(...this.notifications);
        }, 1000);
    }

    @bind
    private renderNotification({ item }: { item: Notification }) {
        return (
            <NotificationContainer
                notification={item}
                isClosed={item.closed}
                handleOpen={() => this.open(item)}
                handleHide={() => this.hide(item)}
            />
        );
    }

    private reset() {
        if (this.injected.api.account.openNotifications.empty) {
            this.closed = {};
        }
    }

    public render() {
        const {api} = this.injected;

        this.notifications = api.account.openNotifications.list.filter(({ key }) => !this.closed[key]);

        const [ currentNotification, nextNotification, afterNextNotification ] = this.notifications;


        return (
            <Overlay toggle={this.state.sidebarVisible}>
                {afterNextNotification && this.renderNotification({item: afterNextNotification})}
                {nextNotification && this.renderNotification({item: nextNotification})}
                {currentNotification && this.renderNotification({item: currentNotification})}
                {this.notifications.length > 1 && (
                    <HideButtonContainer>
                        <HideButton onClick={() => this.hideAll()}>
                            {Env.i18n.t('HideAll')}
                        </HideButton>
                    </HideButtonContainer>
                )}
            </Overlay>
        );
    }
}