import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Place, Search } from '@material-ui/icons';
import { bind, debounce } from 'decko';
import Downshift from 'downshift';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import colors from '../../../../lib/src/styles/colors';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import { MealResponse, PlaceResponse, TagResponse } from '../../../../lib/src/types/models/Response';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import { theme } from '../../theme';
import RestaurantsMap from '../restaurants/RestaurantsMap';
import Icon, { IconType } from './Icon';

const ResultIcon = styled(Icon).attrs({
    color: colors.grey_02,
    size: GRID_SIZE * 3
})`
    margin-right: ${GRID_SIZE}px;
`;

const ResultList = styled(Paper).attrs({
    square: true
})`
    position: absolute;
    z-index: 1000;

    ${theme.breakpoints.down('md')} {
        left: 0;
        right: 0;
    }
`;

interface Result {
    type:  'keyword' | 'place' | 'partner' | 'non-partner' | 'meal' | 'tag';
    data: string | PlaceResponse | RestaurantEntry | MealResponse | TagResponse;
    key: string;
    label: string;
    image: IconType;
}

const MAX_PLACES = 3;
const MAX_PARTNERS = 5;
const MAX_NONPARTNERS = 5;
const MAX_MEALS = undefined;
const MAX_TAGS = undefined;

interface Props {
    inputValue: string;
    onKeywordSelect?: (keyword: string) => void;
    onPlaceSelect?: (place: PlaceResponse) => void;
    onPartnerSelect?: (partner: RestaurantEntry) => void;
    onNonPartnerSelect?: (partner: RestaurantEntry) => void;
    onMealSelect?: (meal: MealResponse) => void;
    onTagSelect?: (tag: TagResponse) => void;
}

interface State {
    keywords: Result[];
    places: Result[];
    partners: Result[];
    nonPartners: Result[];
    meals: Result[];
    tags: Result[];
    search: string;
}

/**
 * This is taken from https://material-ui.com/components/autocomplete/ and uses downshift,
 * which in my opinion has a bit of a strange style to use
 */
@inject('api')
export default class AutocompleteInput extends React.PureComponent<Props, State> {
    private static PLACES_REQUEST = {
        language: 'de',
        componentRestrictions: {
            country: [ 'de', 'at', 'ch' ] // TODO: localize
        },
        location: {
            lat: () => 53.5,
            lng: () => 9.9
        },
        radius: 5000
    };

    public readonly state: State = {
        keywords: [],
        places: [],
        partners: [],
        nonPartners: [],
        meals: [],
        tags: [],
        search: ''
    };

    private inputRef = React.createRef<HTMLDivElement>();

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.inputValue !== this.props.inputValue) {
            this.setState({ search: this.props.inputValue });
        }
    }

    private searchForKeywords(input: string) {
        if (this.props.onKeywordSelect) {
            const keywords: Result[] = [{
                type: 'keyword',
                data: input,
                key: 'keyword',
                label: input,
                image: Search
            }];

            this.setState({ keywords });
        }
    }

    @debounce(800)
    private searchForPlaces(input: string) {
        const { autocompleteService } = RestaurantsMap;

        if (this.props.onPlaceSelect && autocompleteService) {
            const request = { ...AutocompleteInput.PLACES_REQUEST, input };

            autocompleteService.getPlacePredictions(request, (placeResults: PlaceResponse[], status: any) => {
                if (status === 'OK') {
                    this.setState({
                        places: placeResults.slice(0, MAX_PLACES).map(data => ({
                            type: 'place',
                            data,
                            key: data.id,
                            label: data.description || '',
                            image: Place
                        }))
                    });
                }
            });
        }
    }

    @debounce(800)
    private searchForPartners(input: string) {
        if (this.props.onPartnerSelect) {
            Backend.searchForRestaurants(input, CooperationType.Restaurant, this.injected.api.location.coordinate).then(restaurants => {
                this.setState({
                    partners: restaurants.slice(0, MAX_PARTNERS).map(data => ({
                        type: 'partner',
                        data,
                        key: data.key,
                        label: data.name || '',
                        image: data.logo?.url || require('../../assets/svg/meal.svg')
                    }))
                });
            });
        }
    }

    @debounce(800)
    private searchForNonPartners(input: string) {
        if (this.props.onPartnerSelect) {
            Backend.searchForRestaurants(input, CooperationType.NonPartner, this.injected.api.location.coordinate).then(restaurants => {
                this.setState({
                    partners: restaurants.slice(0, MAX_NONPARTNERS).map(data => ({
                        type: 'non-partner',
                        data,
                        key: data.key,
                        label: data.name || '',
                        image: data.logo?.url || require('../../assets/svg/meal.svg')
                    }))
                });
            });
        }
    }

    @debounce(800)
    private searchForMeals(input: string) {
        if (this.props.onMealSelect) {
            Backend.searchForMeals(input).then(meals =>
                this.setState({
                    meals: meals.slice(0, MAX_MEALS).map(data => ({
                        type: 'meal',
                        data,
                        key: data.key,
                        label: data.name,
                        image: require('../../assets/svg/meal.svg')
                    }))
                })
            );
        }
    }

    @debounce(800)
    private searchForTags(input: string) {
        if (this.props.onTagSelect) {
            Backend.searchForTags(input).then(tags =>
                this.setState({
                    tags: tags.slice(0, MAX_TAGS).map(data => ({
                        type: 'tag',
                        data,
                        key: data.id,
                        label: data.translation || data.name,
                        image: require('../../assets/svg/tag.svg')
                    }))
                })
            );
        }
    }

    @bind
    private handleInputValueChange(search: string) {
        this.setState({ search });

        if (search.length > 2) {
            this.searchForKeywords(search);
            this.searchForPlaces(search);
            this.searchForPartners(search);
            this.searchForNonPartners(search);
            this.searchForMeals(search);
            this.searchForTags(search);
        }
    }

    @bind
    private handleSelection(item: Result | null) {
        // `item` is `undefined` if ESC is pressed
        if (item) {
            switch (item.type) {
                case 'keyword':
                    if (this.props.onKeywordSelect) {
                        this.props.onKeywordSelect(item.data as string);
                    }
                break;
                case 'place':
                    if (this.props.onPlaceSelect) {
                        this.props.onPlaceSelect(item.data as PlaceResponse);
                    }
                break;
                case 'partner':
                    if (this.props.onPartnerSelect) {
                        this.props.onPartnerSelect(item.data as RestaurantEntry);
                    }
                break;
                case 'non-partner':
                    if (this.props.onNonPartnerSelect) {
                        this.props.onNonPartnerSelect(item.data as RestaurantEntry);
                    }
                break;
                case 'meal':
                    if (this.props.onMealSelect) {
                        this.props.onMealSelect(item.data as MealResponse);
                    }
                break;
                case 'tag':
                    if (this.props.onTagSelect) {
                        this.props.onTagSelect(item.data as TagResponse);
                    }
                break;
            }

            // blur input
            this.inputRef.current?.querySelector('input')?.blur();
        }
    }

    @bind
    private itemToString() {
        return this.injected.api.location.name;
    }

    @bind
    private renderItem(item: Result, index: number, itemProps: any, highlightedIndex: number | null, selectedItem: Result | null) {
        const isSelected = selectedItem?.key === item.key;
        const isHighlighted = highlightedIndex === index;
        const fontWeight = isSelected ? 500 : 400;

        return (
            <MenuItem {...itemProps} key={item.key} selected={isHighlighted} component="div" style={{ fontWeight }}>
                <ResultIcon src={item.image} />
                {item.label}
            </MenuItem>
        );
    }

    public render() {
        return (
            <Downshift<Result>
                inputValue={this.state.search}
                itemToString={this.itemToString}
                defaultHighlightedIndex={0}
                onInputValueChange={this.handleInputValueChange}
                onSelect={this.handleSelection}
            >
                {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen, selectedItem }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps();
                    const { keywords, tags, places, partners, nonPartners, meals } = this.state;
                    const results = [ ...keywords, ...tags, ...places, ...partners, ...nonPartners, ...meals ];

                    return (
                        <div style={{ marginLeft: GRID_SIZE }}>
                            <TextField
                                ref={this.inputRef}
                                placeholder={Env.i18n.t('FilterSearch')}
                                fullWidth={true}
                                type="search"
                                margin="normal"
                                InputProps={{ onBlur, onFocus }}
                                {...(inputProps as TextFieldProps)}
                            />
                            {isOpen && (
                                <ResultList {...getMenuProps()}>
                                    {results.map((item, index) =>
                                        this.renderItem(item, index, getItemProps({ item }), highlightedIndex, selectedItem)
                                    )}
                                </ResultList>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}
