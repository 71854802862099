import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { bind } from 'decko';
import React from 'react';

import colors from '../../../../lib/src/styles/colors';

export interface Option {
    label: string;
    action?: () => void;
    destructive?: boolean;
    hideFor?: boolean;
}

interface Props {
    options: Option[];
    onCancel?: () => void; // called when scrim is tapped or ESC is pressed
    onClose?: () => void; // called after any action before action sheet closes
    title?: string;
    message?: string;
}

interface State {
    open?: boolean;
    target?: HTMLElement;
}

export default class ActionSheet extends React.PureComponent<Props> {
    public readonly state: State = {};

    private options: Option[] = [];

    @bind
    public show(target?: HTMLElement) {
        this.setState({ open: true, target });
    }

    @bind
    public hide() {
        this.setState({ open: false, target: undefined });
    }

    public render() {
        const { title, options } = this.props;

        this.options = options.filter(option => !option.hideFor);

        if (title) {
            this.options.unshift({ label: title });
        }

        return (
            <Menu
                open={!!this.state.open}
                onClose={this.handleCancel}
                anchorEl={this.state.target}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                {this.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        disabled={!option.action}
                        onClick={() => this.handleSelect(option)}
                        style={option.destructive ? { color: colors.warn } : undefined}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        );
    }

    private close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private cancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    @bind
    private handleCancel() {
        this.hide();
        this.cancel();
        this.close();
    }

    @bind
    private handleSelect(option: Option) {
        if (option.action) {
            option.action();
            this.hide();
            this.close();
        }
    }
}
