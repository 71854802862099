import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { EmptyContactsList } from '../social/EmptyList';
import AbstractContactsTab from './AbstractContactsTab';
import AddContactsModal from './AddContactsModal';

export type ContactsTabType = ContactsTab;

@inject('api', 'invitationDraft', 'chats')
@observer
export default class ContactsTab extends AbstractContactsTab<ContactPerson> {
    private addContactsModalRef = React.createRef<AddContactsModal>();

    @bind
    public addNew() {
        this.addContactsModalRef.current?.open();
    }

    @bind
    public renderModals() {
        return (
            <AddContactsModal ref={this.addContactsModalRef} />
        );
    }

    public render() {
        return this.renderList(true, [
            {
                label: Env.i18n.t('ContactRemove'),
                action: this.removeContact
            },
            {
                label: Env.i18n.t('ContactBlock'),
                action: this.blockContact,
                destructive: true
            }
        ]);
    }

    @bind
    protected renderEmptyList() {
        return (
            <EmptyContactsList onPress={this.addNew} />
        );
    }

    @bind
    private async blockContact() {
        this.handlePromise(
            contact => Backend.blockUsers([contact.key]),
            Env.i18n.t('SuccessBlockedUserSet'),
            Env.i18n.t('ErrorBlockedUserNotSet')
        );
    }

    @bind
    private async removeContact() {
        this.handlePromise(
            contact => Backend.removeContacts([contact.key]),
            Env.i18n.t('SuccessContactsRemoved', { count: 1 }),
            Env.i18n.t('ErrorContactsNotRemoved', { count: 1 })
        );
    }
}
