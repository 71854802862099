import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import { DescriptionText, RegularText } from '../text';
import PerishableText from '../text/PerishableText';
import Distance from './Distance';

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

// @ts-ignore ts(2615)
const PreformattedText = styled(DescriptionText)`
    white-space: pre-wrap;
`;

interface Props {
    restaurant: RestaurantEntry;
    displayOpeningTime?:boolean;
    onOpenMoia?: () => void;
    style?: React.CSSProperties;
}

@inject('api')
@observer
export default class RestaurantMeta extends React.PureComponent<Props> {
    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public render() {
        const { restaurant, displayOpeningTime, onOpenMoia, children, style } = this.props;
        const cuisine = restaurant.getCuisine(this.injected.api.tags);

        return (
            <div style={style}>
                {cuisine && (
                    <DescriptionText style={{ marginBottom: GRID_SIZE }}>
                        {cuisine.translations['de'] || cuisine.name}
                    </DescriptionText>
                )}
                <TextContainer style={{ marginBottom: GRID_SIZE }}>
                    {!!children && (
                        <PreformattedText>
                            {children}
                            {Constants.INFO_SEPARATOR}
                        </PreformattedText>
                    )}
                    <Distance restaurant={restaurant} onOpenMoia={onOpenMoia} />
                </TextContainer>
                {displayOpeningTime && (
                    <PreformattedText>
                        <PerishableText generator={() => restaurant.getClosingHint()} style={{ color: colors.warn }} />
                        <PerishableText generator={() => restaurant.getOpeningHoursHint()} />
                    </PreformattedText>
                )}
                      {restaurant.onlyTakeAway && (
                    <RegularText style={{ color: colors.navy_blue, marginTop: 3 }} >
                        { Env.i18n.t('OnlyTakeAway')}
                    </RegularText >
                )}
            </div>
        );
    }
}
