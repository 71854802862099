import Env from '../../../lib/src/Env';
import RestaurantEntry from '../../../lib/src/types/models/RestaurantEntry';
import { formatDayAndTime, formatNumber } from './formatting';

const CURRENCY = '€';

function unknownError(error: any) {
    console.warn('Unknown payment error occurred', error);

    return Env.i18n.t('ErrorUnknown');
}

export function paymentError(error: any, withCode = true) {
    // error can be a number or has properties ResultCode (string), e.code (number), or ???
    if (error) {
        if (typeof error?.message === 'string') {
            const [ domain, code ] = error.message.split('/', 2);

            switch (domain) {
                case 'firebase':
                    return backendError(code);
                case 'api-error':
                    return; // ignoring Partner API errors
            }

            /*
             * 404 error can have an error message, e.g.
             * ```
             * <!DOCTYPE html> <html lang="en"> <head> <meta charset="utf-8"> <title>Error</title> </head> <body>
             * <pre>Cannot POST /payment/orders</pre> </body> </html>
             * ```
             * Don't display such message. Are there other cases where `errorMessage` should be returned?
             */
        }

        try {
            const parsedErrorMessage = JSON.parse(error.message);

            if (parsedErrorMessage.details?.length) {
                return paypalError(parsedErrorMessage);
            }
        } catch (e) {}

        if (typeof error === 'number' || typeof error?.code === 'number') {
            return httpError(typeof error === 'number' ? error : error.code);
        }

        // how to handle errors with by the error code ??
        if (typeof error?.code === 'string') {
            return backendError(error);
        }
    }

    return unknownError(error);
}

function httpError(code: number) {
    return [ 400, 401, 403, 404 ].includes(code)
        ? Env.i18n.t(`ErrorHttp_${code}`)
        : unknownError(code);
}

function backendError(error: any) {
    const paymentErrors = [
        'DiscountLimitExceeded',
        'MissingRequiredFields',
        'NoCard',
        'NoCardId',
        'NoCardType',
        'NoLegalUser',
        'NoMeetUpDate',
        'NoPaymentUser',
        'NoPreauthorizationId',
        'NoItems',
        'NoTransactionId',
        'OrderMealsChanged',
        'OrderNotCancelable',
        'OrderNotFound',
        'TotalPrice',
        'UserNotFound',
        'WrongTransactionAuthor',
    ];

    return paymentErrors.includes(error)
        ? Env.i18n.t(`ErrorPayment_${error}`)
        : unknownError(error);
}

export function showPaymentError(error: any) {
    const errorMessage = paymentError(error);

    if (errorMessage) {
        Env.snackbar.error(errorMessage);
    }
}

function paypalError(error: any) {
    return Env.i18n.t(`ErrorPayPal_${error.details[0].issue}`);
}

// amount is price in cents. For now is euro currency only supported
export function getFormattedPrice(amount: number, currency?: string) {
    return formatNumber(amount / 100, 2) + ` ${currency || CURRENCY}`;
}

export function getCartId(restaurant?: RestaurantEntry): string {
    return restaurant?.routingName || '';
}

export function formatMeetUpDate(date: Date = new Date(), override?: boolean): string {
    return date
        ? formatDayAndTime(date, !override ? {} : {
            nextWeek: Env.i18n.t('nextWeekAt'),
            sameElse: Env.i18n.t('sameElseAt')
        })
        : '';
}
