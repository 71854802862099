import { bind, debounce } from 'decko';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import EmptyListIndicator from '../common/EmptyListIndicator';
import SearchBar from '../forms/SearchBar';
import ContactList, { ContactListProps, PromiseHandler } from './ContactList';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const FilterSearchBar = styled(SearchBar)`
    margin: ${GRID_SIZE * 2}px ${SCREEN_PADDING}px;
`;

export interface FilteredContactListProps<T extends ContactEntity>
        extends Omit<
            ContactListProps<T>,
            'ListHeaderComponent' | 'ListHeaderLayout' | 'ListFooterComponent' | 'ListFooterLayout' | 'ListEmptyComponent'
        > {

    filterPlaceholder?: string;
    /** If the function returns an array, it is displayed instead of `contacts`. Default: A function filtering `contacts` by `name` */
    onFilterInput?: (input: string, contacts: T[]) => Promise<T[] | undefined>;
    /** If `true`, the filter field is hidden if `contacts` is empty or unset */
    hideFilterIfEmpty?: boolean;
    ListHeaderComponent?: (state: FilteredContactListState<T>) => (React.ReactElement<any> | undefined);
    ListHeaderLayout?: (state: FilteredContactListState<T>) => (number | undefined);
    ListFooterComponent?: (state: FilteredContactListState<T>) => (React.ReactElement<any> | undefined);
    ListFooterLayout?: (state: FilteredContactListState<T>) => (number | undefined);
    ListEmptyComponent?: (state: FilteredContactListState<T>) => (React.ReactElement<any> | undefined);
}

export interface FilteredContactListState<T extends ContactEntity> {
    filteredContacts?: T[];
    filtering?: boolean;
}

@observer
export default class FilteredContactList<T extends ContactEntity>
        extends React.PureComponent<FilteredContactListProps<T>, FilteredContactListState<T>> {

    public readonly state: FilteredContactListState<T> = {};

    private contactListRef = React.createRef<ContactList<T>>();

    public readonly waitFor: PromiseHandler<T> = async (...args) => {
        this.contactListRef.current?.waitFor(...args);
    };

    public render() {
        const {
            filterPlaceholder, onFilterInput, hideFilterIfEmpty, contacts, className, style,
            ListHeaderComponent, ListHeaderLayout, ListFooterComponent, ListFooterLayout, ListEmptyComponent,
            ...listProps
        } = this.props;
        const { filteredContacts, filtering } = this.state;

        return (
            <Container className={className} style={style}>
                {(!hideFilterIfEmpty || !!contacts?.length) && (
                    <FilterSearchBar
                        placeholderText={filterPlaceholder}
                        loading={filtering}
                        onInput={this.filterContacts}
                        onClear={this.filterContacts}
                    />
                )}
                <ContactList<T>
                    {...listProps}
                    ref={this.contactListRef}
                    contacts={filteredContacts || contacts}
                    ListHeaderComponent={ListHeaderComponent && ListHeaderComponent(this.state)}
                    ListHeaderLayout={ListHeaderLayout && ListHeaderLayout(this.state)}
                    ListFooterComponent={ListFooterComponent && ListFooterComponent(this.state)}
                    ListFooterLayout={ListFooterLayout && ListFooterLayout(this.state)}
                    ListEmptyComponent={this.renderEmptyList()}
                    style={{ flex: 1 }}
                />
            </Container>
        );
    }

    private renderEmptyList() {
        const { onFilterInput, ListEmptyComponent } = this.props;
        const { filteredContacts } = this.state;

        return (!filteredContacts || onFilterInput)
            ? (ListEmptyComponent && ListEmptyComponent(this.state))
            : (
                // default empty state for name-filtered list
                <EmptyListIndicator
                    waitFor={true}
                    icon={require('../../assets/svg/empty_state_user.svg')}
                    hint={Env.i18n.t('NoUserFoundByName')}
                    style={{ marginTop: GRID_SIZE * 3 }}
                />
            );
    }

    @bind
    @debounce(800)
    private async filterContacts(byInput?: string) {
        this.setState({ filtering: true });

        const filter = this.props.onFilterInput || this.nameFilter;
        const filteredContacts = await filter(byInput?.trim() || '', this.props.contacts || []);

        this.setState({ filteredContacts, filtering: false });
    }

    @bind
    private nameFilter(term: string, contacts: T[]) {
        const query = term?.trim();
        let filteredContacts: T[] | undefined;

        if (query) {
            const needles = query.toLocaleLowerCase().split(' ').filter(needle => needle);

            filteredContacts = contacts.filter(contact => {
                const name = contact.name.toLocaleLowerCase();

                return needles.every(needle => name.includes(needle));
            });
        }

        return filteredContacts;
    }
}
