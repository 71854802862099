import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../../lib/src/Env';
import { InjectedGroupsProps } from '../../../../../lib/src/managers/GroupsManager';
import colors from '../../../../../lib/src/styles/colors';
import { ChatHeader } from '../../../../../lib/src/types/models/Chat';
import Timestamps from '../../../../../lib/src/types/Timestamps';
import { GRID_SIZE } from '../../../styles/base';
import ProfilePicture from '../../social/ProfilePicture';
import { RegularText, TitleText } from '../../text';

const Card = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: ${GRID_SIZE * 4}px;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: ${GRID_SIZE * 2}px;
    overflow: hidden;
`;

const NameText = styled(TitleText)`
    color: ${colors.navy_blue};
    margin-bottom: ${GRID_SIZE}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ContentText = styled(TitleText)`
    color: ${colors.navy_blue};
    font-weight: 700;
    margin-bottom: ${GRID_SIZE}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TimeAgoText = styled(RegularText)`
    color: ${colors.description_grey};
    font-size: 12px;
`;

interface Props {
    chat: ChatHeader;
    onPress?: () => void;
}

@inject('groups')
@observer
export default class ChatCard extends React.PureComponent<Props> {
    private get injected() {
        return this.props as Props & InjectedGroupsProps;
    }

    public render() {
        const { chat, onPress } = this.props;
        const lastMessage = chat.lastMessage;
        // TODO: for now just use one, design for more users needs to be done
        const firstParticipant = Object.values(chat.otherParticipants)[0];
        let lastMessageContent = lastMessage?.content;

        if (lastMessage?.contentId) {
            if (lastMessage.content === 'restaurant') {
                lastMessageContent = Env.i18n.t('SharedRestaurant')
            } else if (lastMessage.content === 'invitation') {
                lastMessageContent = Env.i18n.t('SharedInvitation')
            }
        }

        return (
            <Card onClick={onPress}>
                <ProfilePicture uri={firstParticipant?.photoURL} />
                <Content>
                    <NameText>
                        {this.injected.groups.getChatName(chat)}
                    </NameText>
                    <ContentText>
                        {lastMessageContent}
                    </ContentText>
                    {lastMessage?.timestamp && (
                        <TimeAgoText>
                            {moment.duration(moment(Timestamps.toDate(lastMessage.timestamp)).diff(moment())).humanize(true)}
                        </TimeAgoText>
                    )}
                </Content>
            </Card>
        )
    }
}
