import { bind } from 'decko';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import { getInitial } from '../../../../lib/src/helpers/formatting';
import { logError } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import Action from '../../../../lib/src/types/Action';
import ContactEntity, { compareContactEntites } from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE } from '../../styles/base';
import FlatList from '../common/FlatList';
import { TitleText } from '../text';
import ContactEntry from './ContactEntry';

const SectionTitle = styled(TitleText)<{ first: boolean }>`
    color: ${colors.grey_04};
    margin-top: ${({ first }) => GRID_SIZE * 1.5 * (first ? 1 : -1)}px;
    padding-left: ${GRID_SIZE * 3}px;
    position: relative;
    z-index: 1;
`;

export type PromiseHandler<T> = (
        contact: T,
        promise: Promise<any> | ((contact: T) => Promise<any>),
        successMessage: string,
        errorMessage: string,
        successAction?: Action,
        errorAction?: Action
    ) => Promise<any>;

export interface ContactListProps<T extends ContactEntity> {
    contacts?: T[];
    renderItemContent?: (contact: T) => React.ReactNode;
    onItemPress?: (contact: T) => void;
    ListHeaderComponent?: React.ReactElement<any>;
    ListHeaderLayout?: number;
    ListFooterComponent?: React.ReactElement<any>;
    ListFooterLayout?: number;
    ListEmptyComponent?: React.ReactElement<any>;
    disableSections?: boolean;
    disableItems?: string[];
    dependsOn?: any;
    className?: string;
    style?: React.CSSProperties;
}

interface State {
    loading: string[];
}

@observer
export default class ContactList<T extends ContactEntity> extends React.PureComponent<ContactListProps<T>, State> {
    public readonly state: State = {
        loading: []
    };

    public readonly waitFor: PromiseHandler<T> = async (contact, promise, successMessage, errorMessage, successAction, errorAction) => {
        this.setState(state => ({
            loading: Arrays.add(state.loading.slice(), contact.key)
        }));

        try {
            if (typeof promise === 'function') {
                promise = promise(contact);
            }

            await promise;
            Env.snackbar.success(successMessage, successAction);
        } catch (error) {
            logError('ContactList.waitFor', error);
            Env.snackbar.error(errorMessage, errorAction);
        } finally {
            this.setState(state => ({
                loading: Arrays.remove(state.loading.slice(), contact.key)
            }));
        }
    };

    public render() {
        const { contacts, disableSections, className, style } = this.props;
        const { ListHeaderComponent, ListHeaderLayout, ListFooterComponent, ListFooterLayout, ListEmptyComponent } = this.props;
        let sections: (T | string)[] = [];
        let items = (contacts?.slice() || []).sort(compareContactEntites);

        if (items.length) {
            if (disableSections) {
                sections = items;
            } else {
                let lastInitial = '';

                items.forEach(item => {
                    const initial = getInitial(item.name);

                    if (initial !== lastInitial) {
                        sections.push(initial);
                        lastInitial = initial;
                    }

                    sections.push(item);
                });
            }
        }

        return (
            <FlatList<T | string>
                data={sections}
                renderItem={this.renderItem}
                getItemLayout={this.getItemLayout}
                ListHeaderComponent={ListHeaderComponent}
                ListHeaderLayout={ListHeaderLayout}
                ListFooterComponent={ListFooterComponent}
                ListFooterLayout={ListFooterLayout}
                ListEmptyComponent={ListEmptyComponent}
                className={className}
                style={{ flex: 1, ...style }}
            />
        );
    }

    @bind
    private renderItem(item: T | string, index: number) {
        return (typeof item === 'string') ? this.renderSectionTitle(item, index) : this.renderContact(item);
    }

    @bind
    private renderContact(contact: T) {
        const { onItemPress, renderItemContent, disableItems } = this.props;
        const loading = this.state.loading.includes(contact.key);
        const disabled = disableItems?.includes(contact.key);

        return (
            <ContactEntry contact={contact} loading={loading} disabled={disabled} onPress={onItemPress}>
                {renderItemContent && renderItemContent(contact)}
            </ContactEntry>
        );
    }

    @bind
    private renderSectionTitle(sectionTitle: string, index: number) {
        return (
            <SectionTitle first={index === 0}>
                {sectionTitle}
            </SectionTitle>
        )
    }

    @bind
    private getItemLayout(item : T | string, index: number) {
        return (typeof item === 'string')
            ? (index ? 0 : GRID_SIZE * 3)
            : GRID_SIZE * 11;
    }
}
