import { bind } from 'decko';
import { computed, observable, reaction } from 'mobx';

import Env from '../Env';
import Backend from '../helpers/Backend';
import Documents from '../helpers/Documents';
import { logError } from '../helpers/Validate';
import ContactPersonList from '../store/ContactPersonList';
import DataList, { Snapshot } from '../store/DataList';
import DiscountList from '../store/DiscountList';
import List from '../types/List';
import ContactEntity, { ContactPerson, ContactStatus } from '../types/models/ContactEntity';
import { Notification } from '../types/models/Notification';
import RestaurantEntry, { Tag } from '../types/models/RestaurantEntry';
import User, { ContactUser, createUserFromSnapshot } from '../types/models/User';
import { Attendee } from '../types/models/UserInvitation';
import Timestamps from '../types/Timestamps';
import ApiManager from './ApiManager';

type ApiType = ApiManager<AccountManager<ApiType>>;

export default abstract class AccountManager<A extends ApiType> {
    @observable
    private _userData?: User;

    @observable
    private _user?: firebase.User;

    @observable
    private _loaded = false;

    private _contacts = new ContactPersonList();
    private _blockedUsers = new ContactPersonList();
    private _blockingUsers = new DataList<any>();
    private _openNotifications = new DataList<Notification>();
    private _accountDiscounts = new DiscountList();
    private _globalDiscounts = new DiscountList();
    private _contactSuggestions?: Promise<ContactPerson[]>;

    private _waitFor: Promise<any>;
    private _api?: A;
    private _currentPolicyVersion?: Date;

    private unsubscribeUserData?: () => void;
    private unsubscribeLists: List<() => void> = {};

    private lists: List<DataList<any>> = {
        contacts: this._contacts,
        blockedUsers: this._blockedUsers,
        isBlockedBy: this._blockingUsers
    };

    constructor(waitFor: Promise<any> = Promise.resolve()) {
        this._waitFor = waitFor;

        // set language esp. for email templates TODO: why here and not in Env?
        Env.firebase.auth().languageCode = Env.currentLanguageCode();

        // listeners are never disposed
        Documents.addVersionListener(currentVersion => {
            this._currentPolicyVersion = currentVersion;
            this.schedulePolicyAcceptanceCheck();
        });
        this._waitFor.then(() => this._globalDiscounts.startWatching());
    }

    @computed
    private get userRef() {
        return this.user
            ? Env.firebase.firestore().collection('users').doc(this.user.uid)
            : undefined;
    }

    @computed
    public get user() {
        return this._user;
    }

    @computed
    public get loggedIn() {
        return this.user?.isAnonymous === false;
    }

    @computed
    public get verified() {
        // not using `this.user.emailVerified` because it is only updated on log-in
        return this.data.verificationCode === true;
    }

    @computed
    public get loaded() {
        return this._loaded;
    }

    @computed
    public get contacts() {
        return this._contacts;
    }

    @computed
    public get blockedUsers() {
        return this._blockedUsers;
    }

    /**
     * NOTE: This should never be visible for the user!
     */
    @computed
    public get blockingUsersCount() {
        return this._blockingUsers.list.length;
    }

    @computed
    public get openNotifications() {
        return this._openNotifications;
    }

    @computed
    public get data() {
        return (this._userData || {}) as User;
    }

    @computed
    public get currentContactPerson() {
        if (this.user) {
            return new ContactPerson(this.user.uid, this.data.displayName || '', this.data.photoURL || undefined);
        }
    }

    public get contactSuggestions() {
        if (this._contactSuggestions === undefined) {
            this._contactSuggestions = Backend.searchForRandomUsers(4);
        }

        return this._contactSuggestions;
    }

    protected get api() {
        return this._api!;
    }

    public get accountDiscounts() {
        return this._accountDiscounts;
    }

    @computed
    public get allDiscounts() {
        return [ ...this._globalDiscounts.list, ...this._accountDiscounts.list ]
            .filter(discount => discount.availableForToday() && discount.availableForUser(this._user?.uid || ''));
    }

    public setApi(api: A) {
        this._api = api;
    }

    @bind
    public schedule(callback: () => void) {
        // wait for email as we need a non-anonymous user
        reaction(
            () => this.data.email,
            (email, handle) => {
                if (email) {
                    callback();
                    handle.dispose();
                }
            },
            { fireImmediately: true }
        );
    }

    @bind
    public schedulePolicyAcceptanceCheck() {
        this.schedule(this.checkPolicyAcceptance);
    }

    @bind
    public async setUserFlag(flag: keyof User, value: boolean) {
        if (this._userData) {
            await this.userRef?.update({ [flag]: value }); // can't use `set(merge: true)` as it doesn't support dot notation
        } else {
            throw new Error('No current user');
        }
    }

    @bind
    public setVisibility(visible: boolean) {
        this.setUserFlag('visible', visible)
            .then(() => {
                if (this.verified || !visible) {
                    Env.snackbar.success(Env.i18n.t(visible ? 'SuccessBecomeVisible' : 'SuccessBecomeInvisible'));
                } else {
                    Env.alert(Env.i18n.t('ChangeSuccess'), Env.i18n.t('VisibleButUnverifiedHint'));
                }
            })
            .catch(error => {
                logError('SettingsScreen.setVisibility', error);
                Env.snackbar.error(Env.i18n.t('ErrorChangeVisibility'));
            });
    }

    /**
     * set local and firebase value for user favorite
     * @param restaurantKey
     * @param value
     */
    @bind
    public async setFavorite(restaurantKey: string, value: boolean) {
        const favorites = value
            ? Env.firebase.firestore.FieldValue.arrayUnion(restaurantKey)
            : Env.firebase.firestore.FieldValue.arrayRemove(restaurantKey);

        await this.userRef?.set({ favorites }, { merge: true });
    }

    @bind
    public isFavorite(restaurant: RestaurantEntry) {
        return computed(() => !!this.data.favorites?.includes(restaurant.key)).get();
    }

    @bind
    public isBlockedBy(contactOrKey: ContactEntity | string) {
        return computed(() => !!this._blockingUsers.get((typeof contactOrKey === 'string') ? contactOrKey : contactOrKey.key)).get();
    }

    @bind
    public hasTaste(tag: Tag) {
        /*
         * Little cryptic: We look here if a taste reference with a certain id exists,
         * surrounded by computed to trigger observer updates
         * (@computed decorator only works on getters, which don't work with parameters)
         */
        return computed(() => !!this._userData?.tasteKeys?.find(taste => tag.key === taste)).get();
    }

    @bind
    public async setTasteKeys(tasteKeys: string[]) {
        await this.userRef?.set({ tasteKeys }, { merge: true });
    }

    @bind
    public async updateProfile(user: Partial<ContactUser>) {
        await this.userRef?.set({ ...user }, { merge: true });
    }

    @bind
    public getMyStatus(contacts: (Attendee | ContactPerson)[]): ContactStatus {
        // using dynamic properties to by-pass type checking
        return computed(() => contacts.find(user => (user['uid'] || user['key']) === this.user?.uid)?.status || 'pending').get();
    }

    public async acceptPolicy() {
        if (this.user) {
            return this.userRef?.set({ policyAccepted: new Date() }, { merge: true });
        }
    }

    public async closeNotifications(notifications: Notification[]) {
        if (this.user && notifications.length) {
            const batch = Env.firebase.firestore().batch();
            const collection = this.userRef!.collection('notifications');
            const data = { closed: true };

            notifications.forEach(notification => batch.update(collection.doc(notification.key), data));

            await batch.commit();
        }
    }

    @bind
    public async requestVerificationCode() {
        await this.api.waitFor(Backend.resendEmailVerification());
        Env.snackbar.info(Env.i18n.t('VerificationCodeSent'));
    }

    /**
     * @param featureHint An engaging description of the features behind this sign-in wall
     */
    @bind
    public requireVerification(featureHint: string, elseCallback: (loggedIn: boolean) => void) {
        const { loggedIn, verified } = this;

        if (!verified) {
            const title = Env.i18n.t(loggedIn ? 'UnverifiedCallToActionTitle' : 'AnonCallToActionTitle');
            const label = Env.i18n.t(loggedIn ? 'VerifyEmail' : 'RegistrationOrLogin');
            const action = () => elseCallback(loggedIn);

            Env.alert(
                title,
                featureHint,
                [
                    { label, action },
                    { label: Env.i18n.t('Cancel') }
                ]
            );
        }

        return verified;
    }

    public async setSelectedDiscountId(discountId: string) {
        await this.userRef?.update({ selectedDiscountId: discountId });
    }

    @bind
    public async shareMeAsContact() {
        const { connectionLink, connectionCode, displayName = '' } = this.data;

        if (connectionLink) {
            // NOTE: Don't use returned Promise, as it doesn't resolve on cancel on iOS in 0.61.5
            Env.share(Env.i18n.t('ShareContactMessage', {
                name: displayName,
                url: connectionLink,
                code: connectionCode
            }));
        } else {
            Env.snackbar.error(Env.i18n.t('ErrorUnknown'));
        }
    }

    public abstract connectUsers(): Promise<void>;

    public abstract toggleFcmToken(set: boolean): void;

    protected abstract handleUserDataChange(): void;

    protected abstract reactivateDeletedAccount(): Promise<boolean>;

    protected abstract demandPolicyAcceptance(): void;

    @bind
    protected onUserChange(currentUser: firebase.User | null) {
        const previousUser = this._user;

        this._user = currentUser || undefined;

        if (!previousUser || !currentUser || previousUser.uid !== currentUser.uid) {
            this._loaded = false;
            this._contactSuggestions = undefined;

            if (previousUser) {
                if (this.unsubscribeUserData) {
                    this.unsubscribeUserData();
                }

                Object.values(this.unsubscribeLists).forEach(unsubscriber => unsubscriber());
                this._accountDiscounts.stopWatching();
            }

            if (currentUser) {
                const languageCode = Env.i18n.currentLocale();
                const userDoc = this.userRef!;

                this.unsubscribeUserData = userDoc.onSnapshot(this.handleUserSnapshot);

                Object.entries(this.lists).forEach(([ key, list ]) =>
                    this.unsubscribeLists[key] = userDoc.collection(key).onSnapshot(snapshot =>
                        list.addQuerySnapshotChildren(snapshot as Snapshot, true)
                    )
                );

                this.unsubscribeLists['openNotifications'] = userDoc.collection('notifications')
                    .where('closed', '==', false)
                    .orderBy('sent', 'asc')
                    .onSnapshot(snapshot => this._openNotifications.addQuerySnapshotChildren(snapshot as Snapshot, true));

                this._waitFor.then(() => this._accountDiscounts.startWatching(currentUser));
                userDoc.set({ languageCode }, { merge: true });
                this.connectUsers();
                this.handleUserDataChange();
            } else {
                this._userData = undefined;
                Object.values(this.lists).forEach(list => list.reset());
                this._accountDiscounts.reset();
            }
        }
    }

    @bind
    private async handleUserSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
        // avoid cache
        if (snapshot.metadata.fromCache) {
            snapshot = await snapshot.ref.get({ source: 'server' });
        }

        this._userData = createUserFromSnapshot(snapshot as any);

        if (!this._loaded) {
            this._loaded = true;

            // reset deletion timestamp on login
            if (this.data.deleted) {
                this.reactivateDeletedAccount()
                    .then(visible => snapshot.ref.update({ deleted: Env.firebase.firestore.FieldValue.delete(), visible }))
                    .catch(logError('resetDeletion'));
            }

            // force users to verify, if existing before verification was required
            if (this.loggedIn && !this.data.verificationCode) {
                if (this.user?.emailVerified) {
                    snapshot.ref.update({ verificationCode: true });
                } else {
                    this.requireVerification(Env.i18n.t('VerificationDescription'), this.requestVerificationCode);
                }
            }
        }
    }

    @bind
    private checkPolicyAcceptance() {
        const { policyAccepted } = this.data;
        const acceptedVersion = policyAccepted ? Timestamps.toDate(policyAccepted) : -1;
        const currentVersion = this._currentPolicyVersion || -2;

        if (this.loggedIn && acceptedVersion < currentVersion) {
            this.demandPolicyAcceptance();
        }
    }
}
